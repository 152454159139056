import React, { useState, useEffect } from "react";
import { Avatar, Row, Col } from "antd";
import { EuroCircleTwoTone, CustomerServiceTwoTone, PercentageOutlined } from "@ant-design/icons";
import * as API from "../../../api/API";
import MoneyGraphs from "./MoneyGraphs.js";
import LoaderRaw from "../../../utils/LoaderRaw";

function MoneyDashboard(props) {
  const [dashboardInfo, setDashboardInfo] = useState([]);

  useEffect(() => {
    (async () => {
      let res = await API.getMoneyDashboard();
      res = await res.json();
      setDashboardInfo(res);
    })();
  }, []);

  return (
    <div className="overview" style={{ marginTop: "40px" }}>
      {dashboardInfo.length > 0 ? (
        <>
          <Row className="money-chart-row">
            <Col xs={24} sm={24} md={5} lg={5} className="col-money-charts">
              <span className="money-chart-component">
                <Row>
                  <Col span={4}>
                    <Avatar
                      className="dashboard-icon"
                      size="large"
                      icon={<CustomerServiceTwoTone twoToneColor="#4569f8" />}
                    />
                  </Col>
                  <Col span={20} className="col-money-indicator">
                    <span className="money-indicator">
                      {dashboardInfo[0].nomberOfPayingSubscriptions}
                    </span>
                    <p>Abonnements payants actifs</p>
                  </Col>
                </Row>
              </span>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              className="col-money-charts"
            >
              <span className="money-chart-component">
                <Row>
                  <Col span={4}>
                    <Avatar
                      className="dashboard-icon"
                      size="large"
                      icon={<EuroCircleTwoTone twoToneColor="#4569f8" />}
                    />
                  </Col>
                  <Col span={20} className="col-money-indicator">
                    <span className="money-indicator">
                      {dashboardInfo[0].currentRevenueWithFeatures} €
                    </span>{" "}
                    <p>MRR avec frais supp</p>
                  </Col>
                </Row>
              </span>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              className="col-money-charts"
            >
              <span className="money-chart-component">
                <Row>
                  <Col span={4}>
                    <Avatar
                      className="dashboard-icon"
                      size="large"
                      icon={<EuroCircleTwoTone twoToneColor="#4569f8" />}
                    />
                  </Col>
                  <Col span={20} className="col-money-indicator">
                    <span className="money-indicator">
                      {dashboardInfo[0].currentRevenueWithDiscounts} €
                    </span>{" "}
                    <p>MRR sans frais supp</p>
                  </Col>
                </Row>
              </span>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 5, offset: 1 }}
              className="col-money-charts"
            >
              <span className="money-chart-component">
                <Row>
                  <Col span={4}>
                    <Avatar
                      className="dashboard-icon"
                      size="large"
                      icon={<PercentageOutlined style={{ color: "#4569f8" }} />}
                    />
                  </Col>
                  <Col span={20} className="col-money-indicator">
                    <span className="money-indicator">
                      {(
                        ((dashboardInfo[0].currentRevenueWithDiscounts -
                          dashboardInfo[0].currentRevenue) /
                          dashboardInfo[0].currentRevenue) *
                        100
                      ).toFixed(2)}{" "}
                      %
                    </span>{" "}
                    <p>Remise moyenne par client</p>
                  </Col>
                </Row>
              </span>
            </Col>
          </Row>
          <MoneyGraphs dataAnalysis={dashboardInfo} />
        </>
      ) : (
        <LoaderRaw />
      )}
    </div>
  );
}

export default MoneyDashboard;
