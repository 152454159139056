import React from "react";
import { Select } from "antd";
import * as API from "../../../api/API";
import jwt_decode from "jwt-decode";

const { Option } = Select;

function OperationNature(props) {
  const operation = props.operation;
  const tokenClient = props.match.params.token;
  const idClient = jwt_decode(tokenClient).clientId;

  const onSubmit = (value) => {
    API.UpdateOperation({
      clientId: idClient,
      title: props.type.title !== undefined ? props.type.title.replace("/", "%2F") : undefined,
      typeId: props.type.typeId,
      categoryId: props.type._id,
      operation,
      nature: value,
      other: props.title === "Autres" ? true : false,
    });
  };

  return (
    <>
      {props.type.typeId === "1" ? (
        <Select
          defaultValue={operation.nature}
          popupClassName="ant-select-dropdown-client"
          className="nature-select"
          placeholder="Nature du mouvement"
          style={{ width: 200 }}
          onChange={(value) => onSubmit(value)}
          placement="bottomLeft"
          dropdownAlign={{ overflow: { adjustY: false } }}
        >
          <Option value="Paiement fournisseur">Paiement fournisseur</Option>
          <Option value="Paiement d'un salarié">Paiement d'un salarié</Option>
          <Option value="Retrait d'un dirigeant">Retrait d'un dirigeant</Option>
          <Option value="Impôt ou charge sociale">Impôt ou charge sociale</Option>
          <Option value="Autre">Autre</Option>
        </Select>
      ) : props.type.typeId === "2" ? (
        <Select
          className="nature-select"
          popupClassName="ant-select-dropdown-client"
          placeholder="Nature du mouvement"
          style={{ width: 200 }}
          onChange={(value) => onSubmit(value)}
          placement="bottomLeft"
          dropdownAlign={{ overflow: { adjustY: false } }}
        >
          <Option value="Paiement client">Paiement client</Option>
          <Option value="Apport d'un dirigeant">Apport d'un dirigeant</Option>
          <Option value="Autre">Autre</Option>
        </Select>
      ) : null}
    </>
  );
}

export default OperationNature;
