import { ArrowRightOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Row, Select } from "antd";
import "dayjs/locale/fr";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import Loader from "../../utils/Loader.js";
import logAction from "../../utils/logActions";
import openNotification from "../../utils/notification";
import Footer from "../footer/Footer.js";
import Nav from "../nav/Nav.js";
import DataProviderTag from "./DataProviderTag.js";
import "./Import.css";

const { Option } = Select;

function ImportDataProvider(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  const [dataProviderCodes, setDataProviderCodes] = useState([]);
  const [allCodes, setAllCodes] = useState([]);
  const [sync, setSync] = useState(false);
  const [codesAdded, setCodesAdded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    (async () => {
      let response = await API.getDataProviderCodes();
      if (response.status === 200) {
        response = await response.json();
        setAllCodes(
          response.codes
            .filter(
              (code) => !accountingFirm.excludedList?.includes(code.name + "*****" + code.code)
            )
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        );

        setLoading(false);
      } else if (response.status === 500) {
        openNotification(
          "error",
          "Une erreur interne est survenue, merci de réessayer dans quelques minutes."
        );
      } else {
        console.log("error");
      }
    })();
  }, []);

  const onFinish = (values) => {
    const clientsList = [];
    const statusListLocal = [];
    values.dataProviderCodes.forEach((elt) => {
      clientsList.push(elt);
      statusListLocal.push("empty");
    });
    setStatusList(statusListLocal);

    setSync(true);
    if (dataProviderCodes.length) setDataProviderCodes([]);
    setDataProviderCodes(clientsList);
    setCodesAdded(true);
  };

  var codes = dataProviderCodes.map((code, index) => {
    return (
      <DataProviderTag
        {...props}
        code={code}
        length={dataProviderCodes.length}
        key={index}
        index={index}
        sync={(data) => setSync(data)}
        allCodes={allCodes}
        statusList={statusList}
        updateStatusList={(data) => setStatusList(data)}
      />
    );
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="import-container">
          <Nav {...props} />
          <Row justify="center" className="import import-acd">
            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
              <Row className="top-box">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className="title-import">Ajouter un client</div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row className="import-submit-row">
                    {!codesAdded ? (
                      <Button
                        type="button"
                        className="other-action-btn return-button-fec"
                        onClick={() => {
                          props.history.push("/homepage");
                        }}
                      >
                        Annuler
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        className="call-action-btn link-button-fec"
                        loading={sync}
                        onClick={() => {
                          setSync(true);
                          logAction(70, 1);
                          setTimeout(function () {
                            setSync(false);
                            props.history.push("/homepage");
                          }, 3000);
                        }}
                      >
                        Valider <ArrowRightOutlined />
                      </Button>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row className="mid-box add-acd-box">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <span className="add-acd-txt">
                    Indiquez ici le code du dossier client à synchroniser avec Sesha. Si vous
                    souhaitez en importer plusieurs, séparez-les par un retour à la ligne.
                  </span>
                  {accountingFirm.dataProvider === "AGIRIS" ? (
                    <Alert
                      className="alert-import"
                      showIcon
                      description={
                        <span>
                          Suite à l'import de votre dossier, 15 à 20 minutes peuvent être
                          nécessaires pour la première synchornisation des données comptables.
                        </span>
                      }
                      type="warning"
                    />
                  ) : accountingFirm.dataProvider === "CEGID EXPERT" ||
                    accountingFirm.dataProvider === "CEGID EXPERT v1" ? (
                    <Alert
                      className="alert-import"
                      showIcon
                      description={
                        <span>
                          Du fait des limitations de l’API Cegid Expert dans le cadre de la première
                          synchronisation, nous vous invitons à{" "}
                          <b>ne pas ajouter plus de 10 dossiers dans Sesha par tranche de 2h.</b>{" "}
                          Par ailleurs, plusieurs minutes à quelques heures peuvent être nécessaires
                          pour récupérer l’ensemble des données du dossier lors de la première
                          synchronisation.
                        </span>
                      }
                      type="warning"
                    />
                  ) : accountingFirm.dataProvider === "CEGID LOOP" ? (
                    <Alert
                      className="alert-import"
                      showIcon
                      description={
                        <span>
                          Du fait des limitations de l’API Cegid Loop dans le cadre de la première
                          synchronisation, nous vous invitons à{" "}
                          <b>ne pas ajouter plus de 10 dossiers dans Sesha par tranche de 2h.</b>{" "}
                          Par ailleurs, plusieurs minutes à quelques heures sont nécessaires pour
                          récupérer l’ensemble des données du dossier lors de la première
                          synchronisation.
                        </span>
                      }
                      type="warning"
                    />
                  ) : null}
                  <Form className="add-acd-form" name="acd" onFinish={onFinish} layout="inline">
                    <Form.Item name="dataProviderCodes" className="select-acd">
                      <Select
                        mode="multiple"
                        notFoundContent="Pas de dossier correspondant"
                        placeholder="Codes des dossiers"
                        allowClear
                        popupClassName="ant-select-dropdown-zindex"
                        placement="bottomLeft"
                        dropdownAlign={{ overflow: { adjustY: false } }}
                      >
                        {allCodes.map((code) => {
                          return (
                            <Option key={code.name + "*****" + code.code}>
                              {code.name} - {code.code}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item className="submit-acd">
                      <Button
                        type="submit"
                        htmlType="submit"
                        className="call-action-btn link-button-fec"
                        disabled={sync}
                      >
                        Synchroniser <ArrowRightOutlined />
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {dataProviderCodes.length ? codes : null}
                </Col>
              </Row>
              <Row className="bottom-box"></Row>
            </Col>
          </Row>
          <Footer />
        </div>
      )}
    </>
  );
}

export default ImportDataProvider;
