import React, { useState, useEffect } from "react";
import { Button, Select, Form, Typography } from "antd";
import { DownloadOutlined, HistoryOutlined } from "@ant-design/icons";
import * as API from "../../../../api/API";
import openNotification from "../../../../utils/notification";
import ClientTable from "./ClientTable";
import SentryTable from "../SentryTable";
import Tracking from "../../../tracking/Tracking";

const { Text } = Typography;

function Support() {
  const [form] = Form.useForm();

  const [accFirms, setAccFirms] = useState();
  const [clients, setClients] = useState();

  const [currentAccFirm, setCurrentAccFirm] = useState();
  const [currentClient, setCurrentClient] = useState();
  const [buttonLoadingFEC, setButtonLoadingFEC] = useState(false);
  const [buttonLoadingS3, setButtonLoadingS3] = useState(false);

  const [showTracking, setShowTracking] = useState(false);

  useEffect(() => {
    (async () => {
      let res = await API.getAccountingFirms();
      res = await res.json();
      setAccFirms(res);
    })();
  }, []);

  const downloadFec = () => {
    (async () => {
      setButtonLoadingFEC(true);
      let res = await API.postExportFec({
        accountingFirmId: currentAccFirm,
        clientRemoteId: currentClient,
      });
      if (res.status === 500) openNotification("error", "Erreur lors du téléchargement des FEC");
      else {
        res = await res.blob();
        const url = URL.createObjectURL(res);
        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          "FEC - " + clients.find((client) => client.remoteId === currentClient).name + ".zip"
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setButtonLoadingFEC(false);
    })();
  };

  const downloadS3 = () => {
    (async () => {
      setButtonLoadingS3(true);
      let res = await API.getExportS3(
        clients.find((client) => client.remoteId === currentClient)._id
      );
      if (res.status === 500)
        openNotification("error", "Erreur lors de la récupération dans le glacier");
      else {
        res = await res.blob();
        const url = URL.createObjectURL(res);
        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          "Fichiers - " + clients.find((client) => client.remoteId === currentClient).name + ".zip"
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setButtonLoadingS3(false);
    })();
  };

  const onChangeAccFirm = (label, options) => {
    (async () => {
      setCurrentClient(null);
      form.setFieldsValue({ clientItem: null });
      let res = await API.getClientsByAccountingFirmId(options.value);
      res = await res.json();
      setClients(res);
      setCurrentAccFirm(options.value);
    })();
  };

  const onChangeClient = (label, options) => {
    setCurrentClient(options.value);
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <div>
      <div className="sesha-support">
        <Tracking
          showModal={showTracking}
          showModalTracking={(data) => setShowTracking(data)}
          clientId={clients?.find((client) => client.remoteId === currentClient)?._id}
          source="support"
        />

        <Form form={form} {...layout} name="control-ref">
          <Form.Item name="accountingFirmItem" label={<b>Cabinet comptable</b>}>
            <Select
              style={{ marginLeft: "20px", width: "250px" }}
              showSearch
              placeholder="Choisir un cabinet"
              onChange={onChangeAccFirm}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={accFirms?.map(({ _id, name }) => ({ value: _id, label: name }))}
              popupClassName="ant-select-dropdown-zindex"
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustY: false } }}
            />
          </Form.Item>

          <Form.Item name="clientItem" label={<b>Client</b>}>
            <Select
              style={{ marginLeft: "20px", width: "250px" }}
              showSearch
              placeholder="Choisir un client"
              onChange={onChangeClient}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={clients?.map(({ remoteId, name }) => ({ value: remoteId, label: name }))}
              disabled={!currentAccFirm}
              popupClassName="ant-select-dropdown-zindex"
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustY: false } }}
            />
          </Form.Item>

          {currentClient ? (
            <div className="dl-buttons">
              <Button
                className="call-action-btn"
                onClick={downloadFec}
                loading={buttonLoadingFEC}
                disabled={!currentClient}
              >
                <DownloadOutlined />
                FEC
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                className="call-action-btn"
                onClick={downloadS3}
                loading={buttonLoadingS3}
                disabled={!currentClient}
              >
                <DownloadOutlined /> Sauvegarde de fichiers
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                className="call-action-btn"
                onClick={() => setShowTracking(true)}
              >
                <HistoryOutlined /> Historique
              </Button>
            </div>
          ) : null}
        </Form>
      </div>

      {currentClient && clients ? (
        <div style={{ marginTop: "10px" }}>
          <Text className="section-title">Récap' dossier</Text>
          <div className="blue-bar"></div>
          <ClientTable
            clients={clients}
            currentAccFirm={currentAccFirm}
            currentClient={currentClient}
            currentClientId={clients.find((client) => client.remoteId === currentClient)._id}
          />
        </div>
      ) : null}

      {currentClient && clients ? (
        <div style={{ marginTop: "40px" }}>
          <Text className="section-title">Dernières erreurs pour ce dossier</Text>
          <div className="blue-bar"></div>
          <SentryTable
            clients={clients}
            currentAccFirm={currentAccFirm}
            currentClient={currentClient}
          />
        </div>
      ) : null}
    </div>
  );
}

export default Support;
