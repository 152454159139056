import React from "react";
import { Area } from "@ant-design/plots";

const MoneyGraphComponentAreas = (props) => {
  var config = {
    height: 250,
    data: props.data,
    xField: props.valuex,
    yField: props.valuey,
    seriesField: props.type,
    xAxis: {
      type: "time",
      mask: "YYYY-MM-DD",
      tickCount: 20,
    },
  };
  return (
    <div className="money-chart-component">
      <p>{props.title}</p>
      <Area {...config} />
    </div>
  );
};
export default MoneyGraphComponentAreas;
