import React, { useState, useEffect } from "react";
import { Form, Button } from "antd";
import "react-quill/dist/quill.snow.css";
import { useSelector, useDispatch } from "react-redux";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectUser, updateUser } from "../../slices/userSlice";
import SignatureTextEditor from "./SignatureTextEditor.js";
import * as API from "../../api/API";
import openNotification from "../../utils/notification";

function UserSignature(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);

  const defaultSignature = `<p>Cordialement</p>
  <p>${user.firstName} ${user.lastName}</p>
  <span style="color:#4569f8; font-size: 16px">${accountingFirm.name}</span>
  `;

  const [logo, setLogo] = useState();
  const [loadingImage, setLoadingImage] = useState(true);
  const [editSignature, setEditSignature] = useState(false);
  const [signatureTxt, setSignatureTxt] = useState(
    user.signature && user.signature !== "" ? user.signature : defaultSignature
  );

  useEffect(() => {
    (async () => {
      let res = await API.getAccountingFirmLogo(accountingFirm._id);
      if (res.status === 200) {
        const data = await res.blob();
        setLogo(URL.createObjectURL(data));
        setLoadingImage(false);
      } else {
        console.log("Error getAccountingFirmLogo");
        setLoadingImage(false);
      }
    })();
  }, []);

  const onSubmit = () => {
    (async () => {
      const res = await API.putUser(user._id, { signature: signatureTxt });
      if (res.status === 201) {
        dispatch(updateUser({ signature: signatureTxt }));
        openNotification("success", "Modification effectuée");
        props.history.push("/homepage");
      } else {
        setSignatureTxt("");
        openNotification("error", "Erreur lors de la modification de la signature");
      }
    })();
  };

  const resetSignature = () => {
    setEditSignature(false);
    (async () => {
      await API.putUser(user._id, {
        signature: defaultSignature,
      });
      setSignatureTxt(defaultSignature);
      dispatch(
        updateUser({
          signature: defaultSignature,
        })
      );
    })();
  };

  return (
    <>
      <Form layout="vertical" onFinish={onSubmit} className="signature-form">
        <div className="form-bnt-block">
          <Button
            className="other-action-btn cancel-btn"
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/homepage");
            }}
          >
            Annuler
          </Button>
          <Form.Item className="submit-changes-btn">
            <Button
              type="primary"
              htmlType="submit"
              className="call-action-btn modal-submit-btn save-signature"
            >
              Sauvegarder
            </Button>
          </Form.Item>
        </div>
        <p style={{ marginTop: "17px" }}>
          Votre signature est utilisée pour les envois d’emails à vos clients via Sesha.
        </p>
        <Form.Item
          name="signature"
          className={editSignature ? "edit-signature" : ""}
          onClick={(e) => setEditSignature(true)}
        >
          <SignatureTextEditor
            {...props}
            text={signatureTxt}
            setText={(data) => setSignatureTxt(data)}
          />
        </Form.Item>
      </Form>
      {loadingImage ? null : <img src={logo ? logo : null} alt="" style={{ maxHeight: "80px" }} />}
      <Button className="other-action-btn cancel-btn reset-signature-btn" onClick={resetSignature}>
        Réinitialiser
      </Button>
    </>
  );
}

export default UserSignature;
