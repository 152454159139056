import React from "react";
import { BidirectionalBar } from "@ant-design/plots";

const MoneyGraphComponentColumnBidirectional = (props) => {
  const max = Math.max.apply(
    Math,
    props.data.map(function (o) {
      return o.sub;
    })
  );
  var config = {
    height: 200,
    layout: "vertical",
    data: props.data,
    xField: props.valuex,
    yField: props.valuey,
    color: ["#4569f8", "#af2727"],
    yAxis: {
      sub: {
        min: 0,
        max: max,
      },
      unsub: {
        min: 0,
        max: max,
      },
    },
    tooltip: {
      shared: true,
      showMarkers: false,
    },
  };
  return (
    <div className="money-chart-component">
      <p>{props.title}</p>
      <BidirectionalBar {...config} />
    </div>
  );
};
export default MoneyGraphComponentColumnBidirectional;
