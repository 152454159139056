import React from "react";
import "./Footer.css";
import { Row, Col, Typography } from "antd";
import FooterCloud from "../../assets/images/footer-cloud.png";

const { Text, Title } = Typography;

function DisconnectedFooter() {
  return (
    <Row justify="center">
      <Col xs={22} sm={22} md={22} lg={22} xl={22}>
        <div className="footer-disconnected">
          <Text className="disconnected-1">
            <Title level={4}>Merci</Title>
            <Title level={4}>
              Vos justificatifs sont en cours de traitement…
            </Title>
          </Text>
          <Text className="disconnected-2">
            <Title level={4}>Vous pouvez fermer la fenêtre.</Title>
          </Text>
        </div>
        <Row className="bottom-box">
          <div className="footer-img-disconnected">
            <img src={FooterCloud} alt="cloud" />
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default DisconnectedFooter;
