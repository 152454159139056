import React from "react";
import { Bar } from "@ant-design/plots";

const MoneyGraphComponentBarHorizontal = (props) => {
  var config = {
    height: 260,
    data: props.data,
    xField: props.valuex,
    yField: props.valuey,
    color: "#4569f8",
  };
  return (
    <div className="money-chart-component">
      <p>{props.title}</p>
      <Bar {...config} />
    </div>
  );
};
export default MoneyGraphComponentBarHorizontal;
