import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import OperationActions from "./OperationActions.js";
import FileUpload from "./FileUpload.js";
import isOperationEmpty from "../../../utils/isOperationEmpty";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";

const parseNum = (num) =>
  parseFloat(num).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

function numberWithThousandSeparator(x) {
  return x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";
}

function OperationLine(props) {
  const [files, setFiles] = useState([]);
  const [comment, setComment] = useState(props.operation.comment);
  const [lost, setLost] = useState(props.operation.isLost);
  const [lostNoAccount, setLostNoAccount] = useState(props.operation.isLostNoAccount);
  const [editComment, setEditComment] = useState(false);

  useEffect(() => {
    setComment(props.operation.comment);
    setLost(props.operation.isLost);
    setLostNoAccount(props.operation.isLostNoAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.operation.comment, props.operation.isLost, props.operation.isLostNoAccount]);

  function changeClass() {
    var opClassName = "";
    if (comment || files.length > 0) {
      opClassName = "status-green";
    } else if (lost || lostNoAccount) {
      opClassName = "status-grey";
    }
    return opClassName;
  }

  useEffect(() => {
    if (
      !props.hide &&
      (comment !== props.operation.comment ||
        lost !== props.operation.isLost ||
        files.length !== props.operation.files.length ||
        lostNoAccount !== props.operation.isLostNoAccount)
    ) {
      props.reload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, comment, lost, lostNoAccount]);

  useEffect(() => {
    const files = [];
    if (props.operation.files) {
      if (props.operation.files.length > 0) {
        props.operation.files.forEach((f) => {
          const name = f.fileName.split("_");
          name.pop();
          files.push({ _id: f._id, filename: name.join("_") + "." + f.fileName.split(".").pop() });
        });
      }
      setFiles(files);
    }
  }, []);

  return (
    <>
      <div className="client-operation">
        <div className={"operation-line-block " + changeClass()}>
          <Row className="op-row">
            <Col xs={22} sm={22} md={22} lg={3} xl={3}>
              <span className="data-op"> {dayjs(props.operation.date).format("DD/MM/YYYY")} </span>{" "}
            </Col>{" "}
            <Col
              xs={12}
              sm={12}
              md={22}
              lg={props.title === "Autres" ? 20 : 7}
              xl={props.title === "Autres" ? 20 : 7}
            >
              <span className="text-op">
                {" "}
                {props.operation.text ? props.operation.text : "[Absence de libellé]"}{" "}
              </span>{" "}
            </Col>{" "}
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              {props.operation.pieceRef &&
              props.operation.pieceRef !== "NONE" &&
              props.operation.piece_ref !== "NAN" &&
              props.title !== "Autres" ? (
                <span className="piece-ref"> {props.operation.pieceRef} </span>
              ) : (
                ""
              )}
            </Col>
            {props.title !== "Autres" ? (
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <span className="piece-ref"> {props.operation.journal} </span>
              </Col>
            ) : null}
            {props.title !== "Autres" ? (
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <span className="client-operation-amount">
                  {" "}
                  {numberWithThousandSeparator(parseNum(Math.abs(props.operation.amount)))}{" "}
                </span>{" "}
              </Col>
            ) : null}{" "}
          </Row>{" "}
        </div>{" "}
        {isOperationEmpty(props.operation) ? (
          <div className="upload-div">
            <FileUpload
              {...props}
              getFiles={(data) => {
                for (const d of data) {
                  if (!files.find((el) => el._id === data._id)) {
                    setFiles([...files, d]);
                  }
                }
              }}
            />
          </div>
        ) : (
          <div className="upload-div-btn">
            <FileUpload
              {...props}
              getFiles={(data) => {
                for (const d of data) {
                  if (!files.find((el) => el._id === data._id)) {
                    setFiles([...files, d]);
                  }
                }
              }}
              btnMode={true}
            />
          </div>
        )}
      </div>
      {props.operation.commentCollab ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "15px",
            paddingLeft: "15px",
          }}
        >
          <span className="popover-comment-text">
            <b>Commentaire cabinet : </b>
            {props.operation.commentCollab}
          </span>
        </div>
      ) : null}
      <div className="client-actions">
        <OperationActions
          {...props}
          files={files}
          invalidComment={
            props.operation.invalidFileComment ? props.operation.invalidFileComment : undefined
          }
          comment={comment}
          isLost={lost}
          isLostNoAccount={lostNoAccount}
          editComment={editComment}
          type={props.type}
          getComment={(data) => setComment(data)}
          getLost={(data) => setLost(data)}
          getLostNoAccount={(data) => setLostNoAccount(data)}
          getDeleteFile={(fileId) => setFiles(files.filter((item) => item._id !== fileId))}
          getEditComment={(data) => setEditComment(data)}
        />{" "}
      </div>{" "}
    </>
  );
}

export default OperationLine;
