import { FrownOutlined, MailOutlined } from "@ant-design/icons";
import {
  Affix,
  Button,
  DatePicker,
  Drawer,
  Form,
  Modal,
  Progress,
  Result,
  Select,
  Skeleton,
} from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import logAction from "utils/logActions";
import * as API from "../../../api/API";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import { getMailDate } from "../../../utils/getDate";
import openNotification from "../../../utils/notification";
import * as number from "../../../utils/number";
import Detailed from "./Detailed";
import ExplainedOp from "./ExplainedOp";
import "./Outstandings.css";

const { Option } = Select;

const filterOperations = (operations) =>
  operations
    ?.filter((op) => !op.filteredParams)
    .filter((op) => !op.filteredDueDays)
    .filter((op) => !op.unchecked) || [];

const accountColumns = [
  {
    title: "N° de compte aux.",
    dataIndex: "accountNumber",
    key: "accountNumber",
    width: "170px",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.accountNumber.localeCompare(b.accountNumber),
  },
  {
    title: "Lib. compte auxiliaire",
    dataIndex: "accountLabel",
    key: "accountLabel",
    width: "170px",
    sorter: (a, b) => a.accountLabel.localeCompare(b.accountLabel),
  },
  {
    title: "Nb de factures",
    key: "invoicesNb",
    align: "center",
    width: "170px",
    specific: "detailed",
    sorter: (a, b) => filterOperations(b.operations).length - filterOperations(a.operations).length,
    render: (text, record) => (
      <span style={{ float: "right" }}>{filterOperations(record.operations).length}</span>
    ),
  },
  {
    title: "Nb de factures",
    key: "invoicesNb",
    align: "center",
    width: "100px",
    specific: "explained",
    sorter: (a, b) => b.operations.length - a.operationslength,
    render: (text, record) => <span style={{ float: "right" }}>{record.operations.length}</span>,
  },
  {
    title: "Encours",
    key: "outstandingsTotal",
    align: "center",
    width: "140px",
    specific: "detailed",
    sorter: (a, b) =>
      Math.abs(filterOperations(b.operations).reduce((acc, current) => acc + current.amount, 0)) -
      Math.abs(filterOperations(a.operations).reduce((acc, current) => acc + current.amount, 0)),
    render: (text, record) => (
      <span style={{ float: "right" }}>
        {number.parseToAmount(
          filterOperations(record.operations).reduce((acc, current) => acc + current.amount, 0)
        )}
      </span>
    ),
  },
  {
    title: "Encours",
    key: "outstandingsTotal",
    align: "center",
    width: "140px",
    specific: "explained",
    sorter: (a, b) =>
      Math.abs(b.operations.reduce((acc, current) => acc + current.amount, 0)) -
      Math.abs(a.operations.reduce((acc, current) => acc + current.amount, 0)),
    render: (text, record) => (
      <span style={{ float: "right" }}>
        {number.parseToAmount(record.operations.reduce((acc, current) => acc + current.amount, 0))}
      </span>
    ),
  },
];

const operationColumns = [
  {
    title: "N° pièce",
    dataIndex: "pieceRef",
    key: "pieceRef",
    width: "150px",
    sorter: (a, b) => a.pieceRef.localeCompare(b.pieceRef),
  },
  {
    title: "Lib. opération",
    dataIndex: "label",
    key: "label",
    width: "150px",
    sorter: (a, b) => a.label.localeCompare(b.label),
  },
  {
    title: "Date de facturation",
    dataIndex: "date",
    key: "date",
    align: "center",
    width: "150px",
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render: (text) => (
      <span style={{ float: "right" }}>{new Date(text)?.toLocaleDateString()}</span>
    ),
  },
  {
    title: "Echéance",
    dataIndex: "dueDays",
    key: "dueDays",
    align: "center",
    width: "120px",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.dueDays - b.dueDays,
    render: (text) => <span style={{ float: "right" }}>{text} jours</span>,
  },
  {
    title: "Encours",
    dataIndex: "amount",
    key: "amount",
    align: "center",
    width: "110px",
    sorter: (a, b) => a.amount - b.amount,
    render: (text) => <span style={{ float: "right" }}>{number.parseToAmount(text)}</span>,
  },
];

const Outstandings = (props) => {
  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;

  dayjs.locale("fr");
  const dispatch = useDispatch();

  const client = useSelector(selectClient);

  const [data, setData] = useState();
  const [showMailDrawer, setShowMailDrawer] = useState(false);
  const [selectedOperations, setSelectedOperations] = useState([]);
  const [selectedOperationsAmount, setSelectedOperationsAmount] = useState(0);
  const [paramsModal, setParamsModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [journalList, setJournalList] = useState([]);
  const [journalListInput, setJournalListInput] = useState([]);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const ref = useRef(0);

  useEffect(() => {
    if (!props.wait)
      (async () => {
        setData();
        setJournalListInput([]);
        setSelectedOperations([]);
        let res = await API.getOutstandings(
          clientId,
          props.date.fiscal_year,
          props.date.month,
          "OUTSTANDINGS_" + props.type
        );

        if (res.status === 200) {
          res = await res.json();
          setData(res.outstandings[props.type]);
          setJournalListInput(res.journals[props.type]);
          setJournalList(
            client?.outstandingsParam[props.type]?.journalList?.length > 0
              ? client?.outstandingsParam[props.type].journalList
              : res.journals[props.type]
          );
          if (res.outstandings[props.type].length === 0) props.setBadge(0);
        } else if (res.status === 500) {
          props.setBadge(0);
          setErrorText("Nous ne parvenons pas à afficher les encours");
          setError(true);
        } else {
          setData([]);
          props.setBadge(0);
        }
      })();
  }, [props.wait, clientRemoteId, props.date, props.type]);

  useEffect(() => {
    if (client.status === "ready") {
      setStartDate(
        (client?.outstandingsParam && client?.outstandingsParam[props.type]?.startDate) ||
          dayjs().subtract(15, "month").startOf("day").toLocaleString()
      );
      setEndDate(
        (client?.outstandingsParam && client?.outstandingsParam[props.type]?.endDate) ||
          dayjs().endOf("day").toLocaleString()
      );
    }
  }, [client]);

  const nbrExplained = () => {
    const nbrExplained = data.reduce(
      (acc, current) => acc + current.operations.filter((op) => op.commentClient)?.length,
      0
    );
    if (nbrExplained > 1) {
      return (
        <div className="nbr-explained">
          <Link to={"#explained-" + props.type}>
            {nbrExplained} éléments expliqués par le client
          </Link>
        </div>
      );
    } else if (nbrExplained === 1) {
      return (
        <div className="nbr-explained">
          <Link to={"#explained-" + props.type}>{nbrExplained} élément expliqué par le client</Link>
        </div>
      );
    } else {
      return <div className="nbr-explained">Aucun élément expliqué par le client</div>;
    }
  };

  const showDrawer = () => {
    setShowMailDrawer(true);
  };

  const onDrawerClose = () => {
    setShowMailDrawer(false);
  };

  const onFinish = (values) => {
    const data = {
      ["outstandingsParam." + props.type + ".startDate"]: dayjs(values.startDate)
        .startOf("day")
        .toLocaleString(),
      ["outstandingsParam." + props.type + ".endDate"]: dayjs(values.endDate)
        .endOf("day")
        .toLocaleString(),
      ["outstandingsParam." + props.type + ".journalList"]: values.journalList,
    };

    (async () => {
      const res = await API.putClient(client._id, data);
      if (res.status !== 201) {
        openNotification("error", "Erreur lors de l'enregistrement des paramètres");
      } else {
        logAction(props.type === "providers" ? 264 : 243, 1, client._id);
        dispatch(
          updateClient({
            outstandingsParam: {
              ...client.outstandingsParam,
              [props.type]: {
                ...client.outstandingsParam[props.type],
                startDate: dayjs(values.startDate).startOf("day").toLocaleString(),
                endDate: dayjs(values.endDate).endOf("day").toLocaleString(),
              },
            },
          })
        );

        setStartDate(dayjs(values.startDate).startOf("day").toLocaleString());
        setEndDate(dayjs(values.endDate).endOf("day").toLocaleString());
        setJournalList(values.journalList);

        setParamsModal(false);
      }
    })();
  };

  useEffect(() => {
    if (props.selectedMailType === "") {
      ref.current = 0;
      props.onRefUpdate(ref);
    }
  }, [props.selectedMailType]);

  return (
    <div>
      {error ? (
        <Result icon={<FrownOutlined style={{ color: "#4569f8" }} />} title={errorText} />
      ) : !data || client.status !== "ready" ? (
        <Skeleton
          className="skeleton-analysis"
          active={true}
          paragraph={{
            rows: 4,
          }}
        />
      ) : (
        <>
          {/*  {props.selectedMailType === props.type ? null : (*/}
          <>
            <Affix offsetTop={120} className="btn-fix-outstandings">
              <div className="site-drawer-render-missingdoc" onMouseDown={onDrawerClose}>
                <Button
                  type="primary"
                  onMouseEnter={showDrawer}
                  className="select-indic-btn"
                  disabled={selectedOperations?.length === 0}
                >
                  <MailOutlined style={{ fontSize: "22px" }} />
                </Button>
                <div
                  onMouseLeave={onDrawerClose}
                  onClick={(e) => {
                    e.preventDefault();
                    ref.current += 1;
                    props.onRefUpdate(ref);
                    props.setSelectedMailType(props.type);
                  }}
                >
                  <Drawer
                    title="Partager avec le client"
                    rootClassName="indic-drawer missing-doc-drawer"
                    placement="right"
                    closable={false}
                    onClose={onDrawerClose}
                    open={showMailDrawer}
                    getContainer={false}
                    rootStyle={{ position: "absolute" }}
                    width={300}
                    mask={false}
                  ></Drawer>
                </div>
              </div>
            </Affix>
            {props.type === "clients" && client.email?.outstandingsclients?.lastEmailSent ? (
              <span className="mail-date-outstandings">
                Dernier envoi {getMailDate(client.email.outstandingsclients.lastEmailSent)}
              </span>
            ) : null}
            {props.type === "providers" && client.email?.outstandingsproviders?.lastEmailSent ? (
              <span className="mail-date-outstandings">
                Dernier envoi {getMailDate(client.email.outstandingsproviders.lastEmailSent)}
              </span>
            ) : null}
          </>
          {/* )}*/}

          <>
            <div className="circle outstandings-circle">
              <Progress
                type="circle"
                percent={100}
                size={80}
                strokeColor="#4569f8"
                format={() => <span className="circular-text">{selectedOperations?.length}</span>}
              />
              <div className="circle-block">
                <span className="outstandings-text">Factures non payées au </span>
                <b>{new Date(endDate).toLocaleDateString()}</b>
                <br />
                <span className="outstandings-text">Pour un montant de </span>
                <b>{number.parseToAmount(Math.abs(selectedOperationsAmount))}</b>
                <br />
                <Button
                  type="link"
                  className={paramsModal ? "change-period-active" : "change-period"}
                  onClick={(e) => {
                    e.preventDefault();
                    setParamsModal(!paramsModal);
                  }}
                >
                  Paramétrer la période d'analyse et les journaux
                </Button>
              </div>
            </div>
            <Modal
              title="Paramétrer la période d'analyse et les journaux"
              className="edit-user-modal"
              open={paramsModal}
              onCancel={(e) => {
                e.preventDefault();
                setParamsModal(false);
              }}
              footer={null}
              // forceRender
            >
              <Form
                layout="vertical"
                name="datepicker"
                onFinish={onFinish}
                initialValues={{
                  startDate: dayjs(startDate),
                  endDate: dayjs(endDate),
                  journalList: journalList,
                }}
              >
                <Form.Item name="startDate" label="Date de début :" className="form-datepicker">
                  <DatePicker
                    locale={locale}
                    allowClear={false}
                    className="import-input"
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
                <Form.Item name="endDate" label="Date de fin :" className="form-datepicker">
                  <DatePicker
                    locale={locale}
                    allowClear={false}
                    className="import-input"
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
                <Form.Item name="journalList" label="Journaux à prendre en compte : ">
                  <Select
                    mode="multiple"
                    /*className="minimum-amount-button"
                    dropdownClassName="ant-select-dropdown-missing-doc"*/
                    placement="bottomLeft"
                    dropdownAlign={{ overflow: { adjustY: false } }}
                  >
                    {journalListInput?.map((journal, i) => (
                      <Option key={i} value={journal}>
                        {journal}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="submit"
                    htmlType="submit"
                    className="call-action-btn link-button-fec submit-date"
                  >
                    Valider
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            {data.length === 0 ? (
              <div>Aucun encours</div>
            ) : (
              <>
                <div>{nbrExplained()}</div>
                <Detailed
                  {...props}
                  data={data}
                  setData={setData}
                  client={client}
                  accountColumns={accountColumns.filter((col) => col.specific !== "explained")}
                  operationColumns={operationColumns.filter((col) => col.key !== "clientComment")}
                  selectedOperations={selectedOperations}
                  setActiveOperations={(data) => {
                    props.setBadge(data?.length);
                    setSelectedOperations(data);
                    if (props.type === "clients") {
                      props.selectedOperationsClients(data);
                    }
                    if (props.type === "providers") {
                      props.selectedOperationsClients(data);
                    }
                  }}
                  setActiveOperationsAmount={(data) => {
                    setSelectedOperationsAmount(data);
                    if (props.type === "clients") {
                      props.setAmountOutstandingsClients(number.parseNum(Math.abs(data)));
                    }
                    if (props.type === "providers") {
                      props.setAmountOutstandingsProviders(number.parseNum(Math.abs(data)));
                    }
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  journalList={journalList}
                />
                <ExplainedOp
                  {...props}
                  data={data
                    .filter((cat) => cat.operations.some((op) => op.commentClient))
                    .map((cat) => ({
                      ...cat,
                      operations: cat.operations.filter((op) => op.commentClient),
                    }))}
                  client={client}
                  accountColumns={accountColumns.filter((col) => col.specific !== "detailed")}
                  operationColumns={operationColumns}
                />
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default Outstandings;
