import { notification } from "antd";

const regexJWT = /(^[\w-]+\.[\w-]+\.[\w-]+$)/g;
const possibleToken = window.location.pathname.split("/").pop();

let token;
if (possibleToken.match(regexJWT)) token = possibleToken;
else token = localStorage.getItem("token");

const displayError = async (res) => {
  if (process.env.REACT_APP_ENV !== "local") return res;
  if (res.status < 200 || res.status >= 300) {
    let parsed = await res.clone().json();
    notification.error({
      message: "Error " + res.status,
      description: res.url + ": " + JSON.stringify(parsed),
      duration: 20,
    });
  }
  return res;
};

const get = async (url) => {
  return displayError(
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

const post = async (url, data) => {
  return displayError(
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  );
};

const rawPost = async (url, data) => {
  return displayError(
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    })
  );
};

const put = async (url, data) => {
  return displayError(
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  );
};

const remove = async (url, data) => {
  return displayError(
    await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  );
};

/* --------- Accounting Firm --------- */

export async function postAccountingFirm(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/accountingfirm", data);
}

export async function postAccountingFirmBackend(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/addAccountingFirm", data);
}

export async function getAccountingFirms() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/accountingfirm");
}

export async function getAccountingFirm(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/accountingfirm/" + id);
}

export async function putAccountingFirm(id, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/accountingfirm/" + id, data);
}

export async function deleteAccountingFirm(id) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/accountingfirm/" + id);
}

export async function getAccountingFirmColor(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/color/" + id);
}

export async function getAccountingFirmFileSettings(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/fileSettings/" + id);
}

/* --------- User --------- */

export async function getUsersLight() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/users");
}

export async function getUsers() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/user");
}

export async function getUser(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/user/" + id);
}

export async function postUser(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/user", data);
}

export async function putUser(id, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/user/" + id, data);
}

export async function deleteUser(id) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/user/" + id);
}

export async function getUsersByAccountingFirmId(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/usersByAccountingFirmId/" + id);
}

export async function changePwd(id, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/user/" + id + "/changePwd", data);
}

export async function notify(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/user/push/notify", data);
}

export async function clickedVPF(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/user/push/clickedVPF", data);
}

/* --------- Role --------- */

export async function getRoles() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/role");
}

export async function getRole(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/role/" + id);
}

export async function postRole(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/role", data);
}

export async function putRole(id, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/role/" + id, data);
}

export async function deleteRole(id) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/role/" + id);
}

/* --------- Feature --------- */

export async function getFeatures() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/feature");
}

/* --------- Authentication --------- */

export async function postGenerateNewToken(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/generateNewToken", data);
}

/* --------- OAuth2 --------- */

export async function postCode(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/oauth2/fulll/sendCode", data);
}

/* --------- Mail --------- */

export async function userCreationMail(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/usercreationmail", data);
}

export async function clientLinkMail(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/clientLinkMail", data);
}

/* --------- MCF --------- */

export async function testMCFConnection(data) {
  const res = await post(process.env.REACT_APP_INTERFACE_BACKEND + "/testMcfConnexion", data);
  return await res.json();
}

/* --------- I-Dépôt --------- */

export async function testIDepotConnection(data) {
  const res = await post(process.env.REACT_APP_INTERFACE_BACKEND + "/testIDepotConnexion", data);
  return await res.json();
}
export async function testIDepotConnectionDashboard(data) {
  const res = await post(
    process.env.REACT_APP_INTERFACE_BACKEND + "/testIDepotConnexionDashboard",
    data
  );
  return await res.json();
}

/* --------- iDocus --------- */

export async function testIDocusConnection(data) {
  const res = await post(process.env.REACT_APP_INTERFACE_BACKEND + "/testIDocusConnexion", data);
  return await res.json();
}

/* ---------- Conciliator ------- */

export async function testConciliatorConnection(data) {
  const res = await post(
    process.env.REACT_APP_INTERFACE_BACKEND + "/testConciliatorConnexion",
    data
  );
  return await res.json();
}

/* --------- Data Provider --------- */

export async function testDataProviderConnexion(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/testDataProviderConnexion", data);
}

export async function putDataProviderUser(data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/dataProvider/user", data);
}

export async function putDataProviderAccountingFirm(data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/dataProvider/accountingFirm", data);
}

export async function postDataProviderClient(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/addDataProviderClient", data);
}

export async function getDataProviderCodes() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/getDataProviderCodes");
}
export async function getAllDataProviderCodes() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/getDataProviderCodes/all");
}

export async function getDataUserRights() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dataProvider/userRights");
}

export async function updateUserRights() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dataProvider/updateUserRights");
}

/* ------------ Client ---------- */

export async function getClient(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + id);
}

export async function putClient(id, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + id, data);
}

export async function deleteClient(id) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + id);
}

export async function postUpdateClient(data) {
  return await rawPost(process.env.REACT_APP_INTERFACE_BACKEND + "/updateClient", data);
}

export async function postImportFec(data) {
  return await rawPost(process.env.REACT_APP_INTERFACE_BACKEND + "/import", data);
}

export async function postExportFec(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/exportFec", data);
}

export async function postToDeleteRemoteClient(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/deleteclient", data);
}

export async function getClientsByAccountingFirmId(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/clientsByAccountingFirmId/" + id);
}

export async function getClientsRemoteId(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/findAllRemoteId/" + id);
}

export async function getClientsTemplate(id, type) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/clientTemplate/" + type + "/" + id);
}

export async function getRemoteClients(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/remoteclientslist/" + id);
}

export async function postIsClientAwaitingUpdate(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/isClientAwaitingUpdate", data);
}

export async function getListOfClientsThatNeedSync(data) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/clientSync", data);
}

/* ------------ Encours ---------- */

export async function getOutstandingsClient(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + id + "/outstandings");
}

export async function putOutstandings(idClient, type, idCategory, idOperation, data) {
  return await put(
    process.env.REACT_APP_INTERFACE_BACKEND +
      "/client/" +
      idClient +
      "/outstandings/" +
      type +
      "/" +
      idCategory +
      "/" +
      idOperation,
    data
  );
}

export async function putUncheckOperations(idClient, type, idCategory, data) {
  return await put(
    process.env.REACT_APP_INTERFACE_BACKEND +
      "/client/" +
      idClient +
      "/outstandings/uncheck/" +
      type +
      "/" +
      idCategory,
    data
  );
}

export async function downloadExplained(id, data) {
  return await post(
    process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + id + "/outstandings/downloadExplained/",
    data
  );
}

/* --------- Input / Synchro --------- */

export async function postSynchroProAbono(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/synchroProAbono", data);
}

export async function getSynchroClients() {
  // remove in op v2
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/synchroClients");
}

export async function getAnalysis(clientId, clientRemoteId, year, month, accountingFirmId = null) {
  return await get(
    process.env.REACT_APP_INTERFACE_BACKEND +
      "/analysis/" +
      clientId +
      "/" +
      clientRemoteId +
      "/" +
      year +
      "/" +
      month +
      (accountingFirmId ? "/" + accountingFirmId : "")
  );
}

export async function getKeyPoints(clientId, clientRemoteId, year, month, accountingFirmId = null) {
  return await get(
    process.env.REACT_APP_INTERFACE_BACKEND +
      "/keyPoints/" +
      clientId +
      "/" +
      clientRemoteId +
      "/" +
      year +
      "/" +
      month +
      (accountingFirmId ? "/" + accountingFirmId : "")
  );
}

export async function getMissingDocuments(clientId, accountingFirmId = null) {
  return await get(
    process.env.REACT_APP_INTERFACE_BACKEND +
      "/missingDocuments/" +
      clientId +
      (accountingFirmId ? "/" + accountingFirmId : "")
  );
}

export async function getOutstandings(clientId, year, month, type, accountingFirmId = null) {
  return await get(
    process.env.REACT_APP_INTERFACE_BACKEND +
      "/outstandings/" +
      clientId +
      "/" +
      year +
      "/" +
      month +
      "/" +
      type +
      (accountingFirmId ? "/" + accountingFirmId : "")
  );
}

export async function synchroClientsManagerView() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/synchroClientsManagerView");
}

export async function postTagMissingDoc(id, data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/tagOperations/" + id, data);
}

export async function postTagOutstandings(id, data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/tagOutstandings/" + id, data);
}

/* --------- Download / Image --------- */

export async function postVerifyDownload(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/verifyDownload", data);
}

export async function postDownload(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/download", data);
}

export async function postDownloadAll(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/downloadAll", data);
}

export async function getDownloadDashboard(data) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/download");
}

export async function getSentryIssues(accountingFirmId, clientId) {
  return await get(
    process.env.REACT_APP_INTERFACE_BACKEND +
      "/dashboard/sentry/client/" +
      accountingFirmId +
      "/" +
      clientId
  );
}

export async function getSentryIssuesByUser(userId) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/sentry/user/" + userId);
}

export async function postImage(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/htmltoimage", data);
}

export async function getExportS3(id, backup) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/exportS3/" + id + "/" + backup);
}

/* --------- Operations ------------ */

export async function getCheckedOperations(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/checkedOperations/" + id);
}

export async function postCheckedOperations(id, data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/checkedOperations/" + id, data);
}

export async function getOperation(idClient, idOperation) {
  return await get(
    process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + idClient + "/operation/" + idOperation
  );
}

export async function putOperation(idClient, idOperation, data) {
  return await put(
    process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + idClient + "/operation/" + idOperation,
    data
  );
}

export async function deleteOperation(idClient, idOperation) {
  return await remove(
    process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + idClient + "/operation/" + idOperation
  );
}

export async function UpdateOperation(data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/operation/updateoperation", data);
}

export async function postToGetFullAccount(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/fullAccount", data);
}

export async function postToResetOperation(id, data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/operation/reset/" + id, data);
}

export async function deleteManyGhostMissingDoc(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/deleteManyGhostMissingDoc", data);
}

export async function deleteGhostMissingDoc(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/deleteGhostMissingDoc", data);
}

/* -------- Document --------- */

export async function postToInvalidDocument(id, data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/operation/invalid/" + id, data);
}

export async function deleteMissingDocumentFile(id, data) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/operation/file/" + id, data);
}

/* -------- Client page --------- */

export async function postToGetClientMissingDoc(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/operation/list", data);
}

/* -------- MCF --------- */

export async function deleteFileMCF(data) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/deleteFileMCF", data);
}

/* -------- Dashboard --------- */

export async function getDashboardOverview() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/overview");
}

export async function getDashboardFirms() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/firms");
}

export async function getDashboardFirmsData() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/firms/data");
}

export async function getDashboardHubspot() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/hubspot");
}

export async function getMoneyDashboard() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/money");
}

export async function getEmailDashboard() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/email");
}

export async function getDashboardRoles() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/dashboard/roles");
}

export async function getDuplicatedClients() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/duplicatedclients");
}
export async function getOrphansClients() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/orphansClients");
}

export async function getOrphansFiles() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/orphansfiles");
}

export async function getDirectDownloadFile(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/directDownloadFile/" + id);
}
export async function getDeleteFile(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/deleteFile/" + id);
}
export async function getOffers() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/proabono/offers");
}
export async function getCustomers() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/proabono/customers");
}
/* ---------- Mails ---------*/

export async function postAnalysisMail(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/analysismail", data);
}

export async function postUserCreationMail(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/usercreationmail", data);
}

/* -------- Schedule -------- */

export async function postScheduleUserCreation(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/scheduleUser", data);
}

export async function getScheduleUsers() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/scheduleUser");
}

export async function putScheduleUser(id, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/scheduleUser/" + id, data);
}

export async function deleteScheduleUser(id) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/scheduleUser/" + id);
}

/* -------- Tracking -------- */

export async function postCollabTracker(id, data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/tracking/collaborator/" + id, data);
}

export async function deleteCollabTracker(idClient, idTracking) {
  return await remove(
    process.env.REACT_APP_INTERFACE_BACKEND +
      "/tracking/collaborator/" +
      idClient +
      "/" +
      idTracking
  );
}

export async function postClientTracker(id, data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/tracking/client/" + id, data);
}

export async function postMissingDocTracker(id, data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/tracking/missingdoc/" + id, data);
}

export async function getTrackerMessenger(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/tracking/messenger/" + id);
}

export async function downloadHistory(id) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/tracking/downloadHistory/" + id);
}

/* ------------- Custom Logo -------- */
export async function postAccountingFirmLogo(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/accountingFirm/logo", data);
}

export async function getAccountingFirmLogo(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/accountingFirm/logo/" + id);
}

export async function removeAccountingFirmLogo(id, data) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/accountingFirm/logo/" + id, data);
}

/* -------------------- News Text --------------- */
export async function getNewsText(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/public/message/" + id);
}

export async function postNewsText(id, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/message/" + id, data);
}

/* -------------------- Vue manager --------------- */

export async function postToRetrieveManagerGlobal(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/manager/global", data);
}

export async function postToRetrieveManagerDetailed(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/manager/detailed", data);
}

export async function postToRetrieveManagerGlobalGraphData(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/manager/graph", data);
}

export async function downloadDetailedView(data) {
  return await post(
    process.env.REACT_APP_INTERFACE_BACKEND + "/manager/downloadDetailedView",
    data
  );
}

export async function downloadReport(id, data) {
  return await post(
    process.env.REACT_APP_INTERFACE_BACKEND + "/manager/report/" + id + "/download",
    data
  );
}

export async function postReport(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/report", data);
}

export async function postToGenerateReport(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/manager/report", data);
}

export async function postToGenerateReportById(id, data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/manager/report/" + id, data);
}

export async function getReports() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/report");
}

export async function getReportsByUser() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/reportsCurrentUser");
}

export async function putReport(id, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/report/" + id, data);
}

export async function getReport(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/report/" + id);
}

export async function getAPECodes() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/ape");
}

/* -------------------- Hubspot --------------- */
export async function getConversationToken() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/hubspot/getConversationToken/");
}

export async function checkCompanyById(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/hubspot/checkCompanyById/", data);
}

export async function getHubspotDeals() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/hubspot/deals/");
}

export async function deleteReport(id) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/report/" + id);
}

/* ------------------ Analysis config -------------------- */

export async function postAnalysisConfigTemplate(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/analysisConfig", data);
}

export async function getAnalysisConfigTemplates() {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/analysisConfig");
}

export async function getAnalysisConfigTemplateById(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/analysisConfig/" + id);
}

export async function putAnalysisConfigTemplate(id, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/analysisConfig/" + id, data);
}

export async function deleteAnalysisConfigTemplate(id) {
  return await remove(process.env.REACT_APP_INTERFACE_BACKEND + "/analysisConfig/" + id);
}

/* ---------------- Indicators -------------------- */

export async function postDeleteIndicator(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/deleteIndicator", data);
}

/* ----------------- Forecast ------------------ */

export async function getIndicators(clientId) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/getForecastPastYear/" + clientId);
}

export async function putForecast(clientId, data) {
  return await put(process.env.REACT_APP_INTERFACE_BACKEND + "/forecast/" + clientId, data);
}

/* -------------- Proabono ---------------- */

export async function upgradeSubscription(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/upgradeSubscription/" + id);
}

export async function getSubscription(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/getSubscription/" + id);
}

export async function getSubscriptions(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/getSubscriptions/" + id);
}

export async function getCustomer(id) {
  return await get(process.env.REACT_APP_INTERFACE_BACKEND + "/getCustomer/" + id);
}

/* -------------- KPI ---------------- */
export async function postLogAction(data) {
  return await post(process.env.REACT_APP_INTERFACE_BACKEND + "/KPI/add", data);
}
