import {
  CloseOutlined,
  DeleteOutlined,
  DownCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleTwoTone,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Button, Carousel, Col, Collapse, Modal, Popover, Row, Spin, Tooltip } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { ReceivedDocHelper } from "helpers/analysis/received/receivedDocHelper";
import { useEffect, useRef, useState } from "react";
import { pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../api/API";
import ConciliatorLogoNotValid from "../../../assets/images/conciliator-error.png";
import ConciliatorLogoValid from "../../../assets/images/conciliator-valid.png";
import { ReactComponent as GreenCircleIcon } from "../../../assets/images/green-circle.svg";
import IDocusLogoNotValid from "../../../assets/images/idocusnotvalid.png";
import IDocusLogoValid from "../../../assets/images/idocusvalid.png";
import IDepotLogo from "../../../assets/images/logo-isuite-depot.png";
import MailToBoxLogoValid from "../../../assets/images/mailtobox.png";
import MCFLogoNotValid from "../../../assets/images/mcfnotvalid.png";
import MCFLogoValid from "../../../assets/images/mcfvalid.png";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient } from "../../../slices/clientSlice";
import { selectUser } from "../../../slices/userSlice";
import openNotification from "../../../utils/notification";
import "../MissingDocuments.css";
import InvalidFile from "./InvalidFile";
import PdfViewer from "./PdfViewer";
import ResetOperation from "./ResetOperation";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Panel } = Collapse;
const { confirm } = Modal;

const getSubTotal = (operations) =>
  Math.abs(operations.reduce((accumulator, obj) => accumulator + obj.amount, 0));

const parseNum = (num) =>
  parseFloat(num).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

function numberWithThousandSeparator(x) {
  return x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";
}

const createtypesList = (data) => {
  let typesList = {};
  data.forEach((element) => {
    if (!typesList.hasOwnProperty(element.typeId)) typesList[element.typeId] = element.typeText;
  });
  return typesList;
};

function DocumentList(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);
  const client = useSelector(selectClient);
  const clientRemoteId = props.match.params.clientRemoteId;

  const [filteredop, setFiltered] = useState([]);
  const [decAmount, setDecAmount] = useState(0);
  const [encAmount, setEncAmount] = useState(0);
  const [invalidated, setInvalidated] = useState([]);
  const [invalidComment, setInvalidComment] = useState([]);
  const [reseted, setReseted] = useState([]);
  const [downloadedOnce, setDownloadedOnce] = useState([]);
  const [otherMissingDocuments, setOtherMissingDocuments] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileShown, setFileShown] = useState();
  const [blobForPreview, setBlobForPreview] = useState();

  const filePreview = useRef();

  const typesList = createtypesList(props.data);
  const nbrReceivedDocuments = props.nbrReceivedDocuments;

  let displayOthersOnce = true;

  // si moins de 30 pièces sont manquantes, alors les différentes catégories sont ouvertes par défaut
  const limitNumberToOpenCollapsesByDefault = 30;

  useEffect(() => {
    setOtherMissingDocuments(client.otherMissingDocuments);
  }, [client]);

  useEffect(() => {
    const dlOnce = [];
    if (client.status === "ready") {
      var amountd = 0;
      var amounte = 0;
      setFiltered(props.data);
      props.data.forEach((elt) => {
        if (elt.typeId === "1") {
          amountd += getSubTotal(elt.operations);
          setDecAmount(parseNum(amountd));
        }
        if (elt.typeId === "2") {
          amounte += getSubTotal(elt.operations);
          setEncAmount(parseNum(amounte));
        }
        elt.operations.forEach((op) => {
          if (op.downloadedOnce) dlOnce.push(op._id);
        });
      });

      const other = [];
      client.otherMissingDocuments?.forEach((op) => {
        if (op.fileId || op.comment || op.files?.length > 0) {
          other.push(op);
        }
        if (op.downloadedOnce) {
          dlOnce.push(op._id);
        }
      });
      props.other(other.length);
      setDownloadedOnce(dlOnce);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.minAmount, props.data, client.otherMissingDocuments, props.journalList]);

  const getHeader = (text, operations) => {
    let accountNumber =
      props.missingDocData?.operationCategories.find((elt) => elt.title === text)?.accountNumber ||
      "";
    return (
      <span>
        <span className="operation-title">
          <GreenCircleIcon className="operation-title-circle" />
        </span>
        <span className="operation-title-text">
          {accountNumber && accountNumber !== "" ? accountNumber + " - " : null}
          {text} - {numberWithThousandSeparator(parseNum(getSubTotal(operations)))}
          {operations.find((op) => op.ghost === 1) ? (
            <ExclamationCircleOutlined
              style={{
                fontSize: "20px",
                color: "#FF9700",
                marginLeft: "5px",
                verticalAlign: "sub",
              }}
            />
          ) : null}
        </span>
      </span>
    );
  };

  const deleteOMD = (idOMD, idFile) => {
    (async () => {
      const res = await API.deleteOperation(client._id, idOMD);
      if (res.status === 200) {
        setOtherMissingDocuments([...otherMissingDocuments.filter((op) => op._id !== idOMD)]);
      } else openNotification("error", "Impossible de supprimer l'opération");
    })();

    if (idFile) {
      (async () => {
        const res = await API.deleteMissingDocumentFile(idFile);
        if (res.status === 201) {
          console.log("File deleted");
        } else console.log("Can't delete file");
      })();
    }
  };

  function confirmDeletion(onConfirm) {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer cet élément ?",
      icon: <ExclamationCircleOutlined />,
      className: "modal-lost modal-others",
      okText: "Oui",
      cancelText: "Non",
      closable: true,
      onOk() {
        onConfirm();
      },
    });
  }

  function confirmGhostDeletion(categoryId, operation) {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer cet élément ?",
      icon: <ExclamationCircleOutlined />,
      className: "modal-lost modal-others",
      okText: "Oui",
      cancelText: "Non",
      closable: true,
      onOk() {
        (async () => {
          await API.deleteGhostMissingDoc({
            clientId: client._id,
            clientRemoteId: clientRemoteId,
            operationId: operation._id,
            accountingFirmId: accountingFirm._id,
          });
        })();
        props.setGhostDocuments((previous) => previous.filter((op) => op._id !== operation._id));
        props.onDeleteOperation({ categoryId: categoryId, operationId: operation._id });
      },
    });
  }

  function openPreview(operation, element) {
    setLoading(true);
    setFileShown(operation);
    setShowPreview(true);
    // eslint-disable-next-line array-callback-return
    ReceivedDocHelper.previewFile(
      client,
      operation.files,
      operation._id,
      element.categoryId,
      true
    ).then((returnedData) => {
      setBlobForPreview(returnedData);
      setLoading(false);
    });
  }

  const closePreview = () => {
    setShowPreview(false);
    for (let i = 0; i < blobForPreview.length; i++) {
      URL.revokeObjectURL(blobForPreview[i]);
    }
  };

  return (
    <>
      <Modal
        title={null}
        footer={null}
        open={showPreview}
        onCancel={() => closePreview()}
        closable={false}
        className="modal-preview"
      >
        {fileShown?.files.length > 1 ? (
          <>
            <Button
              icon={
                <LeftCircleOutlined
                  style={{
                    fontSize: "1.4em",
                    color: "#2F2F2F",
                  }}
                />
              }
              size="large"
              shape="round"
              type="text"
              onClick={() => filePreview.current.prev()}
              className="transparent-btn"
              style={{ left: "-50%", top: "250px" }}
            />
            <Button
              icon={
                <RightCircleOutlined
                  style={{
                    fontSize: "1.4em",
                    color: "#2F2F2F",
                  }}
                />
              }
              size="large"
              shape="round"
              type="text"
              onClick={() => filePreview.current.next()}
              className="transparent-btn"
              style={{
                float: "right",
                right: "-10%",
                top: "250px",
              }}
            />
          </>
        ) : null}
        <Carousel ref={filePreview} dots={false}>
          {fileShown?.files.map((file, index) => {
            const fileExtension = file.fileName.split(".").pop();
            const allowedExtensions = ["jpg", "png", "jpeg", "pdf"];

            if (!allowedExtensions.includes(fileExtension)) {
              return (
                <div>
                  <div className="no-preview" key={index}>
                    <span>
                      <EyeInvisibleOutlined style={{ fontSize: "1.7em" }} />
                      <p>Apperçu non disponible</p>
                      <p>{file.fileName}</p>
                    </span>
                  </div>
                </div>
              );
            }

            return (
              <>
                {loading ? (
                  <div className="no-preview">
                    <Spin size="large" />
                  </div>
                ) : (
                  <div key={index}>
                    <p
                      style={{
                        color: "#373737",
                        fontWeight: "bold",
                        marginTop: "0px",
                        position: "relative",
                        left: "-20%",
                      }}
                    >
                      {file.fileName}
                    </p>
                    {fileExtension === "pdf" ? (
                      <PdfViewer file={blobForPreview[index]} />
                    ) : (
                      <img
                        src={blobForPreview[index]}
                        alt="preview"
                        className="ratio-img"
                        style={{ margin: "auto", height: "auto !important" }}
                      />
                    )}
                  </div>
                )}
              </>
            );
          })}
        </Carousel>
      </Modal>
      {client.status === "ready" ? (
        <>
          {otherMissingDocuments?.map((operation, k) => {
            if (operation.fileId || operation.comment || operation.files?.length > 0) {
              return (
                <span key={k}>
                  {displayOthersOnce ? (
                    <>
                      {" "}
                      <div className="type-title">Autres</div>
                      <div className="type-separation-other"></div>
                    </>
                  ) : null}
                  {(displayOthersOnce = false)}
                  <div className="other-op-received">
                    <div style={{ marginTop: "2%" }}>
                      <div
                        className={
                          invalidated.includes(operation._id) || reseted.includes(operation._id)
                            ? "status-grey site-collapse-custom-text-block"
                            : "site-collapse-custom-text-block"
                        }
                      >
                        <div className="delete-btn-div">
                          <Button
                            shape="circle"
                            className="download-btn delete-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              confirmDeletion(() => {
                                deleteOMD(operation._id, operation.fileId);
                              });
                            }}
                            icon={<DeleteOutlined />}
                          />
                        </div>
                        <p className="other-op-text">{operation.text}</p>
                        {invalidated.includes(operation._id) ? (
                          <span className="not-valid-other">Invalidé</span>
                        ) : reseted.includes(operation._id) ? (
                          <span className="reseted-other">Réinitialisée</span>
                        ) : operation.files.length > 0 ? (
                          <>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                openPreview(operation, "other");
                              }}
                              shape="circle"
                              icon={
                                <EyeOutlined
                                  style={{
                                    top: "1.5px",
                                    position: "relative",
                                  }}
                                />
                              }
                              style={{ margin: " 0 -12px 0 10px" }}
                            />{" "}
                            <Tooltip
                              title={
                                downloadedOnce.includes(operation._id)
                                  ? "Télécharger à nouveau"
                                  : "Télécharger"
                              }
                            >
                              <Button
                                shape="circle"
                                className={
                                  downloadedOnce.includes(operation._id)
                                    ? "download-btn downloaded-once"
                                    : "download-btn "
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  logAction(239, 1, client._id);
                                  if (!downloadedOnce.includes(operation._id)) {
                                    setDownloadedOnce([...downloadedOnce, operation._id]);
                                  }
                                  if (operation.files.length > 0) {
                                    ReceivedDocHelper.downloadFiles(
                                      client,
                                      operation.files,
                                      operation._id
                                    );
                                  }
                                }}
                                icon={<DownloadOutlined />}
                              />
                            </Tooltip>
                          </>
                        ) : null}

                        {!invalidated.includes(operation._id) &&
                        !reseted.includes(operation._id) ? (
                          <Popover
                            title=""
                            align={{ offset: [10, -16] }}
                            overlayClassName="remove-op-popover"
                            content={
                              <>
                                <InvalidFile
                                  {...props}
                                  files={operation.files}
                                  opId={operation._id}
                                  categoryId={null}
                                  other={true}
                                  operationText={operation.text}
                                  invalidated={(data) => {
                                    setInvalidated([...invalidated, data.opId]);
                                    setInvalidComment([
                                      ...invalidComment,
                                      [data.opId, data.invalidcomment],
                                    ]);
                                  }}
                                />
                                <ResetOperation
                                  {...props}
                                  files={operation.files}
                                  opId={operation._id}
                                  categoryId={null}
                                  other={true}
                                  operationText={operation.text}
                                  reseted={(data) => {
                                    setReseted([...reseted, data.opId]);
                                  }}
                                />
                              </>
                            }
                          >
                            {" "}
                            <Button
                              shape="circle"
                              icon={<CloseOutlined />}
                              style={
                                operation.files.length > 0
                                  ? { marginLeft: "10px" }
                                  : { marginLeft: "70px" }
                              }
                            />
                          </Popover>
                        ) : null}
                      </div>
                      <div className="img-ged">
                        {accountingFirm.mcf &&
                        operation.files.length > 0 &&
                        operation.files.length ===
                          operation.files.filter((f) => f.receivedByMCF === true).length ? (
                          <Popover
                            placement="top"
                            content="Justificatif chargé dans MyCompanyFiles"
                            overlayClassName="mcf-success-popover"
                          >
                            <img src={MCFLogoValid} alt="MCF valid" className="mcf-logo" />
                          </Popover>
                        ) : accountingFirm.mcf &&
                          operation.files.length > 0 &&
                          operation.files.length ===
                            operation.files.filter((f) => f.receivedByMCF === false).length ? (
                          <Popover
                            placement="top"
                            content="Erreur de chargement dans MyCompanyFiles"
                            overlayClassName="mcf-fail-popover"
                          >
                            <img src={MCFLogoNotValid} alt="MCF invalid" className="mcf-logo" />
                          </Popover>
                        ) : null}
                        {accountingFirm.iDocus &&
                        operation.files.length > 0 &&
                        operation.files.length ===
                          operation.files.filter((f) => f.receivedByIDocus === true).length ? (
                          <Popover
                            placement="top"
                            content="Justificatif chargé dans iDocus"
                            overlayClassName="mcf-success-popover"
                          >
                            <img src={IDocusLogoValid} alt="iDocus valid" className="idocus-logo" />
                          </Popover>
                        ) : accountingFirm.iDocus &&
                          operation.files.length > 0 &&
                          operation.files.length ===
                            operation.files.filter((f) => f.receivedByIDocus === false).length ? (
                          <Popover
                            placement="top"
                            content="Erreur de chargement dans iDocus"
                            overlayClassName="mcf-fail-popover"
                          >
                            <img
                              src={IDocusLogoNotValid}
                              alt="iDocus invalid"
                              className="idocus-logo"
                            />
                          </Popover>
                        ) : null}
                        {accountingFirm.conciliator &&
                        operation.files.length > 0 &&
                        operation.files.length ===
                          operation.files.filter((f) => f.receivedByConciliator === true).length ? (
                          <Popover
                            placement="top"
                            content="Justificatif chargé dans Conciliator"
                            overlayClassName="mcf-success-popover"
                          >
                            <img
                              src={ConciliatorLogoValid}
                              alt="Conciliator valid"
                              className="idocus-logo"
                            />
                          </Popover>
                        ) : accountingFirm.iDocus &&
                          operation.files.length > 0 &&
                          operation.files.length ===
                            operation.files.filter((f) => f.receivedByConciliator === false)
                              .length ? (
                          <Popover
                            placement="top"
                            content="Erreur de chargement dans Conciliator"
                            overlayClassName="mcf-fail-popover"
                          >
                            <img
                              src={ConciliatorLogoNotValid}
                              alt="Conciliator invalid"
                              className="idocus-logo"
                            />
                          </Popover>
                        ) : null}
                        {accountingFirm.mailToBox &&
                        (client.mailToBox || client.mailToBoxOther) &&
                        operation.files.length > 0 &&
                        operation.files.length ===
                          operation.files.filter((f) => f.receivedByMailToBox === true).length ? (
                          <Popover
                            placement="top"
                            content="Justificatif envoyé par Mail to Box"
                            overlayClassName="mcf-success-popover"
                          >
                            <img
                              src={MailToBoxLogoValid}
                              alt="Mail to Box valid"
                              className="mcf-logo"
                            />
                          </Popover>
                        ) : null}
                        {accountingFirm.iDepot &&
                        operation.files.length > 0 &&
                        operation.files.length ===
                          operation.files.filter((f) => f.receivedByIDepot === true).length ? (
                          <Popover
                            placement="top"
                            content="Justificatif déposé dans la panière"
                            overlayClassName="mcf-success-popover"
                          >
                            <img src={IDepotLogo} alt="IDepot valid" className="mcf-logo" />
                          </Popover>
                        ) : null}
                      </div>
                    </div>
                    {invalidated.includes(operation._id) ? (
                      <div className="comment-div">
                        <p>
                          Invalidation collaborateur ({user.firstName} {user.lastName}) :{" "}
                          {invalidComment.find((comment) => comment[0] === operation._id)?.[1]}
                        </p>
                      </div>
                    ) : null}

                    {operation.comment ? (
                      <div className="comment-div">
                        <span>
                          <b>Commentaire client : </b> {operation.comment}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </span>
              );
            } else return null;
          })}
        </>
      ) : null}
      {Object.entries(typesList).map((type, i) => {
        const filteredopFilteredByType = filteredop.filter(
          (e) => e.typeText === type[1] && e.operations.length > 0
        );
        if (filteredopFilteredByType.length === 0) return null;
        return (
          <span key={i}>
            <>
              <div className="type-title">
                {type[1]} -{" "}
                {type[0] === "1"
                  ? numberWithThousandSeparator(decAmount)
                  : numberWithThousandSeparator(encAmount)}
              </div>
              <div className="type-separation"></div>
            </>
            <Collapse
              bordered={false}
              expandIconPosition="end"
              defaultActiveKey={
                nbrReceivedDocuments <= limitNumberToOpenCollapsesByDefault
                  ? [...Array(300).keys()]
                  : []
              }
              expandIcon={({ isActive }) => (
                <DownCircleOutlined style={{ fontSize: "26px" }} rotate={isActive ? 180 : 0} />
              )}
              className="site-collapse-custom-collapse"
            >
              {filteredop.map((element, j) => {
                if (element.operations?.length === 0) return null;
                if (parseInt(type[0], 10) === parseInt(element.typeId, 10)) {
                  return (
                    <Panel
                      key={j}
                      header={getHeader(element.title, element.operations)}
                      style={{ marginTop: "20px" }}
                    >
                      {element.operations.map((operation, k) => {
                        return (
                          <div key={k}>
                            <span className="operation">
                              <div
                                className={
                                  operation.ghost === 1
                                    ? "site-collapse-custom-text-block-ghost"
                                    : invalidated.includes(operation._id) ||
                                      reseted.includes(operation._id)
                                    ? "status-grey site-collapse-custom-text-block"
                                    : "site-collapse-custom-text-block"
                                }
                              >
                                <Row className="op-row">
                                  <Col xs={22} sm={22} md={4} lg={4} xl={4}>
                                    <span className="data-op">
                                      {dayjs(operation.date).format("DD/MM/YYYY")}
                                    </span>
                                  </Col>
                                  <Col xs={22} sm={22} md={7} lg={7} xl={7}>
                                    {operation.text ? operation.text : "[Absence de libellé]"}
                                  </Col>
                                  <Col xs={12} sm={12} md={6} lg={6} xl={6} className="piece-ref">
                                    {operation.pieceRef &&
                                    operation.pieceRef !== "NONE" &&
                                    operation.pieceRef !== "NAN"
                                      ? operation.pieceRef
                                      : ""}
                                  </Col>
                                  <Col xs={10} sm={10} md={6} lg={6} xl={6} offset={1}>
                                    <div className="div-received-btn">
                                      <span className="operation-amount">
                                        {numberWithThousandSeparator(
                                          parseNum(Math.abs(operation.amount))
                                        )}
                                      </span>

                                      {invalidated.includes(operation._id) ? (
                                        <span className="not-valid">Invalidé</span>
                                      ) : reseted.includes(operation._id) ? (
                                        <span className="not-valid">Réinitialisée</span>
                                      ) : operation.fileId || operation.files.length > 0 ? (
                                        <>
                                          <Button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              openPreview(operation, element);
                                            }}
                                            shape="circle"
                                            icon={
                                              <EyeOutlined
                                                style={{
                                                  top: "1.5px",
                                                  position: "relative",
                                                }}
                                              />
                                            }
                                            style={{ margin: " 0 -12px 0 10px" }}
                                          />{" "}
                                          <Tooltip
                                            title={
                                              downloadedOnce.includes(operation._id)
                                                ? "Télécharger à nouveau"
                                                : "Télécharger"
                                            }
                                          >
                                            <Button
                                              shape="circle"
                                              className={
                                                operation.ghost === 1
                                                  ? downloadedOnce.includes(operation._id)
                                                    ? "download-btn-ghost downloaded-once-ghost"
                                                    : "download-btn-ghost"
                                                  : downloadedOnce.includes(operation._id)
                                                  ? "download-btn downloaded-once"
                                                  : "download-btn "
                                              }
                                              onClick={(e) => {
                                                e.preventDefault();
                                                logAction(239, 1, client._id);
                                                if (!downloadedOnce.includes(operation._id)) {
                                                  setDownloadedOnce([
                                                    ...downloadedOnce,
                                                    operation._id,
                                                  ]);
                                                }
                                                if (operation.files.length > 0) {
                                                  ReceivedDocHelper.downloadFiles(
                                                    client,
                                                    operation.files,
                                                    operation._id,
                                                    element.categoryId
                                                  );
                                                }
                                              }}
                                              icon={<DownloadOutlined />}
                                            />
                                          </Tooltip>
                                        </>
                                      ) : null}
                                      {operation.ghost === 1 ? (
                                        <Tooltip title="Supprimer" color="#FAAD14">
                                          <Button
                                            className="delete-ghost-btn"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              confirmGhostDeletion(element.categoryId, operation);
                                            }}
                                            shape="circle"
                                            icon={<CloseOutlined />}
                                            style={{ marginLeft: "10px" }}
                                          />
                                        </Tooltip>
                                      ) : !invalidated.includes(operation._id) &&
                                        !reseted.includes(operation._id) ? (
                                        <Popover
                                          title=""
                                          //placement="top"
                                          align={{ offset: [10, -16] }}
                                          overlayClassName="remove-op-popover"
                                          content={
                                            <>
                                              <InvalidFile
                                                {...props}
                                                files={operation.files}
                                                opId={operation._id}
                                                categoryId={element.categoryId}
                                                other={false}
                                                operationText={
                                                  operation.text
                                                    ? operation.text
                                                    : "[Absence de libellé]"
                                                }
                                                invalidated={(data) => {
                                                  setInvalidated([...invalidated, data.opId]);
                                                  setInvalidComment([
                                                    ...invalidComment,
                                                    [data.opId, data.invalidcomment],
                                                  ]);
                                                }}
                                              />
                                              <ResetOperation
                                                {...props}
                                                files={operation.files}
                                                opId={operation._id}
                                                categoryId={element.categoryId}
                                                other={false}
                                                operationText={
                                                  operation.text
                                                    ? operation.text
                                                    : "[Absence de libellé]"
                                                }
                                                reseted={(data) => {
                                                  setReseted([...reseted, data.opId]);
                                                }}
                                              />
                                            </>
                                          }
                                        >
                                          {" "}
                                          <Button
                                            shape="circle"
                                            icon={<CloseOutlined />}
                                            style={{ marginLeft: "10px" }}
                                          />
                                        </Popover>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="img-ged">
                                {accountingFirm.mcf &&
                                operation.files.length > 0 &&
                                operation.files.length ===
                                  operation.files.filter((f) => f.receivedByMCF === true).length ? (
                                  <Popover
                                    placement="top"
                                    content="Justificatif chargé dans MyCompanyFiles"
                                    overlayClassName="mcf-success-popover"
                                  >
                                    <img src={MCFLogoValid} alt="MCF valid" className="mcf-logo" />
                                  </Popover>
                                ) : accountingFirm.mcf &&
                                  operation.files.length > 0 &&
                                  operation.files.length ===
                                    operation.files.filter((f) => f.receivedByMCF === false)
                                      .length ? (
                                  <Popover
                                    placement="top"
                                    content="Erreur de chargement dans MyCompanyFiles"
                                    overlayClassName="mcf-fail-popover"
                                  >
                                    <img
                                      src={MCFLogoNotValid}
                                      alt="MCF invalid"
                                      className="mcf-logo"
                                    />
                                  </Popover>
                                ) : null}
                                {accountingFirm.iDocus &&
                                operation.files.length > 0 &&
                                operation.files.length ===
                                  operation.files.filter((f) => f.receivedByIDocus === true)
                                    .length ? (
                                  <Popover
                                    placement="top"
                                    content="Justificatif chargé dans iDocus"
                                    overlayClassName="mcf-success-popover"
                                  >
                                    <img
                                      src={IDocusLogoValid}
                                      alt="iDocus valid"
                                      className="idocus-logo"
                                    />
                                  </Popover>
                                ) : accountingFirm.iDocus &&
                                  operation.files.length > 0 &&
                                  operation.files.length ===
                                    operation.files.filter((f) => f.receivedByIDocus === false)
                                      .length ? (
                                  <Popover
                                    placement="top"
                                    content="Erreur de chargement dans iDocus"
                                    overlayClassName="mcf-fail-popover"
                                  >
                                    <img
                                      src={IDocusLogoNotValid}
                                      alt="iDocus invalid"
                                      className="idocus-logo"
                                    />
                                  </Popover>
                                ) : null}
                                {accountingFirm.conciliator &&
                                operation.files.length > 0 &&
                                operation.files.length ===
                                  operation.files.filter((f) => f.receivedByConciliator === true)
                                    .length ? (
                                  <Popover
                                    placement="top"
                                    content="Justificatif chargé dans Conciliator"
                                    overlayClassName="mcf-success-popover"
                                  >
                                    <img
                                      src={ConciliatorLogoValid}
                                      alt="Conciliator valid"
                                      className="conciliator-logo"
                                    />
                                  </Popover>
                                ) : accountingFirm.conciliator &&
                                  operation.files.length > 0 &&
                                  operation.files.length ===
                                    operation.files.filter((f) => f.receivedByConciliator === false)
                                      .length ? (
                                  <Popover
                                    placement="top"
                                    content="Erreur de chargement dans Conciliator"
                                    overlayClassName="mcf-fail-popover"
                                  >
                                    <img
                                      src={ConciliatorLogoNotValid}
                                      alt="Conciliator invalid"
                                      className="conciliator-logo"
                                    />
                                  </Popover>
                                ) : null}
                                {accountingFirm.mailToBox &&
                                (client.mailToBox || client.mailToBoxSales) &&
                                operation.files.length > 0 &&
                                operation.files.length ===
                                  operation.files.filter((f) => f.receivedByMailToBox === true)
                                    .length ? (
                                  <Popover
                                    placement="top"
                                    content="Justificatif envoyé par Mail to Box"
                                    overlayClassName="mcf-success-popover"
                                  >
                                    <img
                                      src={MailToBoxLogoValid}
                                      alt="Mail to Box valid"
                                      className="mcf-logo"
                                    />
                                  </Popover>
                                ) : null}
                                {accountingFirm.iDepot &&
                                operation.files.length > 0 &&
                                operation.files.length ===
                                  operation.files.filter((f) => f.receivedByIDepot === true)
                                    .length ? (
                                  <Popover
                                    placement="top"
                                    content="Justificatif déposé dans la panière"
                                    overlayClassName="mcf-success-popover"
                                  >
                                    <img src={IDepotLogo} alt="IDepot valid" className="mcf-logo" />
                                  </Popover>
                                ) : null}
                              </div>
                            </span>
                            {operation.ghost === 1 ? (
                              <Popover
                                placement="bottom"
                                dropdownAlign={{ overflow: { adjustY: false } }}
                                content={
                                  <span>
                                    Nous ne parvenons pas à retrouver <b>l’identifiant </b>
                                    correspondant à cette pièce dans les nouvelles données
                                    transmises par votre outil de production. <br />
                                    Nous vous invitons à :
                                    <ul>
                                      <li>
                                        la <b>télécharger</b> si nécessaire
                                      </li>
                                      <li>
                                        la <b>supprimer</b> si celle-ci est déjà lettrée
                                      </li>
                                    </ul>
                                    Sans action de votre part, la pièce sera automatiquement
                                    supprimée le <b>{operation.ghostDeleteDate}</b>. Pour plus
                                    d’informations, n’hésitez pas nous contacter.{" "}
                                  </span>
                                }
                                className="ghost-popover"
                                overlayClassName="ghost-popover-overlay"
                              >
                                <span className="ghost-op-info-received">
                                  Nous ne parvenons pas à trouver l’identifiant correspondant à
                                  cette pièce. <ExclamationCircleOutlined />
                                </span>
                              </Popover>
                            ) : null}
                            <div className="comment-div">
                              {invalidated.includes(operation._id) ? (
                                <p>
                                  Invalidation collaborateur ({user.firstName} {user.lastName}) :{" "}
                                  {
                                    invalidComment.find(
                                      (comment) => comment[0] === operation._id
                                    )?.[1]
                                  }
                                </p>
                              ) : null}
                              {operation.isLost || operation.isLostNoAccount ? (
                                <p className="islost">
                                  {operation.isLost ? "Justificatif perdu – Récupérable" : null}
                                  {operation.isLostNoAccount
                                    ? "Justificatif perdu – Non récupérable"
                                    : null}
                                </p>
                              ) : null}
                              {operation.nature ? (
                                <p className="nature">Nature du mouvement : {operation.nature}</p>
                              ) : null}
                              {operation.comment ? (
                                <p>
                                  <b>Commentaire client : </b> {operation.comment}
                                </p>
                              ) : null}
                              {operation.commentCollab ? (
                                <p>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Popover
                                      placement="right"
                                      content={
                                        <span className="popover-comment-text">
                                          <b>Commentaire cabinet : </b>
                                          {operation.commentCollab}
                                        </span>
                                      }
                                      className="popover-comment"
                                    >
                                      <InfoCircleTwoTone
                                        twoToneColor="#4569f8"
                                        style={{ fontSize: "120%" }}
                                      />
                                    </Popover>
                                  </span>
                                </p>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </Panel>
                  );
                } else return "";
              })}
            </Collapse>
          </span>
        );
      })}
    </>
  );
}

export default DocumentList;
