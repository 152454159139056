import * as dayjs from "dayjs";
import "dayjs/locale/fr";

function getDate() {
  var d = new Date();
  var n = d.getDate();
  var date_table = [];
  dayjs.locale("fr");
  var start_date = n < 15 ? dayjs().subtract(2, "months") : dayjs().subtract(1, "months");

  for (var i = 0; i < 13; i++) {
    date_table.push(dayjs().subtract(i, "months").format("MMMM YYYY"));
  }

  return {
    start_date: start_date.format("MMMM YYYY"),
    date_table,
    date_obj: start_date.startOf("month"),
  };
}

const getMailDate = (date) => {
  const mailDate = dayjs(date);
  return "le " + mailDate.format("DD/MM/YYYY") + " à " + mailDate.format("HH[h]mm");
};

export { getDate, getMailDate };
