import { Button } from "antd";
import { useState } from "react";
import logAction from "utils/logActions";
import * as API from "../../api/API";

function Header(props) {
  const [isDownloading, setIsDownloading] = useState(false);

  function downloadHistory() {
    (async () => {
      let res = await API.downloadHistory(props.clientId);
      res.arrayBuffer().then(function (buffer) {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          var blob = new Blob([buffer], { type: "" });
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "historique.xlsx");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsDownloading(false);
        }
      });
    })();
  }

  return (
    <>
      <div className="tracker-modal-header">
        <h2>Historique des échanges</h2>
        <Button
          className="call-action-btn download-csv-btn"
          onClick={(e) => {
            e.preventDefault();
            logAction(246, 1, props.clientId);
            setIsDownloading(true);
            downloadHistory();
          }}
          loading={isDownloading}
        >
          Télécharger l'historique complet (csv)
        </Button>
      </div>
    </>
  );
}

export default Header;
