import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Typography } from "antd";
import { EFeatures } from "enums/EFeatures";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logAction from "utils/logActions";
import { hasFeatures } from "utils/security";
import * as API from "../../../api/API";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import LoaderRaw from "../../../utils/LoaderRaw";
import "../manager.css";

const { Text } = Typography;

const howManyDaysSince = (date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(date);
  const secondDate = new Date();

  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
};

function Detailed(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  const [dataSource, setdataSource] = useState();

  /* search */
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    (async () => {
      let res = await API.postToRetrieveManagerDetailed({
        startDate: props.startDate,
        endDate: props.endDate,
      });
      res = await res.json();
      setdataSource(res.map((el) => ({ ...el, key: el._id })));
    })();
  }, [props.startDate, props.endDate]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={"Raison sociale"}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 120 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
            Réinitialiser
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "var(--blue)" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Raison sociale",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "ascend",
      width: "200px",
      ...getColumnSearchProps("name"),
      render: (text, record) => {
        return (
          <>
            {searchedColumn === "name" ? (
              <Link
                className="client-link"
                to={{
                  pathname: `/analysis/${record._id}/${record.remoteId}/`,
                }}
                target="_blank"
              >
                <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={record.name}
                />
              </Link>
            ) : (
              <Link
                className="client-link"
                to={{
                  pathname: `/analysis/${record._id}/${record.remoteId}/`,
                }}
                target="_blank"
              >
                {record.name}
              </Link>
            )}
          </>
        );
      },
    },
    {
      title: "Analyses envoyées",
      dataIndex: "analysisCount",
      sorter: (a, b) => a.analysisCount - b.analysisCount,
      render: (text) =>
        text === null || text === "" ? (
          ""
        ) : (
          <span
            style={{
              float: "right",
            }}
          >
            {text}
          </span>
        ),
    },
    {
      title: "Dernière analyse envoyée",
      dataIndex: "lastAnalysisEmailSent",
      sorter: (a, b) => {
        let x, y;
        x = a.email?.analysis?.lastEmailSent
          ? new Date(a.email?.analysis?.lastEmailSent)
          : new Date("2020-01-01T00:00:00Z");
        y = b.email?.analysis?.lastEmailSent
          ? new Date(b.email?.analysis?.lastEmailSent)
          : new Date("2020-01-01T00:00:00Z");
        return x > y ? 1 : -1;
      },
      render: (text, row) => {
        if (row.email?.analysis?.lastEmailSent) {
          const days = howManyDaysSince(row.email?.analysis?.lastEmailSent);
          switch (days) {
            case 0:
              return "Aujourd'hui";
            case 1:
              return "Hier";
            default:
              return days + " jours";
          }
        } else return "Jamais";
      },
    },
    {
      title: "Relances pièces manquantes",
      dataIndex: "missingDocEmailCount",
      sorter: (a, b) => a.missingDocEmailCount - b.missingDocEmailCount,
      render: (text) =>
        text === null || text === "" ? (
          ""
        ) : (
          <span
            style={{
              float: "right",
            }}
          >
            {text}
          </span>
        ),
    },
    {
      title: "Dernière relance pièces manquantes",
      dataIndex: "lastMissingDocEmailSent",
      sorter: (a, b) => {
        let x, y;
        x = a.email?.missingdoc?.lastEmailSent
          ? new Date(a.email?.missingdoc?.lastEmailSent)
          : new Date("2020-01-01T00:00:00Z");
        y = b.email?.missingdoc?.lastEmailSent
          ? new Date(b.email?.missingdoc?.lastEmailSent)
          : new Date("2020-01-01T00:00:00Z");
        return x > y ? 1 : -1;
      },
      render: (text, row) => {
        if (row.email?.missingdoc?.lastEmailSent) {
          const days = howManyDaysSince(row.email?.missingdoc?.lastEmailSent);
          switch (days) {
            case 0:
              return "Aujourd'hui";
            case 1:
              return "Hier";
            default:
              return days + " jours";
          }
        } else return "Jamais";
      },
    },
    {
      title: "Pièces reçues",
      dataIndex: "receivedDocCount",
      sorter: (a, b) => a.receivedDocCount - b.receivedDocCount,
      render: (text) =>
        text === null || text === "" ? (
          ""
        ) : (
          <span
            style={{
              float: "right",
            }}
          >
            {text}
          </span>
        ),
    },
    {
      title: "Pièces reçues / pièces demandées",
      dataIndex: "receivedVsRequested",
      sorter: (a, b) => a.receivedVsRequested - b.receivedVsRequested,
      render: (text, row) =>
        row.receivedVsRequested ? (
          <span
            style={{
              float: "right",
            }}
          >
            {row.receivedVsRequested.toString().replace(".", ",") + " %"}
          </span>
        ) : (
          ""
        ),
      hidden: accountingFirm.fec,
    },
    {
      title: "Collaborateur",
      dataIndex: "usernames",
      // sorter: (rowA, rowB) => rowA.username.localeCompare(rowB.username),
      render: (text) => {
        if (text?.length > 0) return text.join(", ");
      },
    },
    {
      title: accountingFirm.fec ? "Dernier chargement de FECs" : "Dernière mise à jour des données",
      dataIndex: "updateDate",
      sorter: (a, b) => {
        let x, y;
        x = a.updateDate ? new Date(a.updateDate) : new Date("2020-01-01T00:00:00Z");
        y = b.updateDate ? new Date(b.updateDate) : new Date("2020-01-01T00:00:00Z");
        return x > y ? 1 : -1;
      },
      render: (text, row) =>
        row.updateDate ? new Date(row.updateDate).toLocaleDateString("fr-FR") : "",
    },
  ].filter((item) => !item.hidden);

  function downloadDetailedView() {
    (async () => {
      let res = await API.downloadDetailedView(dataSource || []);
      res.arrayBuffer().then(function (buffer) {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          var blob = new Blob([buffer], { type: "" });
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "UtilisationDeSesha.xlsx");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    })();
  }

  return (
    <div className="detailed-section">
      <Button
        className="call-action-btn download-csv-btn"
        onClick={(e) => {
          e.preventDefault();
          logAction(215, 1);
          downloadDetailedView();
        }}
      >
        {" "}
        Exporter (csv){" "}
      </Button>{" "}
      <Text className="section-title">Détail par dossier client</Text>
      <div className="blue-bar"></div>
      <Table
        className="hp-client-list"
        rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
        columns={columns}
        dataSource={
          !hasFeatures([EFeatures.MANAGE_USERS_AND_RIGHTS])
            ? dataSource?.filter((client) => client.active)
            : dataSource
        }
        sortDirections={["ascend", "descend", "ascend"]}
        showSorterTooltip={false}
        size="small"
        pagination={{ defaultPageSize: 20, showSizeChanger: true }}
        loading={{ indicator: <LoaderRaw />, spinning: !dataSource }}
      />
    </div>
  );
}

export default Detailed;
