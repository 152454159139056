import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Switch } from "antd";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import * as API from "../../../api/API";

const { confirm } = Modal;

function LostDocument(props) {
  const operation = props.operation;
  const tokenClient = props.match.params.token;
  const idClient = jwt_decode(tokenClient).clientId;

  const [switched, setSwitched] = useState(operation.isLost || operation.isLostNoAccount);

  const onSubmit = (value) => {
    (async () => {
      const res = await API.UpdateOperation({
        clientId: idClient,
        title: props.type.title !== undefined ? props.type.title.replace("/", "%2F") : undefined,
        typeId: props.type.typeId,
        categoryId: props.type._id,
        operation,
        isLost: value.isLost,
        isLostNoAccount: value.isLostNoAccount,
      });
      if (res.status === 200) {
        API.postClientTracker(idClient, {
          action: "LOST",
          operationId: operation._id,
          dateOp: operation.date,
          type: props.type.typeText,
          category:
            props.type.title !== undefined ? props.type.title.replace("/", "%2F") : "Autres",
          label: operation.text,
          reference: operation.pieceRef,
          amount: operation.amount,
          nature: operation.nature,
          isLost: value.isLost || value.isLostNoAccount,
        });
        if (value.isLost || value.isLostNoAccount)
          API.notify({ clientId: idClient, action: "missingDoc" });
      }
    })();
  };

  function showConfirm(op) {
    confirm({
      title: "Pensez-vous pouvoir retrouver ce justificatif ?",
      icon: <ExclamationCircleOutlined className="modal-lost-alert-icon" />,
      className: "modal-lost",
      okText: "Oui",
      cancelText: "Non",
      maskClosable: false,
      closable: false,
      keyboard: false,
      content: (
        <>
          <Alert
            className="modal-lost-alert"
            description={
              <p>
                <span>
                  Nous attirons votre attention sur le fait que la non présentation de la pièce
                  justificative entraînera immédiatement la non récupération de la TVA et en cas de
                  contrôle :
                </span>
                <br />
                <span> - la non déductibilité de la charge</span>
                <br />
                <span>et, en fonction de votre régime d’imposition : </span>
                <br />
                <span> - un rappel d’impôt sur le revenu et un complément de charges sociales</span>
                <br />
                <span>ou : </span>
                <br />
                <span> - un rappel d'impôt sur les sociétés</span>
              </p>
            }
            type="warning"
          />

          <Button
            className="cancel-lost other-action-btn"
            onClick={(e) => {
              e.preventDefault();
              Modal.destroyAll();
            }}
          >
            Annuler
          </Button>
        </>
      ),
      onOk() {
        onSubmit({ isLost: true, isLostNoAccount: false });
        setSwitched(true);
        props.getLost(true);
      },
      onCancel() {
        Modal.destroyAll();
        onSubmit({ isLost: false, isLostNoAccount: true });
        setSwitched(true);
        props.getLostNoAccount(true);
      },
    });
  }

  if (props.title !== "Autres")
    return (
      <div className="switch-div">
        <span className="lost-switch">Perdu ?</span>
        <Switch
          size="small"
          checked={switched}
          onChange={(c, e) => {
            e.preventDefault();
            if (c === true) {
              showConfirm(operation);
            } else {
              setSwitched(false);
              props.getLost(false);
              props.getLostNoAccount(false);
              onSubmit({ isLost: false, isLostNoAccount: false });
            }
          }}
        />
      </div>
    );
  else return null;
}

export default LostDocument;
