import {
  ArrowRightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Switch } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../api/API";
import { selectAccountingFirm, updateAccountingFirm } from "../../../slices/accountingFirmSlice";
import openNotification from "../../../utils/notification";

function AdminIDepot() {
  const dispatch = useDispatch();
  const accountingFirm = useSelector(selectAccountingFirm);

  const [isIDepotActivated, setIsIDepotActivated] = useState();
  const [checkIDepotConnexion, setCheckIDepotConnexion] = useState();
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const updateAccountingFirmAPI = async (data) => {
    try {
      await API.putAccountingFirm(accountingFirm._id, { iDepot: data });
      setUpdate(!update);
      openNotification("success", "Modifications enregistrées");
    } catch (error) {
      console.log(error);
      openNotification("error", "Erreur lors de l'enregistrement");
    }
  };

  const onFinish = (values) => {
    (async () => {
      const res = await API.testIDepotConnection({
        acdUrl: accountingFirm.acdUrl,
        login: values.login,
        password: values.password,
        cnx: accountingFirm.cnx,
      });
      if (res.statusCode === 0) {
        setCheckIDepotConnexion(true);
        setIsIDepotActivated(true);
        dispatch(
          updateAccountingFirm({
            ...accountingFirm,
            iDepot: {
              login: values.login,
              password: values.password,
            },
          })
        );
        updateAccountingFirmAPI({
          login: values.login,
          password: values.password,
        });
      } else {
        setCheckIDepotConnexion(false);
      }
    })();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    openNotification("error", "Erreur lors de l'enregistrement");
  };

  const testIDepotConnection = async () => {
    const res = await API.testIDepotConnection({
      acdUrl: accountingFirm.acdUrl,
      login: accountingFirm.iDepot.login,
      password: accountingFirm.iDepot.password,
      cnx: accountingFirm.cnx,
    });
    setCheckIDepotConnexion(res.statusCode === 0);
    setLoading(false);
  };

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      setLoading(true);
      if (accountingFirm.iDepot?.login && accountingFirm.iDepot?.password) {
        setIsIDepotActivated(true);
        testIDepotConnection();
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  useEffect(() => {
    if (isIDepotActivated === false) {
      dispatch(
        updateAccountingFirm({
          ...accountingFirm,
          iDepot: {
            login: null,
            password: null,
          },
        })
      );
      updateAccountingFirmAPI({ login: null, password: null });
      setCheckIDepotConnexion(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIDepotActivated]);

  return (
    <>
      {loading ? (
        ""
      ) : (
        <Col>
          <p className="settings-category-p">Connexion à I-Dépôt : </p>
          {/*<div className="how-to-box how-to-mcf">
            <div className="how-to-img-div">
              <QuestionCircleOutlined className="how-to-img" />
            </div>

            <div className="how-to-link">
              <a
                href="https://docs.google.com/document/d/19_NTdN3qQVhnmxX00VBDpAKGvZ2ss6rhdtCscMmAOfA/edit?usp=sharing"
                target="_blank"
              >
                Comment connecter Sesha à MyCompanyFiles ?
              </a>
            </div>
      </div>*/}
          <div className="iDepot-switch">
            <Switch
              checked={isIDepotActivated}
              onChange={() => {
                setIsIDepotActivated((previous) => {
                  !previous ? logAction(209, 1) : logAction(210, 1);
                  return !previous;
                });
              }}
            />
            <span>
              {isIDepotActivated ? (
                checkIDepotConnexion ? (
                  "Activée"
                ) : (
                  <span>
                    Nous vous invitons à renseigner{" "}
                    <b>
                      un profil utilisateur ayant accès uniquement à i-dépôt sur l’ensemble de vos
                      dossiers client
                    </b>{" "}
                    et cliquer sur "Valider".
                  </span>
                )
              ) : (
                "Désactivée"
              )}
            </span>
          </div>

          {isIDepotActivated ? (
            <>
              {checkIDepotConnexion ? (
                <div className="mcf-valid-block">
                  <CheckCircleFilled className="mcf-valid-icon" />
                  <p className="mcf-valid-text">
                    <b>Félicitations, Sesha est connecté à I-Dépôt.</b>
                  </p>
                  <p>
                    Les pièces reçues de vos clients sont automatiquement chargées dans la zone de
                    dépôt correspondante.
                  </p>
                </div>
              ) : (
                <div className="mcf-invalid-block">
                  <CloseCircleFilled className="mcf-invalid-icon" />
                  <p className="mcf-invalid-text">
                    <b>Identifiant ou mot de passe non reconnu</b>
                  </p>
                  <p>Veuillez essayer à nouveau.</p>
                </div>
              )}
              {isIDepotActivated ? (
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  initialValues={{
                    login: accountingFirm.iDepot?.login,
                    password: accountingFirm.iDepot?.password,
                  }}
                  className="user-profile-form iDepot-form"
                >
                  <Form.Item name="login" label="Identifiant">
                    <Input bordered={true} className={"form-input"} />
                  </Form.Item>
                  <Form.Item name="password" label="Mot de passe">
                    <Input.Password
                      bordered={true}
                      className={"form-input"}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>

                  <FormItem className="submit-changes-btn" style={{ marginLeft: "0" }}>
                    <Button className="call-action-btn modal-submit-btn" htmlType="submit">
                      Valider <ArrowRightOutlined />
                    </Button>
                  </FormItem>
                </Form>
              ) : null}
            </>
          ) : (
            <div>
              <p>La connexion à I-Dépôt est disponible pour votre cabinet.</p>
              <p>
                Une fois activée, les pièces reçues de vos clients seront automatiquement chargées
                dans la zone de dépôt correspondante.
              </p>
            </div>
          )}
        </Col>
      )}
    </>
  );
}
export default AdminIDepot;
