import { ExclamationCircleOutlined, FrownOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Progress, Result, Skeleton } from "antd";
import { ReceivedDocHelper } from "helpers/analysis/received/receivedDocHelper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../api/API";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient } from "../../../slices/clientSlice";
import openNotification from "../../../utils/notification";
import "../MissingDocuments.css";
import DocumentListReceived from "./DocumentListReceived";

const getTotalAmount = (data) => {
  let total = 0;
  data.forEach((element) => {
    total += element.operations.reduce((accumulator, obj) => accumulator + Math.abs(obj.amount), 0);
  });
  return total;
};
const howManyDocuments = (data) => {
  let total = 0;
  data.forEach((element) => element.operations.forEach(() => total++));
  return total;
};
const parseNum = (num) =>
  parseFloat(num).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
function numberWithThousandSeparator(x) {
  return x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";
}

function getDownloadDate() {
  const d = new Date();
  const month = d.getMonth() + 1;
  const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  const year = d.getFullYear();
  return day + "" + month + "" + year;
}

function ReceivedDocuments(props) {
  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;

  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);

  const [missingDocData, setMissingDocData] = useState();
  const [clientData, setClientData] = useState([]);
  const [nbrOther, setNbrOther] = useState(0);
  const [error, setError] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloadPart, setDownloadPart] = useState(false);
  const [ghostDocuments, setGhostDocuments] = useState([]);
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (!props.wait)
      (async () => {
        setMissingDocData();
        let res = await API.getMissingDocuments(clientId);
        if (res.status === 200) {
          res = await res.json();
          if (accountingFirm.fec === true) {
            const missingDocDataWithoutGhost = {
              ...res,
              operationCategories: res.operationCategories.map((category) => ({
                ...category,
                operations: category.operations.filter((operation) => operation.ghost !== 1),
              })),
            };
            setMissingDocData(missingDocDataWithoutGhost);
          } else {
            setMissingDocData(res);
          }
        } else {
          setError(true);
          props.setBadgeReceived(0);
        }
      })();
  }, [props.wait, clientRemoteId]);

  useEffect(() => {
    if (missingDocData) {
      var filtered = [];
      missingDocData.operationCategories.forEach((elt, i) => {
        const op = elt.operations.filter(
          (op) => op.fileId || op.files?.length > 0 || op.comment || op.isLost || op.isLostNoAccount
        );
        if (op.length !== 0) {
          op.sort(function (a, b) {
            return a.date < b.date ? 1 : a.date > b.date ? -1 : 0;
          });
          filtered.push({
            operations: op,
            title: elt.title,
            typeId: elt.typeId,
            typeText: elt.typeText,
            sub_type_id: elt.sub_type_id,
            categoryId: elt._id,
            accountNumber:
              missingDocData.operationCategories.find((acc) => acc.title === elt.title)
                ?.accountNumber || "",
          });
        }
      });

      filtered.sort(function (a, b) {
        var titleA = a.accountNumber
          ? a.accountNumber.trim().toUpperCase()
          : a.title.trim().toUpperCase();
        var titleB = b.accountNumber
          ? b.accountNumber.trim().toUpperCase()
          : b.title.trim().toUpperCase();
        // return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
        return titleA.localeCompare(titleB, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
      setClientData(filtered);

      let currentGhostDocs = [];
      filtered.forEach((element) => {
        element.operations.length > 0 &&
          element.operations.forEach((op) => {
            if (op.ghost === 1) {
              op["categoryId"] = element.categoryId;
              currentGhostDocs.push(op);
            }
          });
      });

      setGhostDocuments(currentGhostDocs);

      props.setBadgeReceived(howManyDocuments(filtered) + nbrOther);
      props.setReceived(howManyDocuments(filtered) + nbrOther);
    }
  }, [missingDocData, nbrOther]);

  useEffect(() => {
    (async () => {
      let res = await API.postVerifyDownload({ clientId });
      res = await res.json();
      setDownloadPart(res.downloadPart);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function deleteOperationInUI(categoryId, operationId) {
    const newState = [...clientData];
    for (const category of newState)
      if (category.categoryId === categoryId) {
        category.operations = category.operations.filter((op) => op._id !== operationId);
        break;
      }
    setClientData(newState);
  }

  function downloadAll(allOp) {
    (async () => {
      let res = await API.postDownloadAll({
        clientId,
        allOp,
      });
      if (res.status === 200) {
        res = await res.blob();
        const link = document.createElement("a");
        const zipName = client.client?.name || "client";
        const dateDownload = getDownloadDate();
        if (link.download !== undefined) {
          const url = URL.createObjectURL(res);
          link.setAttribute("href", url);
          link.setAttribute("download", zipName.replace(/\s/g, "") + "_" + dateDownload + ".zip");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        setDownloading(false);
        setDownloadPart(false);
      } else {
        setDownloading(false);
      }
    })();
  }

  const downloadS3 = (backup) => {
    (async () => {
      let res = await API.getExportS3(client._id, backup);
      if (res.status === 404) {
        openNotification("error", "Il n'y a pas de fichiers disponibles pour ce client.");
        setDownloading(false);
      } else if (res.status === 500) {
        openNotification("error", "Une erreur est survenue lors du téléchargement des fichiers.");
        setDownloading(false);
      } else {
        res = await res.blob();
        const url = URL.createObjectURL(res);
        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.setAttribute("download", "Fichiers - " + client.name + ".zip");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloading(false);
      }
    })();
  };

  const handleOpenModal = () => {
    (async () => {
      setModalIsLoading(true);
      const res = await ReceivedDocHelper.downloadAll(
        client,
        howManyDocuments(clientData) + nbrOther > 0 ? true : false,
        ghostDocuments
      );
      if (res) {
        setDownloading(false);
        setDownloadPart(false);
      } else setDownloading(false);

      const operationIds = ghostDocuments.map((op) => op._id);
      await API.deleteManyGhostMissingDoc({
        clientId: clientId,
        clientRemoteId: clientRemoteId,
        operationIds: operationIds,
        accountingFirmId: accountingFirm._id,
      });
      for (let operation of ghostDocuments) {
        deleteOperationInUI({
          categoryId: operation.categoryId,
          operationId: operation._id,
        });
      }

      const currentClientData = [...clientData];
      currentClientData.forEach((element) => {
        element.operations = element.operations.filter((op) => !op.ghost);
      });

      setClientData(currentClientData);
      props.setBadgeReceived(howManyDocuments(currentClientData) + nbrOther);
      props.setReceived(howManyDocuments(currentClientData) + nbrOther);
      setGhostDocuments([]);
      setModalIsLoading(false);
      setModalIsOpen(false);
      props.setRefreshMissingDoc(!props.refreshMissingDoc);
    })();
  };

  return (
    <>
      {error ? (
        <Result
          icon={<FrownOutlined style={{ color: "#4569f8" }} />}
          title="Nous ne parvenons pas à recupérer les pièces reçues"
        />
      ) : !missingDocData ? (
        <Skeleton
          className="skeleton-analysis"
          active={true}
          paragraph={{
            rows: 4,
          }}
        />
      ) : (
        <div className="received-doc-container">
          <div>
            {howManyDocuments(clientData) + nbrOther > 0 ? (
              <Button
                className="call-action-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setDownloading(true);
                  console.log("download all");
                  logAction(237, 1, client._id);
                  downloadAll(true);
                }}
                loading={downloading}
              >
                Télécharger tous les justificatifs
              </Button>
            ) : null}
            {downloadPart ? (
              <Button
                className="other-action-btn"
                onClick={(e) => {
                  e.preventDefault();
                  console.log("download part");
                  //  setDownloading(true); ??
                  logAction(238, 1, client._id);
                  downloadAll(false);
                }}
                loading={downloading}
              >
                Télécharger uniquement les nouveaux justificatifs
              </Button>
            ) : null}
            {ghostDocuments.length > 0 ? (
              <>
                <Button
                  className="call-action-btn download-delete-alerts"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalIsOpen(true);
                    // downloadAndDeleteAllAlerts();
                  }}
                  loading={downloading}
                >
                  Télécharger et supprimer les opérations en alerte
                </Button>
                <Modal
                  title={
                    <>
                      <ExclamationCircleOutlined className="modal-lost-alert-icon" />
                      <h4>Êtes-vous sûr(e) de vouloir supprimer ces justificatifs?</h4>
                    </>
                  }
                  className="modal-lost modal-receivedDoc-alert"
                  open={modalIsOpen}
                  onOk={handleOpenModal}
                  okText="Valider"
                  cancelText="Annuler"
                  confirmLoading={modalIsLoading}
                  closeIcon={false}
                  maskClosable={false}
                  onCancel={() => setModalIsOpen(false)}
                >
                  <Alert
                    className="modal-lost-alert"
                    description={
                      <p>
                        En cliquant sur “valider”, les justificatifs seront téléchargés et les
                        opérations en alerte disparaitront des pièces reçues ainsi que des pièces
                        manquantes.
                        <br />
                        <br />
                        Pour rappel, ce sont des opérations dont nous ne trouvons plus l’identifiant
                        mais pour lesquelles un retour client a été fait.
                      </p>
                    }
                    type="warning"
                  />
                </Modal>
              </>
            ) : null}
          </div>

          <div className="circle">
            <Progress
              type="circle"
              percent={100}
              size={80}
              strokeColor="#007543"
              format={() => (
                <span className="circular-text">{howManyDocuments(clientData) + nbrOther}</span>
              )}
            />
            <span className="total-amount">
              {numberWithThousandSeparator(parseNum(getTotalAmount(clientData)))}
            </span>
            <span className="total-amount-text">
              {clientData.length === 0 ? "justifié" : "justifiés"}
            </span>
          </div>
          <DocumentListReceived
            {...props}
            missingDocData={missingDocData}
            data={clientData}
            nbrReceivedDocuments={howManyDocuments(clientData) + nbrOther}
            onDeleteOperation={(data) => deleteOperationInUI(data.categoryId, data.operationId)}
            other={(data) => setNbrOther(data)}
            setGhostDocuments={setGhostDocuments}
          />

          <p>
            Je ne retrouve plus mes pièces.{" "}
            <Button
              type="link"
              onClick={(e) => {
                e.preventDefault();
                logAction(240, 1, client._id);
                setDownloading(true);
                downloadS3("2");
              }}
              style={{ paddingLeft: "0px" }}
              loading={downloading}
            >
              Télécharger le backup depuis 2 mois.
            </Button>
          </p>
        </div>
      )}
    </>
  );
}

export default ReceivedDocuments;
