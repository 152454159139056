import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";

const howManyMissingDocuments = (data) => {
  let total = 0;
  data.forEach((element) => element.operations.forEach(() => total++));
  return total;
};
const getTotalAmount = (data) => {
  let total = 0;
  data.forEach((element) => {
    total += element.operations.reduce((accumulator, obj) => accumulator + Math.abs(obj.amount), 0);
  });
  return total;
};

const parseNum = (num) =>
  parseFloat(num).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

function numberWithThousandSeparator(x) {
  return x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";
}

function TotalAmount(props) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let local =
      howManyMissingDocuments(props.missingDocument) + howManyMissingDocuments(props.other);
    setCount(local);
    props.setBadgeCount(local);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.missingDocument, props.other]);

  return (
    <div className="circle">
      <Progress
        type="circle"
        percent={100}
        size={80}
        strokeColor="#FF9700"
        format={() => <span className="circular-text">{count}</span>}
      />
      <span className="total-amount">
        {numberWithThousandSeparator(parseNum(getTotalAmount(props.missingDocument)))}
      </span>
      <span className="total-amount-text">
        non {props.missingDocument.length === 0 ? "justifié" : "justifiés"}
        {props.lastMailSent !== null ? " au " + dayjs(props.lastMailSent).format("DD/MM/YYYY") : ""}
      </span>
    </div>
  );
}

export default TotalAmount;
