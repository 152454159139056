import React, { useState } from "react";
import * as API from "../../../api/API";
import { useSelector, useDispatch } from "react-redux";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import { Button, Input } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import openNotification from "../../../utils/notification";

function Other(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);

  const [editing, setEditing] = useState(false);
  const [currentText, setCurrentText] = useState(props.omd.text);

  const updateOMD = (idOMD, value) => {
    (async () => {
      const res = await API.putOperation(client._id, idOMD, {
        text: value,
        date: new Date(),
      });
      if (res.status === 201) {
        const newList = [
          ...props.otherMissingDocument.map((op) =>
            op._id === idOMD
              ? {
                  _id: idOMD,
                  text: value,
                  date: new Date(),
                }
              : op
          ),
        ];
        props.setOtherMissingDocument(newList);
        dispatch(updateClient({ otherMissingDocuments: newList }));
      } else openNotification("error", "Impossible de mettre à jour l'opération");
    })();
  };

  const deleteOMD = (idOMD, idFile) => {
    (async () => {
      const res = await API.deleteOperation(client._id, idOMD);
      if (res.status === 200) {
        const newList = [...props.otherMissingDocument.filter((op) => op._id !== idOMD)];
        props.setOtherMissingDocument(newList);
        dispatch(updateClient({ otherMissingDocuments: newList }));
      } else openNotification("error", "Impossible de supprimer l'opération");
    })();

    if (idFile) {
      (async () => {
        const res = await API.deleteMissingDocumentFile(idFile);
        if (res.status === 201) {
          console.log("File deleted: " + idFile);
        } else console.log("Error deleteMissingDocumentFile");
      })();
    }
  };

  return (
    <li>
      {!editing ? (
        <p className="other-span">{props.omd.text}</p>
      ) : (
        <p className="other-edit-span">
          <Input
            className="other-edit"
            type="text"
            defaultValue={props.omd.text}
            onChange={(e) => {
              setCurrentText(e.target.value);
            }}
            onPressEnter={(e) => {
              updateOMD(props.omd._id, e.target.value);
              setEditing(false);
            }}
          />
        </p>
      )}
      <Button
        className="other-action-btn"
        shape="circle"
        icon={<EditOutlined />}
        onClick={(e) => {
          if (editing) {
            updateOMD(props.omd._id, currentText);
          }
          setEditing(!editing);
        }}
      />
      <Button
        className="other-action-btn other-delete-btn"
        shape="circle"
        icon={<DeleteOutlined />}
        onClick={() => deleteOMD(props.omd._id)}
      />
    </li>
  );
}

export default Other;
