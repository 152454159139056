import "dayjs/locale/fr";
import * as API from "../../../api/API";

function _getDownloadDate() {
  const d = new Date();
  const month = d.getMonth() + 1;
  const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  const year = d.getFullYear();
  return day + "" + month + "" + year;
}

const _createAndDownloadAllBlobFiles = async (client, res) => {
  res = await res.blob();
  const link = document.createElement("a");
  const zipName = client.client?.name || "client";
  const dateDownload = _getDownloadDate();
  if (link.download !== undefined) {
    const url = URL.createObjectURL(res);
    link.setAttribute("href", url);
    link.setAttribute("download", zipName.replace(/\s/g, "") + "_" + dateDownload + ".zip");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const _createAndDownloadBlobFile = (body, filename) => {
  const blob = new Blob([body]);
  const fileName = `${filename}`;
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export class ReceivedDocHelper {
  static downloadAll = async (client, allOp, specifiedOps) => {
    let res = await API.postDownloadAll({
      clientId: client._id,
      allOp,
      specifiedOps,
    });
    if (res.status === 200) {
      await _createAndDownloadAllBlobFiles(client, res);
      return true;
    } else {
      return false;
    }
  };

  static downloadFiles = async (client, files, idOp, idOpCategory) => {
    for (let file of files) {
      const res = await API.postDownload({
        fileid: file._id,
        clientId: client._id,
        idOp,
        idOpCategory,
      });
      if (res.status === 200) {
        const data = await res.blob();
        // var ext = "";
        // if (
        //   data.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        // ) {
        //   ext = "docx";
        // } else if (data.type === "application/msword") {
        //   ext = "doc";
        // } else if (data.type === "text/plain") {
        //   ext = "txt";
        // } else if (data.type === "application/vnd.ms-excel") {
        //   ext = "xls";
        // } else if (
        //   data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        // ) {
        //   ext = "xlsx";
        // } else {
        //   ext = data.type.split("/")[1];
        // }
        _createAndDownloadBlobFile(data, file.fileName);
      } else console.log("error");
    }
  };

  static previewFile = async (client, files, idOp, idOpCategory, preview) => {
    const returnedData = [];
    for (let file of files) {
      const fileExtension = file.fileName.split(".").pop();
      const allowedExtensions = ["jpg", "png", "jpeg", "pdf"];
      if (allowedExtensions.includes(fileExtension)) {
        const res = await API.postDownload({
          fileid: file._id,
          clientId: client._id,
          idOp,
          idOpCategory,
          preview,
        });
        if (res.status === 200) {
          const data = await res.blob();
          const url = URL.createObjectURL(data);
          returnedData.push(url);
        }
      }
    }
    return returnedData;
  };
}
