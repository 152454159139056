import {
  ArrowRightOutlined,
  CalendarOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import hasFeature from "utils/dataProviderFeatures";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectClient } from "../../slices/clientSlice";
import logAction from "../../utils/logActions";
import openNotification from "../../utils/notification";
import "../import/Import.css";

const { confirm } = Modal;
const { Option } = Select;

const { Title, Text } = Typography;

function ClientInformation(props) {
  const history = useHistory();
  const accountingFirm = useSelector(selectAccountingFirm);
  const client = useSelector(selectClient);

  const [form] = Form.useForm();

  const [clientFiscalStartMonth, setClientFiscalStartMonth] = useState("");
  const [, setBusy] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [secondMailToBox, setSecondMailToBox] = useState(true);
  const [collaborators, setCollaborators] = useState([]);
  const [enableApe, setEnableApe] = useState(true);

  useEffect(() => {
    if (hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "BLOCK_CLIENT_EDIT")) {
      setEnableApe(accountingFirm.dataProvider === "FULLL");
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.dataProvider]);

  useEffect(() => {
    (async () => {
      let res = await API.getUsersByAccountingFirmId(accountingFirm._id);
      if (res.status === 200) {
        res = await res.json();
        setCollaborators(res);
      }
    })();
  }, [accountingFirm._id]);

  async function postForm(formData) {
    let res;
    const formPastYearComp = formData.get("pastYearComp") === "true";
    const formMailToBox = formData.get("mailToBox");
    if (client.mailToBox !== formMailToBox && formMailToBox !== "") logAction(266, 1, client._id);
    if (client.pastYearComp !== formPastYearComp && formPastYearComp === true)
      logAction(254, 1, client._id);
    if (client.pastYearComp !== formPastYearComp && formPastYearComp === false)
      logAction(255, 1, client._id);
    res = await API.postUpdateClient(formData);

    setLoading(false);
    if (res.status === 201) {
      res = await res.json();

      logAction(70, 1, res._id);

      window.location.reload();
    } else if (res.status === 409) {
      openNotification(
        "error",
        "Erreur lors de l'enregistrement, un client existe déjà avec ce nom."
      );
    } else {
      openNotification(
        "error",
        "Impossible de générer l'analyse. Êtes-vous sûr que les fichiers fournis sont bien des fichiers FEC ?"
      );
    }
  }

  const onFinish = async (values) => {
    setLoading(true);
    let formData = new FormData();
    formData.set("token", localStorage.getItem("token"));
    formData.set("update", false); // true or false
    formData.set("clientId", props.clientId);
    formData.set("remoteId", client.remoteId);
    formData.set("accountingFirmId", accountingFirm._id);
    formData.set("clientName", values.clientName);
    formData.set("siren", values.clientSIREN || ""); // peut être undefined lorsque le champ n'apparait pas dans le formulaire
    if (!accountingFirm.connectedRights) {
      formData.set(
        "collaboratorsId",
        JSON.stringify(values.collaboratorsInCharge) || JSON.stringify([])
      );
    }
    formData.set("apeCode", values.apeCode || "");
    formData.set("address", values.address || "");
    formData.set("city", values.city || "");
    formData.set("postalCode", values.postalCode || "");
    formData.set("mailToBox", values.mailToBox || "");
    formData.set("mailToBoxSales", values.mailToBoxSales || "");
    formData.set("mailToBoxOther", values.mailToBoxOther || "");
    formData.set("pastYearComp", values.pastYearComp || false);

    if (accountingFirm.dataProvider !== "SAGE") {
      const formatDate = dayjs("2020-" + values.fiscalStartMonth).format("YYYY-MM");
      formData.set("fiscal_start_date", formatDate);
    }

    values.fiscalStartMonth !== undefined
      ? formData.set("fiscalStartMonth", values.fiscalStartMonth.toString())
      : formData.set("fiscalStartMonth", "");

    postForm(formData);
  };

  function showDelete() {
    confirm({
      title: "Êtes-vous sûr(e) de vouloir supprimer le dossier client ?",
      icon: <ExclamationCircleOutlined />,
      className: "modal-delete-fec",
      okText: "Oui supprimer",
      cancelText: "Non annuler",
      maskClosable: true,
      closable: true,
      onOk() {
        (async () => {
          const res = await API.postToDeleteRemoteClient({
            firm_id: accountingFirm._id,
            company_id: client.remoteId,
          });
          if (res.status === 200) {
            await API.deleteClient(props.match.params.clientId);
            logAction(70, -1, props.match.params.clientId);
            history.push("/homepage");
          }
        })();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  }

  useEffect(() => {
    setSecondMailToBox(!client.mailToBoxSales && !client.mailToBoxOther);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showMailToBoxSales = (e) => {
    setSecondMailToBox(e.target.checked);
  };

  useEffect(() => {
    if (!client.fiscalStartMonth) {
      if (props.input !== undefined && props.input.client) {
        setClientFiscalStartMonth(
          dayjs(props.input.client.date_debut_exercice).format("M").toString()
        );
        setBusy(false);
      } else {
        setBusy(false);
      }
    } else {
      setClientFiscalStartMonth(
        dayjs("2020-" + client.fiscalStartMonth)
          .format("M")
          .toString()
      );
      setBusy(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client._id]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFiscalStartMonth]);

  return (
    <>
      <Form
        form={form}
        name="clientForm"
        layout="vertical"
        onFinish={(values) => {
          onFinish(values);
        }}
        initialValues={{
          clientName: client.name ? client.name : "",
          clientSIREN: client.siren ? client.siren : "",
          collaboratorsInCharge: accountingFirm.connectedRights
            ? client.collaboratorsIdConnected
            : client.collaboratorsId,
          apeCode: client.apeCode ? client.apeCode : "",
          address: client.address ? client.address : "",
          city: client.city ? client.city : "",
          postalCode: client.postalCode ? client.postalCode : "",
          fiscalStartMonth: clientFiscalStartMonth.toString(),
          mailToBox: client.mailToBox ? client.mailToBox : "",
          mailToBoxSales: client.mailToBoxSales ? client.mailToBoxSales : "",
          mailToBoxOther: client.mailToBoxOther ? client.mailToBoxOther : "",
          pastYearComp: client.pastYearComp ? client.pastYearComp : false,
        }}
      >
        <Row className="top-box" style={{ paddingTop: "0" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row className="import-submit-row">
              <Button
                type="button"
                className="other-action-btn return-button-fec"
                onClick={() => {
                  props.activeKeyImport("1");
                  props.importActive(false);
                }}
                style={{ top: "-70px", left: "33.5px" }}
              >
                Annuler
              </Button>
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="call-action-btn link-button-fec"
                  loading={loading}
                  style={{ top: "-70px", left: "33.5px" }}
                >
                  Valider <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>

        <Row className="mid-box">
          <Col xs={{ span: 20, offset: 2 }} lg={{ span: 9, offset: 2 }}>
            <Title level={4}>Informations client</Title>
            <Text className="import-input-text">Saisissez le nom du client</Text>
            <Form.Item
              name="clientName"
              rules={[
                {
                  required: true,
                  message: "Vous devez remplir le nom du client",
                },
              ]}
            >
              <Input className="import-input" placeholder="Nom du client" disabled={disabled} />
            </Form.Item>
            <Text className="import-input-text">Saisissez le numéro SIREN (facultatif)</Text>
            <Form.Item name="clientSIREN">
              <Input className="import-input" placeholder="Numéro SIREN" />
            </Form.Item>
            <Text className="import-input-text">
              {accountingFirm.connectedRights
                ? "Liste des utilisateurs ayant accès au dossier"
                : "Saisissez les noms des collaborateurs en charge du dossier (facultatif)"}
            </Text>
            <Form.Item name="collaboratorsInCharge">
              <Select
                className="import-input collabs-select"
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                popupClassName="ant-select-dropdown-zindex"
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
                disabled={accountingFirm.connectedRights}
              >
                {collaborators.map((item) => (
                  <Option key={item._id}>{item.firstName + " " + item.lastName}</Option>
                ))}
              </Select>
            </Form.Item>
            <Text className="import-input-text">Saisissez le code APE (facultatif)</Text>
            <Form.Item name="apeCode">
              <Input className="import-input" placeholder="Code APE" disabled={!enableApe} />
            </Form.Item>
            <Text className="import-input-text">Saisissez l'adresse (facultatif)</Text>
            <Form.Item name="address">
              <Input className="import-input" placeholder="Adresse" disabled={disabled} />
            </Form.Item>
            <Text className="import-input-text">Saisissez la ville (facultatif)</Text>
            <Form.Item name="city">
              <Input className="import-input" placeholder="Ville" disabled={disabled} />
            </Form.Item>
            <Text className="import-input-text">Saisissez le code postal (facultatif)</Text>
            <Form.Item name="postalCode">
              <Input className="import-input" placeholder="Code postal" disabled={disabled} />
            </Form.Item>

            <>
              <Text className="import-input-text">
                Saisissez le mois de début d'exercice (facultatif)
              </Text>
              <Form.Item name="fiscalStartMonth" className="month-select-form-item">
                <Select
                  className="import-input month-select"
                  placeholder="Date"
                  allowClear={false}
                  placement="bottomLeft"
                  dropdownAlign={{ overflow: { adjustY: false } }}
                >
                  <Option value="1">Janvier</Option>
                  <Option value="2">Février</Option>
                  <Option value="3">Mars</Option>
                  <Option value="4">Avril</Option>
                  <Option value="5">Mai</Option>
                  <Option value="6">Juin</Option>
                  <Option value="7">Juillet</Option>
                  <Option value="8">Août</Option>
                  <Option value="9">Septembre</Option>
                  <Option value="10">Octobre</Option>
                  <Option value="11">Novembre</Option>
                  <Option value="12">Décembre</Option>
                </Select>
              </Form.Item>
              <CalendarOutlined className="calendar-icon" />
            </>
            {client.forecast?.settings?.active?.global ? null : (
              <Form.Item name="pastYearComp" valuePropName="checked">
                <Checkbox>Analyse sans comparatif n-1</Checkbox>
              </Form.Item>
            )}
          </Col>
          <Col xs={24} sm={0} md={0} lg={0} xl={0}>
            <br />
          </Col>
          <Col xs={{ span: 20, offset: 2 }} lg={{ span: 9, offset: 2 }} className="black">
            {accountingFirm.mailToBox ? (
              <>
                <Title level={4}>Mail to Box</Title>
                <Text className="import-input-text">
                  {!secondMailToBox
                    ? " Saisissez l’adresse pour les achats"
                    : "Saisissez l’adresse Mail to Box"}
                </Text>
                <Form.Item
                  name="mailToBox"
                  rules={[
                    {
                      required: !secondMailToBox,
                      message: "Veuillez renseigner une adresse pour les achats",
                    },
                  ]}
                >
                  <Input className="import-input" placeholder="Mail to Box" />
                </Form.Item>
                <Checkbox
                  onClick={(e) => e.stopPropagation()}
                  checked={secondMailToBox}
                  onChange={(e) => showMailToBoxSales(e)}
                >
                  Adresse unique
                </Checkbox>
                {!secondMailToBox ? (
                  <>
                    <Text className="import-input-text mail-sales">
                      Saisissez l’adresse pour les ventes (si non renseigné : renvoi vers "Achats")
                    </Text>
                    <Form.Item name="mailToBoxSales">
                      <Input className="import-input" placeholder="Mail to Box ventes" />
                    </Form.Item>
                    <Text className="import-input-text mail-sales">
                      Saisissez l’adresse pour les autres pièces (si non renseigné : renvoi vers
                      "Achats")
                    </Text>
                    <Form.Item name="mailToBoxOther">
                      <Input className="import-input" placeholder="Mail to Box autres" />
                    </Form.Item>
                  </>
                ) : null}
              </>
            ) : null}
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 21 }}>
            <Button
              htmlType="button"
              className="other-action-btn delete-fec-btn"
              onClick={(e) => {
                e.preventDefault();
                showDelete();
              }}
            >
              <DeleteOutlined /> Supprimer le client
            </Button>
          </Col>
        </Row>
        <div style={{ clear: "both" }}></div>
        <Row className="bottom-box" style={{ margin: " 1% -3.65% -3.65% -7.25%" }}></Row>
      </Form>
    </>
  );
}

export default ClientInformation;
