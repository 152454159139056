import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import * as API from "../../../api/API";
import openNotification from "../../../utils/notification";

const Comment = (props) => {
  const tokenClient = props.match.params.token;
  const idClient = jwt_decode(tokenClient).clientId;

  const [editing, setEditing] = useState(false);
  const [comment, setComment] = useState(props.record.commentClient);

  useEffect(() => {
    setComment(props.record.commentClient);
  }, [props.record]);

  const handleOnPressEnter = async (comment) => {
    const res = await API.putOutstandings(
      idClient,
      props.type,
      props.record.categoryId,
      props.record.id,
      {
        commentClient: comment,
      }
    );
    if (res.status === 200) {
      setComment(comment);

      const updatedData = props.accountData.map((category) => ({
        ...category,
        operations: category.operations.map((op) => ({
          ...op,
          commentClient: op.id === props.record.id ? comment : op.commentClient,
        })),
      }));
      props.setAccountData(updatedData);

      API.postClientTracker(idClient, {
        action: "OUTSTANDING_COMMENT_" + props.type.toUpperCase(),
        category: props.data.find((el) => el._id === props.record.categoryId)?.accountNumber,
        operationId: props.record.id,
        dateOp: props.record.date,
        label: props.record.label,
        reference: props.record.pieceRef,
        amount: props.record.amount,
        comment: comment,
        type: props.type === "clients" ? "ENCOURS CLIENTS" : "ENCOURS FOURNISSEURS",
        dueDays: props.record.dueDays,
      });
      API.notify({
        clientId: idClient,
        action: "outstandings" + props.type[0].toUpperCase() + props.type.slice(1),
      });
    } else openNotification("error", "Erreur lors de l'envoi du commentaire");
  };
  const handleDelete = async () => {
    const res = await API.putOutstandings(
      idClient,
      props.type,
      props.record.categoryId,
      props.record.id,
      {
        commentClient: "",
      }
    );
    if (res.status === 200) {
      setComment("");
      const updatedData = props.accountData.map((category) => ({
        ...category,
        operations: category.operations.map((op) =>
          op.id === props.record.id ? { ...op, commentClient: "" } : op
        ),
      }));
      props.setAccountData(updatedData);
    } else openNotification("error", "Erreur lors de la suppression du commantaire");
  };

  return (
    <>
      {comment ? (
        <>
          {!editing ? (
            <>
              <p className="other-span">{comment}</p>
            </>
          ) : (
            <p className="other-edit-span">
              <Input
                className="other-edit"
                type="text"
                defaultValue={comment}
                onPressEnter={(e) => {
                  handleOnPressEnter(e.target.value);
                  setEditing(false);
                }}
              />
            </p>
          )}
          <Button
            className="other-action-btn"
            shape="circle"
            icon={<EditOutlined />}
            onClick={(e) => setEditing(!editing)}
          />
          <Button
            className="other-action-btn other-delete-btn"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(e) => handleDelete()}
          />
        </>
      ) : (
        <>
          <Input
            className="other-input"
            type="text"
            onPressEnter={(e) => handleOnPressEnter(e.target.value)}
            placeholder="Ajouter un commentaire"
          />
          <span className="help-txt">Appuyez sur « Entrée » pour valider</span>
        </>
      )}
    </>
  );
};

export default Comment;
