import { Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logAction from "utils/logActions";
import { v4 as uuidv4 } from "uuid";
import * as API from "../../../api/API";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import Other from "./Other";

function Others(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);

  const [otherMissingDocument, setOtherMissingDocument] = useState([]);
  const [otherText, setOtherText] = useState("");

  const addOMD = (value) => {
    setOtherText("");
    const uuid = uuidv4();
    (async () => {
      const res = await API.putOperation(client._id, uuid, {
        other: true,
        text: value,
        date: new Date(),
      });
      if (res.status === 201) {
        const newList = [
          ...client.otherMissingDocuments,
          {
            _id: uuid,
            other: true,
            text: value,
            date: new Date(),
          },
        ];
        setOtherMissingDocument(newList);
        props.others(newList);
        dispatch(updateClient({ otherMissingDocuments: newList }));
      } else console.log("error");
    })();
  };

  useEffect(() => {
    setOtherMissingDocument(client.otherMissingDocuments);
  }, [client.otherMissingDocuments]);

  return (
    <>
      <div className="type-title">Autres</div>
      <div className="type-separation"></div>
      <ul className="other-list">
        {otherMissingDocument?.map((omd) => (
          <Other
            {...props}
            key={omd._id}
            omd={omd}
            otherMissingDocument={otherMissingDocument}
            setOtherMissingDocument={(data) => {
              setOtherMissingDocument(data);
              props.others(data);
            }}
          />
        ))}
        <li>
          <Input
            className="other-input"
            type="text"
            value={otherText}
            onChange={(e) => setOtherText(e.target.value)}
            onPressEnter={(e) => {
              logAction(225, 1, client._id);
              addOMD(e.target.value);
            }}
            placeholder="Ajouter une demande"
          />
          <span className="help-txt">Appuyez sur « Entrée » pour valider</span>
        </li>
      </ul>
    </>
  );
}

export default Others;
