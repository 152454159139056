import {
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { Button, Modal, Popover } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as API from "../../../api/API";
import OrderImg from "../../../assets/images/order-table.png";
import { updateClient } from "../../../slices/clientSlice";
import IndicatorItem from "./order/IndicatorItem.js";
import IndicatorsContainer from "./order/IndicatorsContainer.js";

const indicName = {
  ca: "Chiffre d'affaires",
  marge: "Marge",
  masse_sal: "Masse salariale",
  treso: "Trésorerie",
};

const TableOrder = (props) => {
  const dispatch = useDispatch();
  const clientId = props.match.params.clientId;

  const [orderEdit, setOrderEdit] = useState(false);
  const [activeId, setActiveId] = useState();
  const [items, setItems] = useState({
    container1: [],
    container2: [],
  });
  const [initialiItems, setInitialItems] = useState({
    container1: [],
    container2: [],
  });

  useEffect(() => {
    let container1 = [];
    let container2 = [];
    const allIndics = props.analysisTemplate.templateAnalysis.filter(
      (elt) => elt.id !== "treso_dispo"
    );
    props.indicsTableOrder.forEach((elt, i) => {
      if (allIndics.find((obj) => obj.id === elt)) {
        const indic = allIndics.find((obj) => obj.id === elt);
        container1.push({ id: indic.id, name: indic.name || indicName[indic.id] });
      }
    });

    container2 = allIndics
      .filter((elt) => !container1.find((indic) => indic.id === elt.id))
      .map((elt, i) => {
        return { id: elt.id, name: elt.name || indicName[elt.id] };
      });

    setItems({
      container1: container1,
      container2: container2,
    });
    setInitialItems({
      container1: container1,
      container2: container2,
    });
  }, []);

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  function handleDragOver(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable.containerId || over.id;

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((item) => item.id === id);
      const overIndex = overItems.findIndex((item) => item.id === overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.top > over.rect.top + over.rect.height;

        const modifier = isBelowOverItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id: overId } = over;

    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable.containerId || over.id;

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }
    if (overContainer) {
      const activeIndex = items[activeContainer].findIndex((item) => item.id === active.id);
      const overIndex = items[overContainer].findIndex((item) => item.id === overId);

      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
        }));
      }

      if (items.container1.length > 4) {
        const itemsarray = arrayMove(items[overContainer], activeIndex, overIndex);
        const popped = itemsarray.pop();

        setItems((items) => ({
          ...items,
          container1: itemsarray,
          container2: [...items.container2, popped],
        }));
      }
    }
    setActiveId(null);
  }

  const sensors = useSensors(useSensor(PointerSensor), useSensor(MouseSensor));

  return (
    <div className="table-order" id="table-order">
      <Popover
        placement="right"
        content="Réorganiser les indicateurs dans le tableau"
        overlayClassName="info-popover-overlay-order"
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            setOrderEdit(true);
          }}
          style={{ width: 42, height: 42, zIndex: 3 }}
          shape="circle"
          icon={<img src={OrderImg} alt="Order Icon" width="24px" style={{ marginTop: 4 }} />}
        />
      </Popover>
      <Modal
        title="Modifier le tableau de bord"
        className="edit-table-order-modal"
        open={orderEdit}
        onCancel={(e) => {
          e.preventDefault();
          setOrderEdit(false);
        }}
        footer={null}
        forceRender
        width="685px"
        style={{ top: 20 }}
      >
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          collisionDetection={closestCenter}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
          }}
        >
          <div
            style={{
              display: "grid",
              gridAutoFlow: "row",
              padding: "0px 20px 5px 20px",
            }}
          >
            <IndicatorsContainer
              id="container1"
              items={items.container1}
              label="Indicateurs mis en avant"
              total={items.container1.length + items.container2.length}
            />
            <IndicatorsContainer
              id="container2"
              items={items.container2}
              label="Indicateurs disponibles"
              total={items.container1.length + items.container2.length}
            />
          </div>

          <DragOverlay>
            {activeId ? (
              <IndicatorItem
                id={activeId}
                name={
                  items.container1.find((elt) => elt.id === activeId)?.name ||
                  items.container2.find((elt) => elt.id === activeId)?.name
                }
              />
            ) : null}
          </DragOverlay>
        </DndContext>

        <p style={{ fontStyle: "italic", color: "#6E6E73", textAlign: "center" }}>
          Changez la disposition du tableau de bord en faisant glisser les blocs
        </p>

        <div className="prompt-btns">
          <Button
            className="other-action-btn invalid-cancel"
            onClick={(e) => {
              e.preventDefault();
              setOrderEdit(false);
              setItems({
                container1: initialiItems.container1,
                container2: initialiItems.container2,
              });
            }}
          >
            Annuler
          </Button>
          <Button
            className="call-action-btn"
            onClick={(e) => {
              e.preventDefault();
              setOrderEdit(false);
              setInitialItems({
                container1: items.container1,
                container2: items.container2,
              });
              (async () => {
                const res = await API.putClient(clientId, {
                  tableOrder: items.container1.map((elt) => elt.id),
                });

                if (res.status === 201) {
                  props.setIndicsTableOrder(items.container1.map((elt) => elt.id));
                  dispatch(
                    updateClient({
                      tableOrder: items.container1.map((elt) => elt.id),
                    })
                  );
                }
              })();
            }}
          >
            Valider
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TableOrder;
