import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import { DownCircleOutlined } from "@ant-design/icons";
import { ReactComponent as ImportantIcon } from "../../../assets/images/important.svg";
import { ReactComponent as GreenCircleIcon } from "../../../assets/images/green-circle.svg";
import OperationLine from "./OperationLine.js";
import isOperationEmpty from "../../../utils/isOperationEmpty";

const { Panel } = Collapse;

const getTotalAmount = (data) => {
  let total = 0;
  data.forEach((element) => {
    total += element.operations.reduce((accumulator, obj) => accumulator + Math.abs(obj.amount), 0);
  });
  return total;
};

const getSubTotal = (operations) =>
  Math.abs(operations.reduce((accumulator, obj) => accumulator + obj.amount, 0));

const parseNum = (num) =>
  parseFloat(num).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

function numberWithThousandSeparator(x) {
  return x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";
}

function TypeOperationsList(props) {
  const [, setFinishedOp] = useState([]);

  const nbrReceivedDocuments = props.nbrReceivedDocuments;
  const limitNumberToOpenCollapsesByDefault = 30; // si moins de 30 pièces sont manquantes, alors les différentes catégories sont ouvertes par défaut

  const getCollapseHeader = (text, operations) => {
    const list = [];
    operations.forEach((op) => {
      if (!isOperationEmpty(op)) list.push(op);
    });

    return (
      <div>
        <div className="client-operation-title">
          {list.length === operations.length ? (
            <GreenCircleIcon className="client-operation-title-circle" />
          ) : (
            <ImportantIcon className="client-operation-title-circle" />
          )}
        </div>
        <span className="client-operation-title-text">
          {text} - {numberWithThousandSeparator(parseNum(getSubTotal(operations)))}
        </span>
      </div>
    );
  };

  useEffect(() => {
    const list = [];
    props.typelist.forEach((type) => {
      type.operations.forEach((op) => {
        if (!isOperationEmpty(op)) list.push(op);
      });
    });
    setFinishedOp(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updated]);

  return (
    <>
      {getTotalAmount(props.typelist) !== 0 || props.title === "Autres" ? (
        <>
          <div className="type-title">
            {props.title}
            {props.title !== "Autres"
              ? " - " + numberWithThousandSeparator(parseNum(getTotalAmount(props.typelist)))
              : null}
          </div>
          <div className="type-separation"></div>

          {props.typelist.map((type, i) => {
            if (props.title === "Autres") {
              return type.operations.map((op) => {
                return (
                  <div key={op.text} className="other-operation-line">
                    <OperationLine {...props} operation={op} type={type} />
                  </div>
                );
              });
            } else {
              if (getSubTotal(type.operations) > 0)
                return (
                  <div key={i}>
                    <Collapse
                      ghost
                      defaultActiveKey={
                        nbrReceivedDocuments <= limitNumberToOpenCollapsesByDefault
                          ? [...Array(300).keys()]
                          : []
                      }
                      expandIconPosition="end"
                      expandIcon={({ isActive }) => (
                        <DownCircleOutlined
                          style={{ fontSize: "26px" }}
                          rotate={isActive ? 180 : 0}
                        />
                      )}
                    >
                      <Panel header={getCollapseHeader(type.title, type.operations)} key={i}>
                        {type.operations.map((op, j) => {
                          return (
                            <div className="operation-line" key={op._id}>
                              <OperationLine {...props} operation={op} type={type} />
                            </div>
                          );
                        })}
                      </Panel>
                    </Collapse>
                  </div>
                );
              else {
                return null;
              }
            }
          })}
        </>
      ) : null}
    </>
  );
}

export default TypeOperationsList;
