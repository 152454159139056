import React, { useState, useEffect } from "react";
import { Button, Table } from "antd";
import { LinkOutlined, FireTwoTone } from "@ant-design/icons";
import * as API from "../../../api/API";

import dayjs from "dayjs";

function SentryTable(props) {
  const [sentryIssues, setSentryIssues] = useState();

  useEffect(() => {
    (async () => {
      setSentryIssues();
      if (props.currentClient && props.currentAccFirm) {
        let res = await API.getSentryIssues(props.currentAccFirm, props.currentClient);
        res = await res.json();
        setSentryIssues(res);
      } else if (props.userId) {
        let res = await API.getSentryIssuesByUser(props.userId);
        res = await res.json();
        setSentryIssues(res);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentClient, props.clients, props.userId]);

  const columns = [
    {
      title: "Serveur",
      dataIndex: "project",
      key: "project",
      align: "center",
      width: "220px",
      render: (project) => <>{project.slug}</>,
    },
    {
      title: "Erreur",
      dataIndex: "title",
      key: "title",
      render: (title, row) => (
        <>
          {title}{" "}
          {row.isUnhandled ? (
            <b style={{ color: "#d33434" }}>
              <FireTwoTone twoToneColor="#d33434" /> Unhandled
            </b>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Dernière apparition",
      dataIndex: "lastSeen",
      key: "lastSeen",
      align: "center",
      width: "150px",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.lastSeen.localeCompare(b.lastSeen),
      render: (lastSeen) => dayjs(lastSeen).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Lien Sentry",
      dataIndex: "permalink",
      key: "permalink",
      align: "center",
      width: "100px",
      render: (permalink) => (
        <Button type="text" href={permalink} target="_blank">
          <LinkOutlined style={{ color: "blue" }} />
        </Button>
      ),
    },
  ];

  return (
    <div style={{ marginTop: "30px" }}>
      <Table
        columns={columns}
        dataSource={sentryIssues}
        size="small"
        bordered
        pagination={false}
        loading={!sentryIssues}
      />
    </div>
  );
}

export default SentryTable;
