import { CheckOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Popover, Table } from "antd";
import * as dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectClient } from "../../slices/clientSlice";
import RenderCell from "./RenderCell";

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const monthsShort = {
  Janvier: "Jan",
  Février: "Fév",
  Mars: "Mar",
  Avril: "Avr",
  Mai: "Mai",
  Juin: "Jun",
  Juillet: "Jul",
  Août: "Aoû",
  Septembre: "Sep",
  Octobre: "Oct",
  Novembre: "Nov",
  Décembre: "Déc",
};

function applyPercent(value, percent) {
  if (!value) return 0;
  if (!percent) return Math.round(value);
  value = parseFloat(value);
  return Math.round(value + value * (percent / 100));
}

const numberWithThousandSeparator = (x) => {
  return x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";
};

const parseNum = (num) => parseFloat(Math.round(num)).toLocaleString("fr-FR", {});

function ClientForecastTable(props) {
  const client = useSelector(selectClient);
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([
    { key: "ca" },
    { key: "marge" },
    { key: "masse_sal" },
    { key: "treso" },
    { key: "compte_courant_associes" },
  ]);
  const [showEditCell, setShowEditCell] = useState({});
  const [editedCell, setEditedCell] = useState({});
  const [, setRowPercent] = useState("");

  useEffect(() => {
    if (client.status === "ready") {
      const monthsInRange = [];

      const startMonth = dayjs(props.startDate).month();
      const startYear = dayjs(props.startDate).year();
      const endMonth = dayjs(props.endDate).month();
      const endYear = dayjs(props.endDate).year();

      for (let year = startYear; year <= endYear; year++) {
        const start = year === startYear ? startMonth : 0;
        const end = year === endYear ? endMonth : 11;

        for (let month = start; month <= end; month++) {
          monthsInRange.push({ month: month, year: year });
        }
      }

      const reorderedMonths = monthsInRange.map(({ month, year }) => {
        const monthIndex = month;
        return months[monthIndex] + " " + year.toString();
      });

      setColumns([
        {
          title: "",
          className: "forecast-checkbox",
          width: "30px",
          render: (text, record) =>
            record.key !== "compte_courant_associes" ? (
              <Checkbox
                checked={record.active}
                onChange={(e) => {
                  setDataSource(
                    dataSource.map((el) => {
                      if (el.key === record.key) return { ...el, active: e.target.checked };
                      return el;
                    })
                  );

                  var localSettings = { ...props.settings } || {};
                  localSettings.active = { ...props.settings?.active } || {};
                  localSettings.active[record.key] = e.target.checked;
                  props.setSettings(localSettings);
                }}
              ></Checkbox>
            ) : null,
        },
        {
          title: "",
          className: "forecast-add-percent",
          width: "60px",
          render: (text, record) => (
            <Popover
              placement="left"
              className="percent-popover"
              overlayClassName="percent-popover-overlay"
              style={{ width: "127px" }}
              content={
                <>
                  <p style={{ marginTop: "5px", marginLeft: "15px" }}>Incrémenter de : </p>
                  <Form
                    className="percantage-form"
                    name="basic"
                    onFinish={(values) => {
                      var localSettings = { ...props.settings } || {};
                      localSettings.percent = { ...props.settings?.percent } || {};
                      localSettings.percent[record.key] = parseFloat(values.percent);
                      props.setSettings(localSettings);
                    }}
                    initialValues={{
                      percent: props.settings?.percent?.[record.key],
                    }}
                  >
                    <Form.Item
                      name="percent"
                      style={{ display: "inline-block", marginBottom: "5px" }}
                    >
                      <Input />
                    </Form.Item>
                    <span style={{ top: "5px", left: "3px", position: "relative" }}>%</span>
                    <Form.Item
                      style={{ display: "inline-block", marginLeft: "20px", marginBottom: "5px" }}
                    >
                      <Button
                        shape="circle"
                        htmlType="submit"
                        style={{ border: "2px solid #4569F8" }}
                        icon={<CheckOutlined style={{ color: "#4569F8", fontWeight: "700" }} />}
                      />
                    </Form.Item>
                  </Form>
                </>
              }
              trigger="click"
              onOpenChange={(open) => {
                if (open) setRowPercent(record.key);
              }}
            >
              <PlusCircleFilled
                style={
                  props.settings?.percent?.[record.key] &&
                  props.settings?.percent?.[record.key] !== 0
                    ? { fontSize: "31px", color: "#4569F8" }
                    : { fontSize: "31px", color: "#E9E9E9" }
                }
                className="plus-circle-icon"
              />
            </Popover>
          ),
        },
        {
          title: "",
          fixed: "left",
          className: "table-indicator-col",
          dataIndex: "type",
          key: "type",
          width: "140px",
          render: (text, record) =>
            record.active ? text : <div style={{ color: "grey" }}>{text}</div>,
        },
        ...reorderedMonths.map((monthText) => {
          const [month, year] = monthText.split(" ");

          const paddedMonth = (months.findIndex((monthItem) => monthItem === month) + 1)
            .toString()
            .padStart(2, "0");
          const paddedDate = paddedMonth + "-" + year;

          return {
            title: monthsShort[month] + " " + year.toString().slice(-2),
            dataIndex: paddedDate,
            width: "100px",
            align: "center",
            key: paddedDate,
            onCell: (record) => {
              return {
                onMouseEnter: () => {
                  setShowEditCell({
                    type: record.key,
                    month: paddedMonth,
                  });
                },
                onMouseLeave: () => {
                  setShowEditCell({});
                },
                onClick: () => {
                  setEditedCell({
                    type: record.key,
                    month: paddedMonth,
                    date: paddedDate,
                  });
                },
              };
            },
            render: (text, record) => {
              return record.active ? (
                <RenderCell
                  {...props}
                  key={record.key + paddedMonth}
                  text={applyPercent(text, props.settings?.percent?.[record.key] || 0) || 0}
                  type={record.key}
                  year={year}
                  month={paddedMonth}
                  date={paddedDate}
                  showEditCell={showEditCell}
                  editedCell={editedCell}
                  setEditedCell={setEditedCell}
                />
              ) : (
                <div style={{ color: "grey" }}>
                  {numberWithThousandSeparator(
                    parseNum(applyPercent(text, props.settings?.percent?.[record.key] || 0) || 0)
                  )}
                </div>
              );
            },
          };
        }),
        { title: "", className: "forecast-last-cell", width: "0px", fixed: "right" },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client.fiscalStartMonth,
    showEditCell,
    editedCell,
    dataSource,
    props.settings,
    client,
    props.startDate,
    props.endDate,
  ]);

  useEffect(() => {
    console.log("setDatasource ln275");
    console.log(dataSource);
    let types = [
      { short: "ca", long: "Chiffre d'affaires" },
      { short: "marge", long: "Marge" },
      { short: "masse_sal", long: "Masse salariale" },
      { short: "treso", long: "Trésorerie" },
      { short: "compte_courant_associes", long: "Apport en compte courant" },
    ];

    setDataSource(
      Object.entries(props.indicators).map((el, i) => ({
        key: el[0],
        type:
          types.find((type) => type.short === el[0])?.long ||
          props.customIndicators.find((customIndic) => customIndic.id === el[0])?.name,
        active: props.settings?.active[el[0]],
        ...el[1].reduce((obj, item) => {
          const date = new Date(item.date);
          const key = `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
          if (!obj[key]) obj[key] = item.value || 0;
          return obj;
        }, {}),
      }))
    );
    console.log(dataSource);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.indicators, props.settings]);

  return (
    <>
      <p style={{ fontWeight: "bold", marginTop: "40px" }}>
        Personnalisez vos données prévisonnelles :
      </p>
      <div className="forecast-table-div">
        <Table
          className="forecast-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: "1350" }}
        />
      </div>
    </>
  );
}
export default ClientForecastTable;
