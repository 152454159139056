import { DownCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logAction from "utils/logActions";
import { ReactComponent as ImportantIcon } from "../../../assets/images/important.svg";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import * as number from "../../../utils/number";
import AccountGlobalTable from "../AccountGlobalTable";
import Operation from "./Operation";

const { Panel } = Collapse;

function Category(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);

  const [totalAmount, setTotalAmount] = useState();
  const [panelIsActive, setPanelIsActive] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeOperations, setActiveOperations] = useState([]);

  useEffect(() => {
    setTotalAmount(
      number.withThousandSeparator(
        number.parseNum(
          props.category.operations
            .filter((op) => op.active !== false)
            .reduce((acc, curr) => acc + Math.abs(curr.amount), 0)
        )
      )
    );
    setActiveOperations(props.category.operations.filter((op) => op.active !== false));
  }, [props.category]);

  useEffect(() => {
    setPanelIsActive(props.allCollapse ? [props.category._id] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allCollapse]);

  const onChange = (state) => {
    dispatch(
      updateClient({
        uncheckedAccounts: state
          ? client.uncheckedAccounts.filter(
              (cat) => cat !== props.category.accountNumber + "-" + props.category.typeId
            )
          : [
              ...client.uncheckedAccounts,
              props.category.accountNumber + "-" + props.category.typeId,
            ],
      })
    );

    dispatch(
      updateClient({
        operationCategories: [
          ...client.operationCategories.filter((cat) => cat._id !== props.category._id),
          {
            ...props.category,
            operations: props.category.operations.map((op) => ({
              ...op,
              active: state,
            })),
          },
        ],
      })
    );
  };

  return (
    <>
      <Collapse
        bordered={false}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <DownCircleOutlined style={{ fontSize: "26px" }} rotate={isActive ? 180 : 0} />
        )}
        onChange={(activeKey) => setPanelIsActive(activeKey)}
        activeKey={panelIsActive}
      >
        <Panel
          key={props.category._id}
          header={
            <>
              <span className="operation-title">
                <ImportantIcon className="operation-title-circle" />
              </span>
              <span className="operation-title-text">
                {props.category.accountNumber} - {props.category.title} - {totalAmount}
                {props.category.operations.find(
                  (op) =>
                    op.ghost === 1 &&
                    (op.fileId ||
                      op.files?.length > 0 ||
                      op.comment ||
                      op.isLost ||
                      op.isLostNoAccount)
                ) ? (
                  <ExclamationCircleOutlined
                    style={{
                      fontSize: "20px",
                      color: "#FF9700",
                      marginLeft: "5px",
                      verticalAlign: "sub",
                    }}
                  />
                ) : null}
                {props.editMode ? (
                  <Checkbox
                    onClick={(e) => e.stopPropagation()}
                    className="category-checkbox"
                    indeterminate={
                      !!activeOperations.length &&
                      activeOperations.length < props.category.operations.length
                    }
                    checked={activeOperations.length === props.category.operations.length}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                ) : null}
              </span>
            </>
          }
          extra={
            panelIsActive.length ? (
              <p
                className="show-all-op-link missing-doc-all-op"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  logAction(231, 1, client._id);
                  setShowModal(true);
                }}
              >
                Afficher l'ensemble des opérations du compte
              </p>
            ) : null
          }
        >
          {props.category.operations.map((operation) => (
            <Operation {...props} operation={operation} key={operation._id} />
          ))}
        </Panel>
      </Collapse>
      <Modal
        title={props.category.accountNumber + " - " + props.category.title}
        className="all-op-modal"
        open={showModal}
        onCancel={(e) => {
          e.preventDefault();
          setShowModal(false);
        }}
        footer={null}
      >
        <AccountGlobalTable {...props} client={client} account={props.category.accountNumber} />
      </Modal>
    </>
  );
}

export default Category;
