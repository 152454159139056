import { DeleteOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import * as API from "../../../api/API";
import buildFileName from "../../../utils/buildFileName";

function createAndDownloadBlobFile(body, filename, extension) {
  const blob = new Blob([body]);
  const fileName = `${filename}`;
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function FileDisplay(props) {
  const [fileIsDeleting, setFileIsDeleting] = useState(false);
  const [fileId] = useState(props.file._id);

  const operation = props.operation;
  const file = props.file !== "" ? props.file.filename : "FileName";
  const fileName = buildFileName(props.file.filename, operation.text);
  const idOpCategory = props.type._id;
  const tokenClient = props.match.params.token;
  const decodedToken = jwt_decode(props.match.params.token);
  const idClient = jwt_decode(tokenClient).clientId;

  const downloadFile = (id, name, idOp, idOpCategory) => {
    (async () => {
      const res = await API.postDownload({
        fileid: id,
        clientId: idClient,
        idOp,
        idOpCategory,
        client: true,
      });
      if (res.status === 200) {
        const data = await res.blob();
        var ext = "";
        if (
          data.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          ext = "docx";
        } else if (data.type === "application/msword") {
          ext = "doc";
        } else if (data.type === "text/plain") {
          ext = "txt";
        } else if (data.type === "application/vnd.ms-excel") {
          ext = "xls";
        } else if (
          data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          ext = "xlsx";
        } else {
          ext = data.type.split("/")[1];
        }
        createAndDownloadBlobFile(data, name, ext);
      } else console.log("error");
    })();
  };

  function onDelete() {
    setFileIsDeleting(true);

    const dataDelete = {
      idClient: idClient,
      fileName: fileName,
      operation,
      fileId: fileId,
      type: props.type.typeText,
    };
    const dataTracker = {
      action: "DELETE",
      operationId: operation._id,
      dateOp: operation.date,
      type: props.type.typeText,
      category: props.type.title !== undefined ? props.type.title.replace("/", "%2F") : "Autres",
      label: operation.text,
      reference: operation.pieceRef,
      amount: operation.amount,
      nature: operation.nature,
      fileName: fileName,
    };

    console.log(decodedToken.mcf);

    if (decodedToken.mcf) {
      API.deleteFileMCF(dataDelete);
    }

    (async () => {
      let res = await API.deleteMissingDocumentFile(
        operation._id.replaceAll("/", "%2F"),
        dataDelete
      );
      if (res.status === 200) {
        API.postClientTracker(idClient, dataTracker);
        console.log("Fichier supprimé");
        setFileIsDeleting(false);
        props.getDeleteFile(fileId);
      }
    })();
  }

  return (
    <>
      <div className="file-display">
        <span
          className="filename-action"
          onClick={(e) => {
            e.preventDefault();
            if (fileId) {
              downloadFile(fileId, file, operation._id, idOpCategory);
            }
          }}
        >
          {props.file.filename}
        </span>
        {fileIsDeleting ? (
          <Spin size="small" />
        ) : (
          <DeleteOutlined
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
          />
        )}
      </div>
    </>
  );
}

export default FileDisplay;
