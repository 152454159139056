import {
  ArrowRightOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockFilled,
} from "@ant-design/icons";
import "animate.css";
import { Alert, Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { ReactComponent as LogoSeshaWhite } from "../../assets/images/logo-sesha-white.svg";
import logoSesha from "../../assets/images/logo-sesha.svg";
import logAction from "../../utils/logActions";
import { seshaLogoModifier } from "./../nav/logoModifier";

import { EFeatures } from "enums/EFeatures";
import "./Login.css";

const { Title, Text, Link } = Typography;

const interruptionId = "service_interruption";
const newsId = "news";

function Login(props) {
  const [email, getEmail] = useState("");
  const [pwd, getPwd] = useState("");
  const [verif_email, isValidEmail] = useState(false);
  const [user, setUser] = useState({});
  const [alertMail, setAlertMail] = useState(false);
  const [alertPwd, setAlertPwd] = useState(false);
  const [alertDeactivated, setAlertDeactivated] = useState(false);
  const [visible, setVisible] = useState(false);
  const [interruptionMsg, setInterruptionMsg] = useState({});
  const [newsMsg, setNewsMsg] = useState({});
  const [coAttempt, setCoAttempt] = useState(0);
  const [modifiedSeshaLogo, setModifiedSeshaLogo] = useState("");

  // logo modification depending on environment
  useEffect(() => {
    (async () => { 
      setModifiedSeshaLogo(btoa(await seshaLogoModifier(logoSesha, process.env.REACT_APP_ENV)))
    })();
  }, []);

  // redirection vers homepage si token existant et non expiré
  useEffect(() => {
    if (localStorage.getItem("token")) {
      const { exp } = jwt_decode(localStorage.getItem("token"));
      if (Date.now() < exp * 1000) {
        props.history.push({
          pathname: "/homepage",
        });
      } else localStorage.removeItem("token");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_INTERFACE_BACKEND + "/public/message/" + interruptionId, {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => res.json())
      .then((response) => {
        setInterruptionMsg(response);
      })
      .catch((error) => console.log(error));

    fetch(process.env.REACT_APP_INTERFACE_BACKEND + "/public/message/" + newsId, {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => res.json())
      .then((response) => {
        setNewsMsg(response);
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPwdFinish = (values) => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_INTERFACE_BACKEND + "/public/authentication",
      headers: {
        "Content-Type": "application/json",
      },
      data: { username: email, password: pwd },
    })
      .then((response) => {
        if (response.status === 200) {
          const decodedToken = jwt_decode(response.data.token);
          localStorage.setItem("token", response.data.token);
          if (decodedToken.theme) {
            localStorage.setItem("theme", decodedToken.theme);
          }

          logAction(1, 1);
          if (decodedToken.beta) {
            window.location.href =
              process.env.REACT_APP_URL_BETA + "/landing/" + response.data.token;
          } else if (
            decodedToken.features.includes(EFeatures.ACCESS_TO_FORMS) &&
            !decodedToken.features.includes(EFeatures.ACCESS_TO_SESHA)
          ) {
            window.location.href =
              process.env.REACT_APP_URL_FORMS + "/landing/" + response.data.token;
          } else window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setAlertPwd(true);
            setCoAttempt(coAttempt + 1);
          } else if (error.response.status === 402) {
            setAlertDeactivated(true);
          } else {
            console.log(error);
          }
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const verifyEmail = () => {
    fetch(process.env.REACT_APP_INTERFACE_BACKEND + "/public/verifyEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.email) {
          isValidEmail(true);
          setUser(response);
        } else {
          setAlertMail(true);
        }
      })
      .catch((error) => console.log(error));
  };

  const resetUserPwd = () => {
    fetch(process.env.REACT_APP_INTERFACE_BACKEND + "/public/forgotpwd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        firstName: user.firstName,
        id: user.id,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        props.history.push({
          pathname: "/",
        });
      })
      .catch((error) => console.log(error));
  };

  const displayAlert = (alertMessage) => (
    <span className="alert-message">
      <Alert message={alertMessage} type="error" />
    </span>
  );

  const ModalReset = (
    <Modal
      className="reset-modal"
      title="Avez-vous oublié votre mot de passe ?"
      centered
      open={visible}
      onCancel={(e) => {
        e.preventDefault();
        setVisible(false);
      }}
      footer={[
        <Button
          key="reset"
          className="call-action-btn"
          onClick={(e) => {
            e.preventDefault();
            resetUserPwd();
            setVisible(false);
          }}
        >
          Réinitialiser le mot de passe
        </Button>,
        <Button
          key="back"
          className="other-action-btn"
          onClick={(e) => {
            e.preventDefault();
            setVisible(false);
          }}
        >
          Retour
        </Button>,
      ]}
      width={500}
    >
      <p>Si c'est le cas veuillez cliquer sur le bouton "Réinitialiser le mot de passe".</p>
      <p>Un mail vous sera envoyé afin de réinitialiser votre mot de passe.</p>
    </Modal>
  );

  const Mailto = ({ email, subject, children }) => {
    return <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""}`}>{children}</a>;
  };

  const coAttemptText =
    coAttempt < 5 ? "\r\n Il vous reste " + (5 - coAttempt) + " tentatives." : "";

  return (
    <div className="login">
      <Row justify="center" className="login-row">
        <Col xs={16} sm={16} md={8} lg={8} xl={8} className="left-box">
          <div>
            <img hidden={modifiedSeshaLogo===""} src={"data:image/svg+xml;base64,"+modifiedSeshaLogo} alt="Sesha"></img>
          </div>
          {verif_email === false ? (
            <div className="animate__animated animate__fadeIn">
              <div className="login-left-1">
                <Title level={3}>Mon compte</Title>
              </div>
              <div className="login-left-2"></div>
              <Form className="send-email-form">
                <div className="login-left-3">
                  <Text style={{ fontWeight: "bold" }}>Saisissez votre Email</Text>
                  <Form.Item name={["user", "email"]}>
                    <Input
                      className="email-input"
                      placeholder="Email"
                      autoFocus
                      value={email}
                      onChange={(event) => {
                        getEmail(event.target.value);
                        setAlertMail(false);
                      }}
                    />
                  </Form.Item>
                  <br />
                </div>
                <div className="login-left-4"></div>
                <div className="login-left-5">
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="submit call-action-btn"
                      onClick={verifyEmail}
                    >
                      Continuer <ArrowRightOutlined />
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          ) : (
            <div className="animate__animated animate__fadeIn">
              <div className="login-left-1">
                <Title level={3}>Bonjour {user.firstName},</Title>
              </div>
              <div className="login-left-2">
                {email}
                <Link
                  className="login-not-you"
                  onClick={() => {
                    isValidEmail(!verif_email);
                    setAlertMail(false);
                    setAlertPwd(false);
                    setCoAttempt(0);
                  }}
                >
                  Ce n'est pas vous ?
                </Link>
              </div>
              <Form
                className="send-pwd-form"
                onFinish={onPwdFinish}
                onFinishFailed={onFinishFailed}
              >
                <div className="login-left-3">
                  <Text style={{ fontWeight: "bold" }}>Saisissez votre mot de passe</Text>
                  <Form.Item name="password">
                    <Input.Password
                      className="pwd-input"
                      placeholder="Mot de passe"
                      disabled={coAttempt >= 5 ? true : false}
                      autoFocus
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      value={pwd}
                      onChange={(event) => {
                        getPwd(event.target.value);
                        setAlertPwd(false);
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="login-left-4">
                  <Link
                    href="#"
                    className="forget-txt login-txt"
                    onClick={(e) => {
                      e.preventDefault();
                      setVisible(true);
                    }}
                  >
                    Mot de passe oublié ?
                  </Link>
                </div>
                <div className="login-left-5">
                  <br />
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="submit call-action-btn"
                      disabled={coAttempt >= 5 ? true : false}
                    >
                      Continuer <ArrowRightOutlined />
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}
          {ModalReset}
          <div className="help">
            <Text className="need-help">Besoin d'aide ?</Text>
            <Mailto className="login-txt" email="support@sesha.expert" subject="Assistance Sesha">
              Contactez-nous
            </Mailto>
            <Text>
              {" "}
              –{" "}
              <Link className="link-website" href="https://www.sesha.expert/" target="_blank">
                A propos
              </Link>
            </Text>
          </div>
        </Col>
        <div className="alert-login">
          {alertMail
            ? displayAlert("L'email n'est pas valide. Merci de bien vouloir réessayer.")
            : null}

          {alertDeactivated
            ? displayAlert("Votre cabinet est inactif. Veuillez contacter votre administrateur.")
            : null}

          {alertPwd && coAttempt < 5
            ? displayAlert(
                "Le mot de passe n'est pas valide. Merci de bien vouloir réessayer." + coAttemptText
              )
            : null}
          {coAttempt >= 5
            ? displayAlert(
                'Le mot de passe n\'est pas valide. Merci de réinitialiser votre mot de passe en cliquant sur "Mot de passe oublié ?"'
              )
            : null}
        </div>
        <Col xs={16} sm={16} md={13} lg={13} xl={13} className="login-box">
          <div className="secured-txt">
            <LockFilled style={{ color: "white" }} className="lock" />
            <span className="secure">Espace sécurisé</span>
          </div>
          <div className="login-logo-white-parent">
            {interruptionMsg.active ? (
              <div className="service-interruption">
                <div className="service-interruption-title">{interruptionMsg.title}</div>
                <div className="service-interruption-message-1">{interruptionMsg.text}</div>
                <div className="service-interruption-message-2">
                  Votre satisfaction est notre priorité et pour cette raison nous mettons à jour
                  votre service.
                </div>
              </div>
            ) : null}

            {newsMsg.active ? (
              <div className="service-interruption">
                <div className="news-message" dangerouslySetInnerHTML={{ __html: newsMsg.text }} />
              </div>
            ) : null}

            {!interruptionMsg.active && !newsMsg.active ? (
              <div className="login-logo-white-child">
                <LogoSeshaWhite />
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
