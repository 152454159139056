import { ClockCircleFilled, DeleteOutlined, LockFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Radio, Space, Table, Tag } from "antd";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";

import { EFeatures } from "enums/EFeatures";
import { hasFeatures } from "utils/security";
import * as API from "../../../api/API";
import LoaderRaw from "../../../utils/LoaderRaw";
import openNotification from "../../../utils/notification";
import CreateUser from "./CreateUser.js";
import EditUser from "./EditUser.js";
import ExpandedComponent from "./ExpandedComponent";

const howManyDaysSince = (date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(date);
  const secondDate = new Date();

  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
};

const howManyElementSince = (datesArray = []) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  let newArray = [];
  for (let index = 0; index < datesArray.length; index++) {
    const element = datesArray[index];
    if (new Date(element) > thirtyDaysAgo) newArray.push(element);
  }
  return newArray.length;
};

function UsersDashboard(props) {
  const [, setUserList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [userListUpdate, setUserListUpdate] = useState(false);
  const [firmList, setFirmList] = useState([]);
  const [switchTotal, setSwitchTotal] = useState(false);
  const [displayHeader, setDisplayHeader] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [dataUserInfos, setDataUsersInfos] = useState();
  const [dataUsersStats, setDataUsersStats] = useState();
  const [selectionType] = useState("checkbox");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [roleList, setRoleList] = useState([]);
  const searchInput = useRef(null);

  useEffect(() => {
    (async () => {
      // fast data retrieving
      Promise.all([
        API.getAccountingFirms(),
        API.getUsersLight(),
        API.getScheduleUsers(),
        API.getDashboardRoles(),
      ]).then(async (response) => {
        Promise.all(response.map((res) => res.json())).then((jsonResponse) => {
          const [resA, users, schedule, roles] = jsonResponse;
          setFirmList(resA);
          let accountingFirms = resA.map((obj) => ({
            accountingFirmId: obj._id,
            accountingFirmName: obj.name,
            accountingFirmActive: obj.active,
          }));

          // renommage des id de l'objet schedule puis merge de schedule dans users
          schedule.forEach(function (obj) {
            obj.scheduledId = obj._id;
            delete obj._id;
          });

          let merge = users.map((t1) => ({
            ...t1,
            ...schedule.find((t2) => t2.userId === t1._id),
          }));

          // merge accounting firm dans le tableau (users + schedule)
          merge = merge.map((t1) => ({
            ...t1,
            ...accountingFirms.find((t2) => t2.accountingFirmId === t1.accountingFirmId),
          }));

          setUserList(users);
          setRoleList(roles);
          setUserListUpdate(false);
          setDataUsersInfos(merge);
        });
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListUpdate === true]);

  useEffect(() => {
    (async () => {
      // secondary data in parallel
      Promise.all([API.getUsers(), API.getReports()]).then(async (response) => {
        Promise.all(response.map((res) => res.json())).then((jsonResponse) => {
          const [users, reports] = jsonResponse;
          let merge = users;

          merge = merge.map((t1) => ({
            ...t1,
            reportCount: reports.filter((rep) => rep.userId === t1._id)?.length || 0,
          }));

          setUserList(users);
          setUserListUpdate(false);
          setDataUsersStats(merge);
        });
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListUpdate === true]);

  // produce dataSource from parallel data retrieving result
  useEffect(() => {
    (async () => {
      const dataToMerge = [dataUserInfos, dataUsersStats].filter((data) => data);
      let merge = dataToMerge.pop();

      dataToMerge.forEach((data) => {
        merge = merge.map((t1) => ({
          ...t1,
          ...data.find((t2) => t2._id === t1._id),
        }));
      });

      merge?.forEach(function (obj) {
        obj.key = obj._id;
        obj.fullName = obj.firstName + " " + obj.lastName;
        obj.scheduledDate = obj.date;
      });

      setDataSource(merge);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUserInfos, dataUsersStats]);

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={title}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 120 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
            Réinitialiser
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "var(--blue)" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDelete = () => {
    if (
      window.confirm(
        `Voulez-vous supprimer les utilisateurs suivants :\r ${selectedRows.map(
          (r) => r.firstName + " " + r.lastName
        )}?`
      )
    ) {
      selectedRows.forEach((elt) => {
        (async () => {
          const user = await dataSource.find((user) => user.email === elt.email);
          const res = await API.deleteUser(user._id);
          if (res.status === 200) {
            setUserListUpdate(true);
            setDisplayHeader(false);
            if (user.scheduledId) API.deleteScheduleUser(user.scheduledId);
            openNotification("success", "Utilisateur supprimé");
          } else openNotification("error", "Erreur lors de la suppression");
        })();
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length === 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayEdit(true);
        setDisplayDelete(true);
      } else if (selectedRows.length >= 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayEdit(false);
        setDisplayDelete(true);
      } else {
        setSelectedRows(null);
        setDisplayHeader(false);
        setDisplayEdit(false);
        setDisplayDelete(false);
      }
    },
  };

  function displayLoadingData(data) {
    return data === undefined ? <i className="data-cell-loading"></i> : data;
  }

  const columns = [
    {
      title: "Nom",
      dataIndex: "fullName",
      fixed: "left",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      ...getColumnSearchProps("fullName", "Nom ou Prénom"),
      render: (text, record) => {
        return (
          <span style={{ display: "flex", flexWrap: "nowrap" }}>
            {searchedColumn === "fullName" ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text}
              />
            ) : (
              text
            )}
            {record.scheduledDate ? (
              <span
                style={{
                  flexGrow: 1,
                  color: "#ffad21",
                  textAlign: "right",
                  alignSelf: "center",
                }}
              >
                <ClockCircleFilled title="Activation programmée" />
              </span>
            ) : null}
          </span>
        );
      },
    },
    {
      title: "Cabinet Comptable",
      dataIndex: ["accountingFirmName", "accountingFirmActive"],
      align: "center",
      sorter: (a, b) => a.accountingFirmName.localeCompare(b.accountingFirmName),
      ...getColumnSearchProps("accountingFirmName", "Cabinet Comptable"),
      render: (text, record) => {
        return (
          <>
            {searchedColumn === "accountingFirmName" ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={record.accountingFirmName}
              />
            ) : (
              record.accountingFirmName
            )}
            {!record.accountingFirmActive ? (
              <>
                {" "}
                <LockFilled title="Cabinet désactivé" style={{ color: "#c32028" }} />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Email",
      dataIndex: ["email"],
      align: "center",
      ellipsis: true,
      sorter: (a, b) => a.email?.localeCompare(b.email),
      ...getColumnSearchProps("email", "Email"),
      render: (text, record) => {
        return (
          <>
            {searchedColumn === "email" ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text}
              />
            ) : (
              text
            )}
          </>
        );
      },
    },
    {
      title: "Dernière connexion (en jours)",
      dataIndex: "lastLogin",
      align: "center",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        let x, y;
        a.lastLogin || a.logins?.length > 0
          ? (x = new Date(a.lastLogin || a.logins.slice(-1)[0]))
          : (x = new Date("2020-01-01T00:00:00Z"));
        b.lastLogin || b.logins?.length > 0
          ? (y = new Date(b.lastLogin || b.logins.slice(-1)[0]))
          : (y = new Date("2020-01-01T00:00:00Z"));
        return y - x;
      },
      render: (text, row) =>
        row.lastLogin || row.logins?.length > 0
          ? howManyDaysSince((row.logins || []).slice(-1)[0] || row.lastLogin) === 0
            ? "Aujourd'hui"
            : howManyDaysSince((row.logins || []).slice(-1)[0] || row.lastLogin)
          : "Jamais",
    },
    {
      title: "Nombre de connexions",
      dataIndex: "logins",
      align: "center",
      sorter: (a, b) => {
        let x, y;
        a.logins ? (x = a.logins.length) : (x = 0);
        b.logins ? (y = b.logins.length) : (y = 0);
        return x - y;
      },
      render: (text, row) => displayLoadingData(row.logins?.length),
    },
    {
      title: "Analyses générées",
      dataIndex: "analysisGenerated",
      align: "center",
      switchTotal: true,
      sorter: (a, b) => {
        let x, y;
        a.analysisGenerated ? (x = a.analysisGenerated.length) : (x = 0);
        b.analysisGenerated ? (y = b.analysisGenerated.length) : (y = 0);
        return x - y;
      },
      render: (text, row) => displayLoadingData(row.analysisGenerated?.length),
    },
    {
      title: "Analyses générées  30 jours glissants",
      dataIndex: "analysisGenerated",
      align: "center",
      switchTotal: false,
      sorter: (a, b) => {
        let x, y;
        a.analysisGenerated ? (x = howManyElementSince(a.analysisGenerated)) : (x = 0);
        b.analysisGenerated ? (y = howManyElementSince(b.analysisGenerated)) : (y = 0);
        return x - y;
      },
      render: (text, row) => displayLoadingData(howManyElementSince(row.analysisGenerated)),
    },
    {
      title: "Nombre de cliques sur la VPF",
      dataIndex: "clickedVPF",
      align: "center",
      sorter: (a, b) => {
        let x, y;
        a.clickedVPF ? (x = a.clickedVPF.length) : (x = 0);
        b.clickedVPF ? (y = b.clickedVPF.length) : (y = 0);
        return x - y;
      },
      render: (text, row) => displayLoadingData(row.clickedVPF?.length),
    },
    {
      title: "Rapports",
      dataIndex: "reportCount",
      align: "center",
      sorter: (a, b) => a.reportCount - b.reportCount,
      render: (text, row) => displayLoadingData(row.reportCount),
    },
    {
      title: "Emails",
      switchTotal: true,
      children: [
        {
          title: "Analyses",
          dataIndex: "analysisEmailSent",
          align: "center",
          sorter: (a, b) => {
            let x, y;
            a.analysisEmailSent ? (x = a.analysisEmailSent.length) : (x = 0);
            b.analysisEmailSent ? (y = b.analysisEmailSent.length) : (y = 0);
            return x - y;
          },
          render: (text, row) => displayLoadingData(row.analysisEmailSent?.length),
        },
        {
          title: "Pièces manquantes",
          dataIndex: "missingDocEmailSent",
          align: "center",
          sorter: (a, b) => {
            let x, y;
            a.missingDocEmailSent ? (x = a.missingDocEmailSent.length) : (x = 0);
            b.missingDocEmailSent ? (y = b.missingDocEmailSent.length) : (y = 0);
            return x - y;
          },
          render: (text, row) => displayLoadingData(row.missingDocEmailSent?.length),
        },
        {
          title: "Encours clients",
          dataIndex: "outstandingsClientsEmailSent",
          align: "center",
          sorter: (a, b) => {
            let x, y;
            a.outstandingsClientsEmailSent ? (x = a.outstandingsClientsEmailSent.length) : (x = 0);
            b.outstandingsClientsEmailSent ? (y = b.outstandingsClientsEmailSent.length) : (y = 0);
            return x - y;
          },
          render: (text, row) => displayLoadingData(row.outstandingsClientsEmailSent?.length),
        },
        {
          title: "Encours fournisseurs",
          dataIndex: "outstandingsSuppliersEmailSent",
          align: "center",
          sorter: (a, b) => {
            let x, y;
            a.outstandingsSuppliersEmailSent
              ? (x = a.outstandingsSuppliersEmailSent.length)
              : (x = 0);
            b.outstandingsSuppliersEmailSent
              ? (y = b.outstandingsSuppliersEmailSent.length)
              : (y = 0);
            return x - y;
          },
          render: (text, row) => displayLoadingData(row.outstandingsSuppliersEmailSent?.length),
        },
      ],
    },
    {
      title: "Emails sur 30 jours glissants",
      switchTotal: false,
      children: [
        {
          title: "Analyses",
          dataIndex: "analysisEmailSent",
          align: "center",
          sorter: (a, b) => {
            let x, y;
            a.analysisEmailSent ? (x = howManyElementSince(a.analysisEmailSent)) : (x = 0);
            b.analysisEmailSent ? (y = howManyElementSince(b.analysisEmailSent)) : (y = 0);
            return x - y;
          },
          render: (text, row) => displayLoadingData(howManyElementSince(row.analysisEmailSent)),
        },
        {
          title: "Pièces manquantes",
          dataIndex: "missingDocEmailSent",
          align: "center",
          sorter: (a, b) => {
            let x, y;
            a.missingDocEmailSent ? (x = howManyElementSince(a.missingDocEmailSent)) : (x = 0);
            b.missingDocEmailSent ? (y = howManyElementSince(b.missingDocEmailSent)) : (y = 0);
            return x - y;
          },
          render: (text, row) => displayLoadingData(howManyElementSince(row.missingDocEmailSent)),
        },
        {
          title: "Encours clients",
          dataIndex: "outstandingsClientsEmailSent",
          align: "center",
          sorter: (a, b) => {
            let x, y;
            a.outstandingsClientsEmailSent
              ? (x = howManyElementSince(a.outstandingsClientsEmailSent))
              : (x = 0);
            b.outstandingsClientsEmailSent
              ? (y = howManyElementSince(b.outstandingsClientsEmailSent))
              : (y = 0);
            return x - y;
          },
          render: (text, row) =>
            displayLoadingData(howManyElementSince(row.outstandingsClientsEmailSent)),
        },
        {
          title: "Encours fournisseurs",
          dataIndex: "outstandingsSuppliersEmailSent",
          align: "center",
          sorter: (a, b) => {
            let x, y;
            a.outstandingsSuppliersEmailSent
              ? (x = howManyElementSince(a.outstandingsSuppliersEmailSent))
              : (x = 0);
            b.outstandingsSuppliersEmailSent
              ? (y = howManyElementSince(b.outstandingsSuppliersEmailSent))
              : (y = 0);
            return x - y;
          },
          render: (text, row) =>
            displayLoadingData(howManyElementSince(row.outstandingsSuppliersEmailSent)),
        },
      ],
    },
    {
      title: "Droits ouverts",
      dataIndex: "newRole",
      align: "center",
      sorter: (a, b) => a.newRoleLabel.localeCompare(b.newRoleLabel),
      render: (text, row) => {
        const label = roleList.find((el) => el._id === row.newRole)?.label;
        const tagColor = label?.includes("SA ")
          ? "red"
          : label?.includes("Administrateur")
          ? "gold"
          : label?.includes("Utilisateur")
          ? "geekblue"
          : "green";
        return label ? <Tag color={tagColor}>{label}</Tag> : "";
      },
      filters: [{ text: "SUPERADMIN", value: "SA " }],
      onFilter: (value, record) => {
        const label = roleList.find((el) => el._id === record.newRole)?.label;
        return label && label.includes(value);
      },
    },
  ];

  return (
    <div className="userDashboard">
      <CreateUser
        {...props}
        updateUser={(data) => {
          setUserListUpdate(data);
        }}
        firmList={firmList}
        roleList={roleList}
      />

      <div className="wrapper">
        <div className="switchTotal"></div>
        <Radio.Group value={switchTotal} onChange={(e) => setSwitchTotal(e.target.value)}>
          <Radio className="radio" value={false}>
            <span>Total</span>
          </Radio>
          <Radio className="radio" value={true}>
            <span>30 jours glissants</span>
          </Radio>
        </Radio.Group>
      </div>

      {displayHeader ? (
        <div className="header-table">
          {displayDelete ? (
            <div
              style={{
                display: "inline-block",
                float: "right",
                padding: "9px",
              }}
            >
              <Button
                className="call-action-btn"
                key="delete"
                onClick={handleDelete}
                icon={<DeleteOutlined />}
              >
                Supprimer
              </Button>
            </div>
          ) : null}
          {displayEdit ? (
            <div
              style={{
                display: "inline-block",
                float: "right",
                padding: "9px",
              }}
            >
              <EditUser
                {...props}
                firmList={firmList}
                updateUser={(data) => {
                  setUserListUpdate(data);
                }}
                selectedRow={selectedRows[0]}
                roleList={roleList}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      <Table
        rowSelection={
          hasFeatures([EFeatures.SUPERADMIN_WRITE, EFeatures.SUPERADMIN_TECH])
            ? {
                type: selectionType,
                ...rowSelection,
              }
            : false
        }
        columns={columns.filter((col) => col.switchTotal !== switchTotal)}
        dataSource={dataSource}
        expandable={{
          expandedRowRender: (record) => record.logins && <ExpandedComponent data={record} />,
        }}
        size="small"
        bordered
        scroll={{ x: 1300 }}
        pagination={{ defaultPageSize: 50, showSizeChanger: true }}
        loading={{ indicator: <LoaderRaw />, spinning: !dataSource }}
      />
    </div>
  );
}

export default UsersDashboard;
