import React from "react";
import { Column } from "@ant-design/plots";

const MoneyGraphComponentColumn = (props) => {
  var config = {
    height: 200,
    data: props.data,
    xField: props.valuex,
    yField: props.valuey,
    columnStyle: {
      fill: props.color,
    },
  };
  return (
    <div className="money-chart-component">
      <p>{props.title}</p>
      <Column {...config} />
    </div>
  );
};
export default MoneyGraphComponentColumn;
