import { Col, Pagination, Row } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectClient } from "../../../slices/clientSlice";
import * as number from "../../../utils/number";
import "../MissingDocuments.css";

const ITEMS_PER_PAGE = 200;

function Unchecked(props) {
  const client = useSelector(selectClient);

  const [categories, setCategories] = useState([]);
  const [operations, setOperations] = useState([]);
  const [currentOperations, setCurrentOperations] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCategories(
      client.operationCategories
        ?.map((cat) => ({
          ...cat,
          operations: cat.operations.filter(
            (operation) =>
              dayjs(operation.date) >= props.startDate &&
              dayjs(operation.date) <= props.endDate &&
              props.journalListFiltered.includes(operation.journal?.trim())
          ),
        }))
        .filter(({ operations }) => operations.length > 0) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client.operationCategories,
    props.startDate,
    props.endDate,
    props.journalListFiltered,
    props.editMode,
    client.uncheckedAccounts,
  ]);

  useEffect(() => {
    setOperations(
      categories
        .map((cat) =>
          client.uncheckedAccounts?.includes(cat.accountNumber + "-" + cat.typeId)
            ? cat.operations
            : cat.operations.filter((op) => op.active === false)
        )
        .flat(1)
    );
  }, [categories]);

  useEffect(() => {
    setTotalAmount(operations.reduce((acc, current) => acc + Math.abs(current.amount), 0));
  }, [operations]);

  useEffect(() => {
    const indexOfLastOperation = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstOperation = indexOfLastOperation - ITEMS_PER_PAGE;
    setCurrentOperations(operations.slice(indexOfFirstOperation, indexOfLastOperation));
  }, [operations, currentPage]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return totalAmount ? (
    <>
      <div className="type-title title-unchecked">
        Pièces non demandées - {number.withThousandSeparator(number.parseNum(totalAmount))}
      </div>
      <div className="type-separation"></div>
      <div className="unchecked">
        {currentOperations.map((operation, k) => (
          <div key={k}>
            <span className="operation">
              <div className="site-collapse-custom-text-block-grey">
                <Row className="op-row">
                  <Col xs={22} sm={22} md={22} lg={3} xl={3}>
                    <span className="data-op">{dayjs(operation.date).format("DD/MM/YYYY")}</span>
                  </Col>
                  <Col xs={22} sm={22} md={22} lg={7} xl={7}>
                    {operation.text ? operation.text : "[Absence de libellé]"}
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6} xl={6} className="piece-ref">
                    {operation.piece_ref &&
                    operation.piece_ref !== "NONE" &&
                    operation.piece_ref !== "NAN"
                      ? operation.piece_ref
                      : ""}
                  </Col>
                  <Col xs={10} sm={10} md={10} lg={6} xl={6} offset={1}>
                    <div className="div-received-btn">
                      <span className="operation-amount">
                        {number.parseNum(Math.abs(operation.amount))} €
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </span>
          </div>
        ))}
      </div>
      <div style={{ width: "93.8%", textAlign: "right" }}>
        <Pagination
          current={currentPage}
          total={operations.length}
          pageSize={ITEMS_PER_PAGE}
          onChange={paginate}
          showSizeChanger={false}
          hideOnSinglePage={true}
        />
      </div>
    </>
  ) : null;
}

export default Unchecked;
