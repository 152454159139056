import { FrownOutlined, InfoCircleFilled, MailOutlined } from "@ant-design/icons";
import {
  Affix,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Progress,
  Result,
  Select,
  Skeleton,
} from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../api/API";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import { getMailDate } from "../../../utils/getDate";
import * as number from "../../../utils/number";
import "../MissingDocuments.css";
import Others from "./Others";
import Type from "./Type";
import Unchecked from "./Unchecked";

const { Option } = Select;

const defaultTemplate = [
  {
    _id: "MISSING_DOC",
    name: "Modèle Sesha",
    type: "MISSING_DOC",
    default: true,
    sesha: true,
    template: {
      add_accounts: ["40", "41", "47"],
      remove_accounts: ["404", "408", "416", "418", "419"],
      // add_journals: [], add journals pas dans le modèle mais directement au niveau du client
    },
  },
];

function MissingDocuments(props) {
  dayjs.locale("fr");
  const dispatch = useDispatch();
  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);
  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;

  const [filteredOpEnc, setFilteredOpEnc] = useState([]);
  const [filteredOpDec, setFilteredOpDec] = useState([]);
  const [filteredOp, setFilteredOp] = useState([]);

  const [nbrMissingDocEnc, setNbrMissingDocEnc] = useState(0);
  const [nbrMissingDocDec, setNbrMissingDocDec] = useState(0);
  const [nbrMissingDocuments, setNbrMissingDocuments] = useState(0);

  const [amountMissingDocEnc, setAmountMissingDocEnc] = useState(0);
  const [amountMissingDocDec, setAmountMissingDocDec] = useState(0);
  const [amountMissingDoc, setAmountMissingDoc] = useState(0);

  const [missingDocData, setMissingDocData] = useState();
  const [changeDate, setChangeDate] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(dayjs());
  const [journalList, setJournalList] = useState([]);
  const [journalListFiltered, setJournalListFiltered] = useState([]);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [allCollapse, setAllCollapse] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [filteredJournalList, setFilteredJournalList] = useState([]);
  const [excludedJournals, setExcludedJournals] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState();
  const [showMissingDocMail, setShowMissingDocMail] = useState(false);
  const [displayAccount, setDisplayAccount] = useState(false);
  const [locker, setLocker] = useState(false);

  const ref = useRef(0);

  useEffect(() => {
    if (!props.wait)
      (async () => {
        setMissingDocData();
        let res = await API.getMissingDocuments(clientId);
        if (res.status === 200) {
          res = await res.json();
          setError(false);
          if (accountingFirm.fec === true) {
            const missingDocDataWithoutGhost = {
              ...res,
              operationCategories: res.operationCategories.map((category) => ({
                ...category,
                operations: category.operations.filter((operation) => operation.ghost !== 1),
              })),
            };
            setMissingDocData(missingDocDataWithoutGhost);
          } else {
            setMissingDocData(res);
          }
        } else {
          setError(true);
          props.setBadgeMissing(0);
        }
        setRefresh(false);
      })();
  }, [props.wait, clientRemoteId, props.date, refresh, props.refreshMissingDoc]);

  useEffect(() => {
    if (!props.wait && !error && missingDocData) {
      dispatch(
        updateClient({
          operationCategories: missingDocData.operationCategories,
          otherMissingDocuments: missingDocData.otherMissingDocuments,
        })
      );
    }
  }, [props.wait, clientRemoteId, props.date, refresh, missingDocData]);

  useEffect(() => {
    if (!props.wait && missingDocData) {
      const hasPendingDownloads = missingDocData.operationCategories?.some((category) =>
        category.operations.some((op) => !op.downloadedOnce && op.files?.length > 0)
      );
      setLocker(hasPendingDownloads);
    } else {
      setLocker(false);
    }
  }, [props.wait, missingDocData]);

  useEffect(() => {
    if (!props.wait && client) setDisplayAccount(!!client.showZeroAccounts);
  }, [client]);

  useEffect(() => {
    if (!props.wait);
    // setFilteredJournalList([]);
    // setExcludedJournals([]);
    (async () => {
      if (!accountingFirm.proAbonoFeatures?.includes("analysis_config")) {
        setCurrentTemplate(defaultTemplate);
      } else {
        let res = await API.getAnalysisConfigTemplates(clientId);
        if (res.status === 200) {
          res = await res.json();
          const current = res.find((t) => t._id === client.templates?.missingDoc);
          setCurrentTemplate(current);
        } else {
          setCurrentTemplate(defaultTemplate);
        }
      }
      const filtre = [];
      journalList.forEach((j) => {
        if (!currentTemplate?.template?.remove_journals?.includes(j)) {
          filtre.push(j);
        }
      });
      setFilteredJournalList(filtre.length > 0 ? filtre : journalList);
      setExcludedJournals(currentTemplate?.template?.remove_journals || []);
    })();
  }, [props.wait, clientRemoteId, props.date, refresh, journalList]);

  useEffect(() => {
    setJournalListFiltered(filteredJournalList.filter((j) => !client.hiddenJournals?.includes(j)));
  }, [filteredJournalList, client.hiddenJournals]);

  useEffect(() => {
    if (missingDocData) {
      setFilteredOp(missingDocData.operationCategories);
    }
  }, [missingDocData]);

  useEffect(() => {
    setFilteredOp([...filteredOpEnc, ...filteredOpDec]);
  }, [filteredOpEnc, filteredOpDec]);

  useEffect(() => {
    setNbrMissingDocuments(
      nbrMissingDocEnc + nbrMissingDocDec + (client.otherMissingDocuments?.length || 0)
    );
    (async () => {
      await API.putClient(clientId, {
        missingDocNumber:
          nbrMissingDocEnc + nbrMissingDocDec + (client.otherMissingDocuments?.length || 0),
      });
    })();
  }, [nbrMissingDocEnc, nbrMissingDocDec, client.otherMissingDocuments]);

  useEffect(() => {
    setAmountMissingDoc(amountMissingDocEnc + amountMissingDocDec);
    (async () => {
      await API.putClient(clientId, {
        missingDocAmount: amountMissingDocEnc + amountMissingDocDec,
      });
    })();
  }, [amountMissingDocEnc, amountMissingDocDec]);

  function handleFinish(value) {
    dispatch(
      updateClient({
        operationCategories: client.operationCategories.map((cat) => ({
          ...cat,
          operations: cat.operations.map((op) => ({
            ...op,
            active: Math.abs(op.amount) < value.minAmountSelect ? false : op.active,
          })),
        })),
      })
    );
  }

  useEffect(() => {
    if (!client.filterStartDate) {
      setStartDate(
        dayjs().subtract(15, "month").set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      );
    } else {
      setStartDate(dayjs(client.filterStartDate));
    }

    if (missingDocData) {
      if (client.journalList?.length > 0) {
        setJournalList(client.journalList.map((s) => s.trim()));
      } else {
        setJournalList(missingDocData.journals.missing_documents?.map((s) => s.trim()));
      }
    }
  }, [client.filterStartDate, client.journalList, missingDocData]);

  useEffect(() => {
    props.amount(number.parseNum(amountMissingDoc));
    props.nbr(nbrMissingDocuments);
    props.filteredOp(filteredOp);
    props.others(client.otherMissingDocuments);
    props.setBadgeMissing(nbrMissingDocuments);
    props.setMissing(nbrMissingDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOp, amountMissingDoc, nbrMissingDocuments, client.otherMissingDocuments]);

  const onFinish = (values) => {
    let allJournals = journalList;
    values.journalList.forEach((j) => {
      if (!allJournals.includes(j)) {
        allJournals.push(j);
      }
    });

    setStartDate(dayjs(values.startDate));
    setEndDate(values.endDate);

    setJournalList(values.journalList);

    (async () => {
      await API.putClient(clientId, {
        filterStartDate: values.startDate,
        journalList: allJournals,
        hiddenJournals: allJournals.filter((j) => !values.journalList.includes(j)),
        showZeroAccounts: displayAccount,
      });
      dispatch(
        updateClient({
          filterStartDate: values.startDate,
          journalList: allJournals,
          hiddenJournals: allJournals.filter((j) => !values.journalList.includes(j)),
          showZeroAccounts: displayAccount,
        })
      );

      setChangeDate(false);
      if (allJournals.length > client.journalList.length) {
        setRefresh(true);
      }

      logAction(227, 1, clientId);
    })();
  };

  const resetJournals = () => {
    setJournalList(missingDocData.journals.missing_documents?.map((s) => s.trim()));
    (async () => {
      const res = await API.putClient(clientId, {
        journalList: missingDocData.journals.missing_documents?.map((s) => s.trim()),
        hiddenJournals: [],
      });
      if (res.status === 201) {
        dispatch(
          updateClient({
            journalList: missingDocData.journals.missing_documents?.map((s) => s.trim()),
            hiddenJournals: [],
          })
        );

        setChangeDate(false);
        setRefresh(true);
      }
    })();
  };

  const showMissingDocDrawer = () => {
    setShowMissingDocMail(true);
  };

  const onMissingDocDrawerClose = () => {
    setShowMissingDocMail(false);
  };

  const selectAll = (state) => {
    dispatch(
      updateClient({
        operationCategories: client.operationCategories.map((cat) => ({
          ...cat,
          operations: cat.operations.map((op) => ({ ...op, active: state })),
        })),
      })
    );
  };

  useEffect(() => {
    if (props.selectedMailType === "") {
      ref.current = 0;
      props.onRefUpdate(ref);
    }
  }, [props.selectedMailType]);

  return (
    <>
      {error ? (
        <Result
          icon={<FrownOutlined style={{ color: "#4569f8" }} />}
          title="Nous ne parvenons pas à recupérer les pièces manquantes"
        />
      ) : !missingDocData || !filteredOp ? (
        <Skeleton
          className="skeleton-analysis"
          active={true}
          paragraph={{
            rows: 4,
          }}
        />
      ) : (
        <>
          {startDate ? (
            <>
              <>
                {!editMode ? (
                  missingDocData ? (
                    <>
                      <Affix offsetTop={120} className="btn-fix-missingdoc">
                        <div
                          className="site-drawer-render-missingdoc"
                          onMouseDown={onMissingDocDrawerClose}
                        >
                          <Button
                            type="primary"
                            onMouseEnter={showMissingDocDrawer}
                            disabled={nbrMissingDocuments === 0}
                            className="select-indic-btn"
                          >
                            <MailOutlined style={{ fontSize: "22px" }} />
                          </Button>
                          <Drawer
                            title={
                              <div
                                onMouseLeave={onMissingDocDrawerClose}
                                onClick={(e) => {
                                  e.preventDefault();
                                  ref.current += 1;
                                  props.onRefUpdate(ref);
                                  props.setSelectedMailType("missingdoc");
                                }}
                              >
                                Demander les justificatifs au client
                              </div>
                            }
                            rootClassName="indic-drawer missing-doc-drawer"
                            placement="right"
                            closable={false}
                            open={showMissingDocMail}
                            getContainer={false}
                            rootStyle={{ position: "absolute" }}
                            width={300}
                            mask={false}
                          ></Drawer>
                        </div>
                      </Affix>
                      {client.email?.missingdoc?.lastEmailSent ? (
                        <span className="mail-date-missingdoc">
                          Dernier envoi {getMailDate(client.email.missingdoc.lastEmailSent)}
                        </span>
                      ) : null}
                    </>
                  ) : null
                ) : null}
              </>

              <div className="missing-doc-container">
                <div className="circle circle-missing-doc">
                  <Progress
                    type="circle"
                    percent={100}
                    size={80}
                    strokeColor="#FF9700"
                    format={() => <span className="circular-text">{nbrMissingDocuments}</span>}
                  />
                  <div className="circle-block">
                    <span className="total-amount">
                      {number.withThousandSeparator(number.parseNum(amountMissingDoc))}
                    </span>
                    <span className="total-amount-text">
                      non {filteredOp.length === 0 ? "justifié " : "justifiés "}
                      {/*{localStorage.getItem("fec") === "false"
            ? " au " + dayjs().format("D MMMM YYYY")
            : props.client.updateDate
            ? " au " + dayjs(props.client.updateDate).format("D MMMM YYYY")
        : " à la dernière actualisation des données comptables."}*/}
                      entre le{" " + dayjs(startDate).format("DD/MM/YYYY")} et le
                      {" " + dayjs(endDate).format("DD/MM/YYYY")}
                    </span>
                    <br />
                    <Button
                      type="link"
                      className={changeDate ? "change-period-active" : "change-period"}
                      onClick={(e) => {
                        e.preventDefault();
                        setChangeDate(!changeDate);
                      }}
                    >
                      Paramétrer la période d'analyse et les journaux
                    </Button>
                  </div>
                </div>

                <Modal
                  title="Paramétrer la période d'analyse et les journaux"
                  className="edit-user-modal"
                  open={changeDate}
                  onCancel={(e) => {
                    e.preventDefault();
                    setChangeDate(false);
                  }}
                  footer={null}
                  style={{ top: "12%" }}
                  // forceRender
                >
                  <Form
                    layout="vertical"
                    name="datepicker"
                    onFinish={onFinish}
                    initialValues={{
                      startDate: startDate,
                      endDate: endDate,
                      journalList: filteredJournalList.filter(
                        (j) => !client.hiddenJournals?.includes(j)
                      ),
                      showZeroAccounts: displayAccount,
                    }}
                  >
                    <Form.Item name="startDate" label="Date de début :" className="form-datepicker">
                      <DatePicker
                        locale={locale}
                        allowClear={false}
                        className="import-input"
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                    <Form.Item name="endDate" label="Date de fin :" className="form-datepicker">
                      <DatePicker
                        locale={locale}
                        allowClear={false}
                        className="import-input"
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                    <Form.Item
                      name="journalList"
                      label="Retirer ou ajouter les journaux à prendre en compte : "
                    >
                      <Select
                        mode="tags"
                        /*className="minimum-amount-button"
                  dropdownClassName="ant-select-dropdown-missing-doc"*/
                        placement="topLeft"
                        dropdownAlign={{ overflow: { adjustY: false } }}
                      >
                        {filteredJournalList?.map((journal, i) => (
                          <Option key={i} value={journal}>
                            {journal}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {excludedJournals?.length > 0 && currentTemplate?.name !== undefined ? (
                      <span
                        style={{
                          position: "relative",
                          top: "-20px",
                          fontSize: "0.9em",
                          fontStyle: "italic",
                          color: "grey",
                        }}
                      >
                        <p style={{ margin: "0px" }}>
                          Le modèle {'"'}
                          {currentTemplate?.name}
                          {'" '}
                          {excludedJournals?.length > 1
                            ? "exclut les journaux : "
                            : "exclut le journal : "}
                          {excludedJournals?.join(", ")}
                          {"."}
                        </p>
                      </span>
                    ) : null}
                    <Form.Item
                      name="showZeroAccounts"
                      valuePropName="checked"
                      style={{ position: "relative", top: "-16px", marginBottom: "0" }}
                    >
                      <Checkbox
                        className="balancedAccountCheckbox"
                        onChange={() => setDisplayAccount(!displayAccount)}
                        disabled={locker}
                      >
                        Afficher les comptes soldés
                      </Checkbox>
                    </Form.Item>
                    {displayAccount && locker ? (
                      <div className="blue-box">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <InfoCircleFilled style={{ color: "var(--blue)", fontSize: "1.2em" }} />
                          <span style={{ marginLeft: "8px", fontWeight: "lighter" }}>
                            Des pièces ont été déposées pour les comptes soldés. Téléchargez les
                            pièces et rafraichissez la page pour en désactiver l’affichage.
                          </span>
                        </div>
                      </div>
                    ) : null}

                    <Popconfirm
                      title="Voulez-vous réinitialiser la liste des journaux ?"
                      onConfirm={resetJournals}
                      okText="Oui"
                      cancelText="Non"
                    >
                      {" "}
                      <Button className=" other-action-btn init-journals-btn">
                        Réinitialiser la liste des journaux (détection automatique)
                      </Button>
                    </Popconfirm>

                    <Form.Item>
                      <Button
                        type="submit"
                        htmlType="submit"
                        className="call-action-btn link-button-fec submit-date"
                      >
                        Valider
                      </Button>
                    </Form.Item>
                  </Form>
                </Modal>

                {editMode ? (
                  <Button
                    className="tab-action-btn-edit-active call-action-btn"
                    type="submit"
                    shape="round"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditMode(false);
                      (async () => {
                        // let numEditsDec = 0;
                        // let numEditsEnc = 0;
                        // const oldClient = await API.getClient(clientId).then((res) => {
                        //   return res.json();
                        // });

                        // for (const ops of filteredOp) {
                        //   if(ops.typeText === "Décaissements à justifier") {
                        //     ops.filter((op) => )
                        //   }
                        //   console.log("test");
                        // }

                        API.putClient(clientId, {
                          uncheckedAccounts: client.uncheckedAccounts,
                        });

                        let res = await API.postCheckedOperations(clientId, {
                          uncheckedOperations: filteredOp
                            .map(({ operations }) =>
                              operations
                                .filter(({ active }) => active === false)
                                .map(({ _id }) => _id)
                            )
                            .flat(1),
                        });
                        if (res.status === 200) {
                          res = await res.json();
                        } else {
                          console.log("error");
                        }
                      })();
                    }}
                  >
                    Valider
                  </Button>
                ) : (
                  <Button
                    className={
                      client.email?.missingdoc?.lastEmailSent
                        ? "other-action-btn tab-action-btn-edit-date"
                        : "other-action-btn tab-action-btn-edit"
                    }
                    type="submit"
                    // à remettre en place
                    // disabled={
                    //   input.operationCategories &&
                    //   input.operationCategories.length === 0 &&
                    //   nbrMissing === 0
                    //     ? true
                    //     : false
                    // }
                    onClick={(e) => {
                      e.preventDefault();
                      setEditMode(true);
                    }}
                  >
                    Modifier la liste
                  </Button>
                )}

                {editMode ? (
                  <div className="minimum-amount">
                    <Form
                      name="basic"
                      layout="inline"
                      initialValues={{
                        remember: true,
                        minAmountSelect: "1",
                      }}
                      onFinish={handleFinish}
                    >
                      <Form.Item
                        name="minAmountSelect"
                        label="Décocher les montants inférieurs à : "
                      >
                        <InputNumber
                          min={1}
                          className="input-minimum-amount-button"
                          stringMode="true"
                          style={{ width: "80px" }}
                        />
                      </Form.Item>
                      <span style={{ position: "relative", right: "35px", top: "5px" }}>€</span>
                      <Form.Item className="submit-min-amount-item">
                        <Button
                          style={{ height: 30 }}
                          shape="round"
                          htmlType="submit"
                          className="submit-min-amount-btn"
                        >
                          Appliquer
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                ) : null}

                <Others {...props} />

                {nbrMissingDocEnc + nbrMissingDocDec > 0 ? (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setAllCollapse(!allCollapse);
                    }}
                    className="other-action-btn allCollapse-btn"
                  >
                    {allCollapse ? "Tout replier" : "Tout déplier"}
                  </Button>
                ) : null}

                <div className="select-all">
                  {editMode ? (
                    <div className="switch-select">
                      <a
                        href="# "
                        onClick={(e) => {
                          e.preventDefault();
                          selectAll(false);
                        }}
                      >
                        Tout déselectionner
                      </a>
                      <span> / </span>
                      <a
                        href="# "
                        onClick={(e) => {
                          e.preventDefault();
                          selectAll(true);
                        }}
                      >
                        Tout sélectionner
                      </a>
                    </div>
                  ) : null}
                </div>

                <Type
                  {...props}
                  typeId="1"
                  typeText="Décaissements à justifier"
                  filtered={setFilteredOpDec}
                  amountMissingDoc={setAmountMissingDocDec}
                  nbrMissingDoc={setNbrMissingDocDec}
                  journalListFiltered={journalListFiltered}
                  startDate={startDate}
                  endDate={endDate}
                  allCollapse={allCollapse}
                  editMode={editMode}
                />
                <Type
                  {...props}
                  typeId="2"
                  typeText="Encaissements à justifier"
                  filtered={setFilteredOpEnc}
                  amountMissingDoc={setAmountMissingDocEnc}
                  nbrMissingDoc={setNbrMissingDocEnc}
                  journalListFiltered={journalListFiltered}
                  startDate={startDate}
                  endDate={endDate}
                  allCollapse={allCollapse}
                  editMode={editMode}
                />

                <Unchecked
                  {...props}
                  journalListFiltered={journalListFiltered}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
}

export default MissingDocuments;
