import React, { useState, useEffect } from "react";
import * as API from "../../../../api/API";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "../../../../slices/accountingFirmSlice";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Select, Col, Row, InputNumber, Tooltip, Tag } from "antd";
import { DeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import columnList from "../columnList";
import criteriaList from "../criteriaList";

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

const tagRender = (props, originalColors) => {
  const { label } = props;

  return (
    <Tag
      style={{
        marginRight: 3,
        backgroundColor: originalColors.light,
        color: originalColors.dark,
        border: "none",
        borderRadius: "16px",
      }}
    >
      {label}
    </Tag>
  );
};

const CreateTableItem = (props) => {
  const accountingFirm = useSelector(selectAccountingFirm);

  const { id, originalColors, dragOverlay } = props;
  const [deleteBtn, setDeleteBtn] = useState({
    display: "none",
  });
  const [itemStyle, setItemStyle] = useState({});
  const [operator, setOperator] = useState(props.criteria?.operator || "none");
  const [criteriaValue, setCriteriaValue] = useState(props.criteria?.value);
  const [isVisible, setIsVisible] = useState(
    props.isVisible !== undefined ? props.isVisible : true
  );
  const [apeCodes, setApeCodes] = useState([]);
  const [users, setUsers] = useState([]);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: dragOverlay ? "grabbing" : "grab",
  };

  const handleOpChange = (value) => {
    setOperator(value);

    // key points
    if (id === "keyPoints" && value === "none")
      props.setCriteria({ keyPointsListExclusive: null, keyPointsListInclusive: null });
    else if (id === "keyPoints" && value === "and") {
      props.setCriteria({
        keyPointsListInclusive: {
          operator: value,
          value: criteriaValue.map((el) => parseInt(el)),
        },
        keyPointsListExclusive: null,
      });
    } else if (id === "keyPoints" && value === "or") {
      props.setCriteria({
        keyPointsListExclusive: {
          operator: value,
          value: criteriaValue.map((el) => parseInt(el)),
        },
        keyPointsListInclusive: null,
      });
    }

    // collab
    else if (id === "collaboratorsId" && value === "none") {
      props.setCriteria({
        collaboratorsExclusive: null,
        collaboratorsInclusive: null,
      });
    } else if (id === "collaboratorsId" && value === "and") {
      props.setCriteria({
        collaboratorsInclusive: {
          operator: value,
          value: criteriaValue,
        },
        collaboratorsExclusive: null,
      });
    } else if (id === "collaboratorsId" && value === "or") {
      props.setCriteria({
        collaboratorsExclusive: {
          operator: value,
          value: criteriaValue,
        },
        collaboratorsInclusive: null,
      });
    }

    // others
    else if (criteriaValue !== undefined) {
      if (value === "none") props.setCriteria({ [id]: null });
      else
        props.setCriteria({
          [id]: {
            operator: value,
            value: criteriaValue,
          },
        });
    }
  };

  const handleValChange = (value) => {
    setCriteriaValue(value);

    // key points
    if (id === "keyPoints" && operator === "and") {
      props.setCriteria({
        keyPointsListInclusive: {
          operator,
          value: value.map((el) => parseInt(el)),
        },
        keyPointsListExclusive: null,
      });
    } else if (id === "keyPoints" && operator === "or") {
      props.setCriteria({
        keyPointsListExclusive: {
          operator,
          value: value.map((el) => parseInt(el)),
        },
        keyPointsListInclusive: null,
      });
    }

    // collab
    else if (id === "collaboratorsId" && operator === "and") {
      props.setCriteria({
        collaboratorsInclusive: {
          operator,
          value: value,
        },
        collaboratorsExclusive: null,
      });
    } else if (id === "collaboratorsId" && operator === "or") {
      props.setCriteria({
        collaboratorsExclusive: {
          operator,
          value: value,
        },
        collaboratorsInclusive: null,
      });
    }

    // fiscal month
    else if (id === "fiscalEndMonth") {
      props.setCriteria({
        fiscalEndMonthExclusive: {
          value: value,
        },
      });
    }

    // others
    else if (operator !== "none") {
      props.setCriteria({
        [id]: {
          operator,
          value: value,
        },
      });
    }
  };

  useEffect(() => {
    props.column({
      name: id,
      show: isVisible,
    });
  }, [isVisible]);

  useEffect(() => {
    (async () => {
      let resAPE = await API.getAPECodes();
      resAPE = await resAPE.json();
      setApeCodes(resAPE);

      let resUsers = await API.getUsersByAccountingFirmId(accountingFirm._id);
      resUsers = await resUsers.json();
      setUsers(resUsers);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <Row
        className="criteria-line"
        style={itemStyle}
        onMouseEnter={(e) => {
          setDeleteBtn({
            display: "block",
            backgroundColor: originalColors.light,
            border: `2px solid ${originalColors.dark} !important`,
          });
          setItemStyle({
            backgroundColor: originalColors.light,
          });
        }}
        onMouseLeave={(e) => {
          setDeleteBtn({
            display: "none",
            backgroundColor: originalColors.light,
            border: `2px solid ${originalColors.dark} !important`,
          });
          setItemStyle({
            backgroundColor: "transparent",
          });
        }}
      >
        <Col span={1}>
          <Tooltip placement="top" title={isVisible ? "Masquer la colonne" : "Afficher la colonne"}>
            <Button
              className="drag-line-btn"
              shape="circle"
              onClick={(e) => {
                e.preventDefault();
                setIsVisible(!isVisible);
              }}
              icon={
                isVisible ? (
                  <EyeOutlined style={{ color: originalColors.dark, fontSize: "16px" }} />
                ) : (
                  <EyeInvisibleOutlined style={{ color: originalColors.dark, fontSize: "16px" }} />
                )
              }
              style={deleteBtn}
            />
          </Tooltip>
        </Col>
        <Col span={7}>
          <Tooltip
            placement="top"
            title={
              columnList.find((elt) => elt.label === id).name.length > 40
                ? columnList.find((elt) => elt.label === id).name
                : null
            }
          >
            <div
              className="column-name"
              style={{
                border: `1px solid ${originalColors.dark}`,
                color: originalColors.dark,
              }}
            >
              <span>{truncateString(columnList.find((elt) => elt.label === id).name, 40)}</span>
            </div>
          </Tooltip>
        </Col>
        <Col span={5} offset={1}>
          <div className="criteria-op">
            {[
              "apeCode",
              "fiscalEndMonth",
              "analysisTemplate",
              "missingDocTemplate",
              "outstandingsClientsTemplate",
              "outstandingsProvidersTemplate",
            ].includes(id) ? (
              <Select
                placeholder="Aucun filtre"
                onChange={handleOpChange}
                onClick={(e) => e.stopPropagation()}
                value={operator}
                options={[
                  {
                    value: "or",
                    label: "Est l'un des",
                  },
                  {
                    value: "none",
                    label: "Aucun filtre",
                  },
                ]}
                placement="bottomLeft"
              />
            ) : id === "collaboratorsId" || id === "keyPoints" ? (
              <Select
                placeholder="Aucun filtre"
                onChange={handleOpChange}
                onClick={(e) => e.stopPropagation()}
                value={operator}
                options={[
                  {
                    value: "and",
                    label: "Et",
                  },
                  {
                    value: "or",
                    label: "Ou",
                  },
                  {
                    value: "none",
                    label: "Aucun filtre",
                  },
                ]}
                placement="bottomLeft"
              />
            ) : (
              <Select
                placeholder="Aucun filtre"
                onChange={handleOpChange}
                onClick={(e) => e.stopPropagation()}
                value={operator}
                disabled={id.includes("Template") ? true : false}
                options={[
                  {
                    value: "gte",
                    label: "Est supérieur ou égal à",
                  },
                  {
                    value: "lte",
                    label: "Est inférieur ou égal à",
                  },
                  {
                    value: "eq",
                    label: "Est égal à",
                  },
                  {
                    value: "none",
                    label: "Aucun filtre",
                  },
                ]}
                placement="bottomLeft"
              />
            )}
          </div>
        </Col>
        <Col span={8} offset={1}>
          <div className="criteria-value">
            {id === "apeCode" ? (
              <Select
                className="criteria-value-input"
                mode="multiple"
                showSearch
                maxTagCount="responsive"
                placeholder="Sélectionner un code APE"
                optionFilterProp="children"
                defaultValue={criteriaValue || []}
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
                options={apeCodes.map((code) => ({ value: code.code, label: code.codeLabel }))}
                tagRender={(props) => tagRender(props, originalColors)}
                maxTagPlaceholder={(omittedValues) => {
                  return (
                    <Tag
                      style={{
                        marginRight: 3,
                        backgroundColor: originalColors.light,
                        color: originalColors.dark,
                        border: "none",
                        borderRadius: "16px",
                      }}
                    >
                      + {omittedValues.length}
                    </Tag>
                  );
                }}
                onChange={handleValChange}
                onClick={(e) => e.stopPropagation()}
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
              />
            ) : [
                "analysisTemplate",
                "missingDocTemplate",
                "outstandingsClientsTemplate",
                "outstandingsProvidersTemplate",
              ].includes(id) ? (
              <Select
                className="criteria-value-input"
                mode="multiple"
                showSearch
                maxTagCount="responsive"
                placeholder="Sélectionner un modèle"
                optionFilterProp="children"
                defaultValue={criteriaValue || []}
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
                options={[{ value: "sesha", label: "Sesha" }].concat(
                  props.templates
                    .filter((el) => {
                      switch (id) {
                        case "analysisTemplate":
                          return el.type === "ANALYSIS";
                        case "missingDocTemplate":
                          return el.type === "MISSING_DOC";
                        case "outstandingsClientsTemplate":
                          return el.type === "OUTSTANDINGS_CLIENTS";
                        case "outstandingsProvidersTemplate":
                          return el.type === "OUTSTANDINGS_PROVIDERS";
                        default:
                          return false;
                      }
                    })
                    .map((el) => ({ value: el._id, label: el.name }))
                )}
                tagRender={(props) => tagRender(props, originalColors)}
                maxTagPlaceholder={(omittedValues) => {
                  return (
                    <Tag
                      style={{
                        marginRight: 3,
                        backgroundColor: originalColors.light,
                        color: originalColors.dark,
                        border: "none",
                        borderRadius: "16px",
                      }}
                    >
                      + {omittedValues.length}
                    </Tag>
                  );
                }}
                onChange={handleValChange}
                onClick={(e) => e.stopPropagation()}
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
              />
            ) : id === "collaboratorsId" ? (
              <Select
                className="criteria-value-input"
                mode="multiple"
                showSearch
                disabled={operator === "none" ? true : false}
                placeholder="Sélectionner un ou plusieurs collaborateurs"
                maxTagCount="responsive"
                tagRender={(props) => tagRender(props, originalColors)}
                defaultValue={criteriaValue || []}
                maxTagPlaceholder={(omittedValues) => {
                  return (
                    <Tag
                      style={{
                        marginRight: 3,
                        backgroundColor: originalColors.light,
                        color: originalColors.dark,
                        border: "none",
                        borderRadius: "16px",
                      }}
                    >
                      + {omittedValues.length}
                    </Tag>
                  );
                }}
                onChange={handleValChange}
                onClick={(e) => e.stopPropagation()}
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                options={users.map((user) => ({
                  value: user._id,
                  label: user.firstName + " " + user.lastName,
                }))}
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
              />
            ) : id === "fiscalEndMonth" ? (
              <Select
                className="criteria-value-input"
                mode="multiple"
                showSearch
                placeholder="Sélectionner un ou plusieurs mois"
                maxTagCount="responsive"
                tagRender={(props) => tagRender(props, originalColors)}
                defaultValue={criteriaValue}
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                options={Object.entries(
                  criteriaList.find((elt) => elt.value === "fiscalEndMonthExclusive").list
                ).map(([key, value]) => ({
                  value: key,
                  label: value,
                }))}
                maxTagPlaceholder={(omittedValues) => {
                  return (
                    <Tag
                      style={{
                        marginRight: 3,
                        backgroundColor: originalColors.light,
                        color: originalColors.dark,
                        border: "none",
                        borderRadius: "16px",
                      }}
                    >
                      + {omittedValues.length}
                      {/*omittedValues.length > 1
                        ? " points d'attention sélectionnés"
                    : " point d'attention sélectionné"*/}
                    </Tag>
                  );
                }}
                onChange={handleValChange}
                onClick={(e) => e.stopPropagation()}
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
              />
            ) : id === "keyPoints" ? (
              <Select
                className="criteria-value-input"
                mode="multiple"
                showSearch
                disabled={operator === "none" ? true : false}
                placeholder="Sélectionner un ou plusieurs points d'attention"
                maxTagCount="responsive"
                tagRender={(props) => tagRender(props, originalColors)}
                defaultValue={criteriaValue?.map((el) => el.toString()) || []}
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                options={Object.entries(
                  criteriaList.find((elt) => elt.value === "keyPointsListInclusive").list
                ).map(([key, value]) => ({
                  value: key,
                  label: value,
                }))}
                maxTagPlaceholder={(omittedValues) => {
                  return (
                    <Tag
                      style={{
                        marginRight: 3,
                        backgroundColor: originalColors.light,
                        color: originalColors.dark,
                        border: "none",
                        borderRadius: "16px",
                      }}
                    >
                      + {omittedValues.length}
                      {/*omittedValues.length > 1
                        ? " points d'attention sélectionnés"
                    : " point d'attention sélectionné"*/}
                    </Tag>
                  );
                }}
                onChange={handleValChange}
                onClick={(e) => e.stopPropagation()}
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
              />
            ) : (
              <InputNumber
                disabled={id.includes("Template") || operator === "none" ? true : false}
                className="criteria-value-input"
                value={criteriaValue}
                placeholder={criteriaList.find((elt) => elt.value === id).placeholder}
                onChange={handleValChange}
                onClick={(e) => e.stopPropagation()}
              />
            )}
          </div>
        </Col>
        <Col span={1}>
          <Tooltip placement="top" title="Supprimer">
            <Button
              shape="circle"
              icon={<DeleteOutlined style={{ color: originalColors.dark, fontSize: "16px" }} />}
              style={deleteBtn}
              className="delete-item-btn"
              onClick={(e) => {
                e.preventDefault();
                props.deleteColumn(id);
              }}
            />
          </Tooltip>
        </Col>
      </Row>
    </li>
  );
};

export default CreateTableItem;
