import {
  CloseCircleOutlined,
  DeleteOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Alert, Badge, Button, Col, Input, Row, Space, Switch, Table, Tag, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import hasFeature from "utils/dataProviderFeatures";
import logAction from "utils/logActions";
import * as API from "../../api/API";
import { ReactComponent as FilterIcon } from "../../assets/images/filterIcon.svg";
import { selectAccountingFirm, updateAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectUser } from "../../slices/userSlice";
import LoaderRaw from "../../utils/LoaderRaw";
import { getMailDate } from "../../utils/getDate";
import openNotification from "../../utils/notification";
import AccessibleClientDocs from "./AccessibleDocs";
import CreateUser from "./CreateUser.js";
import ExcludeSettings from "./ExcludeSettings";
import RenderCell from "./RenderCell";

function Admin(props) {
  const dispatch = useDispatch();
  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);

  const [userList, setUserList] = useState([]);
  const [userRightsList, setUserRightsList] = useState([]);
  const [importedClients, setImportedClients] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [showEditCell, setShowEditCell] = useState({});
  const [editedCell, setEditedCell] = useState({});
  const [usersToDelete, setUsersToDelete] = useState([]);
  const [userListUpdate, setUserListUpdate] = useState(false);
  const [spinUpdate, setSpinUpdate] = useState(false);
  const [roleList, setRoleList] = useState([]);

  /* search */
  const [, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const res = await API.getRoles();
      const roles = await res.json();
      setRoleList(roles);
    }
    fetchData();
  }, [props.updateAdmin]);

  useEffect(() => {
    (async () => {
      if (accountingFirm.status === "ready") {
        let resUsers, resRemoteId, resRights;

        try {
          [resUsers, resRemoteId, resRights] = await Promise.all([
            API.getUsersByAccountingFirmId(accountingFirm._id).then((res) => res.json()),
            API.getClientsRemoteId(accountingFirm._id).then((res) => res.json()),
            accountingFirm.connectedRights
              ? API.getDataUserRights().then((res) => res.json())
              : null,
          ]);

          setImportedClients(resRemoteId);

          if (accountingFirm.connectedRights) {
            if (resRights) {
              setUserRightsList(resRights);

              resUsers = resUsers.map((user, i) => {
                user.key = i;
                const matchingRights = resRights.filter((right) => right.user_mail === user.email);
                if (matchingRights.length > 0) {
                  user.user_id = matchingRights.map(({ user_id }) => user_id);
                  user.dossiers = matchingRights.map(({ dossiers }) => dossiers).flat(1);
                }
                return user;
              });
            } else {
              onChangeConnectedRights(false);
              openNotification("error", "Erreur lors de la récupération automatique des droits");
            }
          } else {
            const resClients = await API.getClientsByAccountingFirmId(accountingFirm._id);
            setClientList(await resClients.json());
          }

          setUserList(resUsers.map((el) => ({ ...el, key: el._id })));
          setUserListUpdate(false);
          setSpinUpdate(false);
        } catch (error) {
          console.error("Une erreur s'est produite lors de la récupération des données: ", error);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListUpdate === true, accountingFirm, props.updateAdmin]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={dataIndex === "lastName" ? "Nom du collaborateur" : "Mail du collaborateur"}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 120 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
            Réinitialiser
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <FilterIcon style={{ color: filtered ? "var(--blue)" : "white" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const onChangeConnectedRights = (checked) => {
    checked ? logAction(200, 1) : logAction(201, 1);
    API.putAccountingFirm(accountingFirm._id, { connectedRights: checked });
    dispatch(
      updateAccountingFirm({
        ...accountingFirm,
        connectedRights: checked,
      })
    );
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      ...getColumnSearchProps("lastName"),
      onCell: (record, rowIndex) => {
        return {
          onMouseEnter: (event) => {
            setShowEditCell({ type: "lastName", user_id: record._id });
          },
          onMouseLeave: (event) => {
            setShowEditCell({});
          },
          onClick: (event) => {
            setEditedCell({ type: "lastName", user_id: record._id });
          },
        };
      },
      render: (text, record) => {
        return (
          <RenderCell
            {...props}
            text={text}
            display={text}
            record={record}
            type={"lastName"}
            showEditCell={showEditCell}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            userRightsList={userRightsList}
            setUserListUpdate={setUserListUpdate}
          />
        );
      },
    },
    {
      title: "Prénom",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      ...getColumnSearchProps("firstName"),
      onCell: (record, rowIndex) => {
        return {
          onMouseEnter: (event) => {
            setShowEditCell({ type: "firstName", user_id: record._id });
          },
          onMouseLeave: (event) => {
            setShowEditCell({});
          },
          onClick: (event) => {
            setEditedCell({ type: "firstName", user_id: record._id });
          },
        };
      },
      render: (text, record) => {
        return (
          <RenderCell
            {...props}
            text={text}
            display={text}
            record={record}
            type={"firstName"}
            showEditCell={showEditCell}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            userRightsList={userRightsList}
            setUserListUpdate={setUserListUpdate}
          />
        );
      },
    },
    {
      title: "Droits associés",
      dataIndex: "newRole",
      key: "newRole",
      filters: roleList.map((role) => ({ text: role.label, value: role._id })),
      filterIcon: (filtered) => (
        <FilterIcon style={{ color: filtered ? "var(--blue)" : "white" }} />
      ),
      sorter: (a, b) => a.newRole.localeCompare(b.newRole),
      onFilter: (value, record) => record.newRole.indexOf(value) === 0,
      onCell: (record, rowIndex) => {
        return {
          onMouseEnter: (event) => {
            setShowEditCell({ type: "newRole", user_id: record._id });
          },
          onMouseLeave: (event) => {
            setShowEditCell({});
          },
          onClick: (event) => {
            setEditedCell({ type: "newRole", user_id: record._id });
          },
        };
      },
      render: (text, record) => {
        return (
          <RenderCell
            {...props}
            text={text}
            display={record.newRoleLabel}
            record={record}
            type={"newRole"}
            showEditCell={showEditCell}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            userRightsList={userRightsList}
            setUserListUpdate={setUserListUpdate}
            roleList={roleList}
          />
        );
      },
    },
    {
      title: "Adresse email",
      dataIndex: "email",
      key: "email",
      width: "250px",
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...getColumnSearchProps("email"),
      onCell: (record, rowIndex) => {
        return {
          onMouseEnter: (event) => {
            setShowEditCell({ type: "email", user_id: record._id });
          },
          onMouseLeave: (event) => {
            setShowEditCell({});
          },
          onClick: (event) => {
            setEditedCell({ type: "email", user_id: record._id });
          },
        };
      },
      render: (text, record) => {
        return (
          <RenderCell
            {...props}
            text={text}
            display={
              !accountingFirm.connectedRights ? (
                text
              ) : record.user_id ? (
                <>
                  <Tag
                    title={
                      record.user_id.length > 1
                        ? "Identifiants correspondants"
                        : "Identifiant correspondant"
                    }
                    color={"green"}
                  >
                    {record.user_id.join(", ")}
                  </Tag>
                  {text}
                </>
              ) : (
                <>
                  <CloseCircleOutlined
                    title="Identifiant non retrouvable automatiquement"
                    style={{ color: "#c32028" }}
                  />{" "}
                  {text}
                </>
              )
            }
            record={record}
            type={"email"}
            showEditCell={showEditCell}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            userRightsList={userRightsList}
            setUserListUpdate={setUserListUpdate}
          />
        );
      },
    },
    {
      title: "Ajouter des droits",
      dataIndex: "rights",
      key: "rights",
      hide: !accountingFirm.connectedRights,
      filters: userRightsList?.map((elt, i) => ({
        text: elt.user_id,
        value: elt.user_id,
      })),

      filterIcon: (filtered) => (
        <FilterIcon style={{ color: filtered ? "var(--blue)" : "white" }} />
      ),
      sorter: false,
      onFilter: (value, record) => record.rights.indexOf(value) === 0,
      onCell: (record, rowIndex) => {
        return {
          onMouseEnter: (event) => {
            setShowEditCell({ type: "rights", user_id: record._id });
          },
          onMouseLeave: (event) => {
            setShowEditCell({});
          },
          onClick: (event) => {
            event.preventDefault();
            setEditedCell({ type: "rights", user_id: record._id });
          },
        };
      },
      render: (text, record) => {
        return (
          <RenderCell
            {...props}
            text={text}
            display={text.join(", ")}
            record={record}
            type={"rights"}
            showEditCell={showEditCell}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            userRightsList={userRightsList}
            setUserListUpdate={setUserListUpdate}
          />
        );
      },
    },
    {
      title: accountingFirm.connectedRights ? "Dossiers accessibles" : "Dossiers attitrés",
      dataIndex: "dossiers",
      key: "dossiers",
      render: (text, record) => {
        let allDossiers = [];

        if (!accountingFirm.connectedRights) {
          allDossiers = clientList
            .filter((client) => client.collaboratorsId?.includes(record._id))
            .map(({ remoteId }) => remoteId);
        } else {
          allDossiers = userRightsList
            .filter(({ user_id }) => record.rights.includes(user_id))
            .map(({ dossiers }) => dossiers)
            .flat(1);

          if (text) allDossiers = allDossiers.concat(text);
        }
        return (
          <>
            {allDossiers.length > 0 ? (
              <AccessibleClientDocs
                {...props}
                importedClients={importedClients}
                allDocs={[...new Set(allDossiers)]}
                connectedRights={accountingFirm.connectedRights}
              />
            ) : null}
          </>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
      setUsersToDelete(selectedRows);
    },
  };

  const handleDelete = () => {
    if (
      window.confirm(
        `Voulez-vous supprimer les utilisateurs suivants :\r ${usersToDelete.map(
          (r) => r.firstName + " " + r.lastName
        )}?`
      )
    ) {
      usersToDelete.forEach((elt) => {
        (async () => {
          let resDeletedUser = await API.getUser(elt._id);
          resDeletedUser = await resDeletedUser.json();
          if (resDeletedUser.scheduledId) await API.deleteScheduleUser(resDeletedUser.scheduledId);

          const res = await API.deleteUser(elt._id);
          if (res.status === 200) setUserListUpdate(true);

          /* openNotification("success", "Utilisateur supprimé");
          } else openNotification("error", "Erreur lors de la suppression");*/
        })();
      });
    }
    setUsersToDelete([]);
  };

  return (
    <>
      <Row className="above-table" align="middle">
        {hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "CONNECTED_RIGHTS") ? (
          <Col span={8}>
            <div className="admin-head">
              <Switch checked={accountingFirm.connectedRights} onChange={onChangeConnectedRights} />
              <Badge count={"BETA"} color="cyan" size={"small"} style={{ top: "-8px" }}>
                <span className="admin-head-text">Récupération automatique des droits</span>
              </Badge>
            </div>
          </Col>
        ) : null}

        <Col span={4}>
          <div style={{ paddingLeft: "0px" }}>
            {usersToDelete.length > 0 ? (
              <Button
                className="other-action-btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
                icon={<DeleteOutlined />}
                disabled={usersToDelete.includes(user._id)}
              >
                Supprimer {usersToDelete.length} utilisateur{usersToDelete.length > 1 ? "s" : ""}
              </Button>
            ) : null}
          </div>
        </Col>
        <Col
          xs={{ offset: 0, span: 3 }}
          sm={{ offset: 1, span: 3 }}
          md={{ offset: 2, span: 3 }}
          lg={{ offset: 4, span: 3 }}
          xl={{ offset: 4, span: 3 }}
        >
          {accountingFirm.updateRightsDate ? (
            <span className="update-rights-date">
              Dernière mise à jour {getMailDate(accountingFirm.updateRightsDate)}
            </span>
          ) : null}
        </Col>
        <Col span={1}>
          {accountingFirm.connectedRights &&
          hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "CONNECTED_RIGHTS") ? (
            <>
              <Tooltip title="Mettre à jour les droits">
                <Button
                  className="other-action-btn update-rights-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setSpinUpdate(true);
                    (async () => {
                      const res = await API.updateUserRights();
                      if (res.status === 200) {
                        setUserListUpdate(true);
                        openNotification("success", "Mise à jour terminée");
                        dispatch(
                          updateAccountingFirm({
                            updateRightsDate: new Date(),
                          })
                        );
                      } else {
                        setSpinUpdate(false);
                        openNotification("error", "Erreur lors de la mise à jour");
                      }
                    })();
                  }}
                  icon={<SyncOutlined spin={spinUpdate} />}
                />
              </Tooltip>
            </>
          ) : null}
        </Col>

        <Col
          span={4}
          offset={
            !hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "CONNECTED_RIGHTS") ? 7 : 0
          }
        >
          <CreateUser
            {...props}
            updateUser={(data) => {
              setUserListUpdate(data);
            }}
            userRightsList={userRightsList}
            roleList={roleList}
          />
        </Col>

        {accountingFirm.connectedRights &&
        hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "CONNECTED_RIGHTS") &&
        accountingFirm.dataProvider === "FULLL" ? (
          <Col span={24}>
            <Alert
              className="modal-lost-alert alert-fulll-gdd"
              description={
                <span>
                  Dans le cadre de la connexion à <b>Fulll</b>, Sesha ne pourra récupérer les droits
                  d’un utilisateur qu’après que celui-ci ait connecté son compte Fulll à son compte
                  Sesha.
                  <br />
                  <b>
                    En cas d’ajout d’utilisateur dans Sesha, il est donc nécessaire que celui-ci se
                    connecte pour que le tableau ci-dessous se mette à jour.
                  </b>
                </span>
              }
              type="warning"
            />
          </Col>
        ) : null}
      </Row>

      <Table
        className="user-table"
        columns={columns.filter(({ hide }) => !hide)}
        dataSource={userList}
        pagination={{ size: "small", pageSizeOptions: [10, 20, 50, 100], showSizeChanger: true }}
        showSorterTooltip={false}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
          hideSelectAll: true,
        }}
        loading={{
          indicator: (
            <div style={{ marginTop: "40px" }}>
              <LoaderRaw />
            </div>
          ),
          spinning: userList.length === 0,
        }}
        locale={{ emptyText: " " }}
      />

      {!accountingFirm.connectedRights &&
      hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "CLIENT_IMPORT") ? (
        <ExcludeSettings {...props} />
      ) : null}
    </>
  );
}

export default Admin;
