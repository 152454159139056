import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { ShopOutlined } from "@ant-design/icons";
import jwt_decode from "jwt-decode";
import * as API from "../../api/API";

function NavClient(props) {
  const [clientName, setClientName] = useState("");
  const [logo, setLogo] = useState();
  const [loading, setLoading] = useState(true);

  const tokenClient = props.match.params.token;
  const idClient = jwt_decode(tokenClient).clientId;

  useEffect(() => {
    fetch(process.env.REACT_APP_INTERFACE_BACKEND + "/client/" + idClient, {
      method: "GET",
      headers: { Authorization: `Bearer ${tokenClient}` },
    })
      .then((res) => res.json())
      .then((response) => {
        setClientName(response.name);
      })
      .catch((error) => console.log(error));

    (async () => {
      let res = await API.getAccountingFirmLogo(jwt_decode(tokenClient).accountingFirmId);
      if (res.status === 200) {
        const data = await res.blob();
        setLogo(URL.createObjectURL(data));
        setLoading(false);
      } else {
        console.log("error");
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Row justify="center" className="row-nav-client">
      <Col xs={22} sm={22} md={22} lg={22} xl={22}>
        <Row
          className="header-row"
          align="middle"
          style={{ boxShadow: "0px 5px 30px " + props.color }}
        >
          {loading ? null : (
            <>
              <Col xs={6} sm={6} md={3} lg={3} xl={3}>
                <div id="svg-container">
                  {logo ? (
                    <img src={logo} alt="logo" style={{ maxWidth: "150px", maxHeight: "80px" }} />
                  ) : null}
                </div>
              </Col>
              <Col
                xs={{ span: 8, offset: 2 }}
                sm={{ span: 8, offset: 2 }}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 8, offset: 1 }}
                xl={{ span: 12, offset: 1 }}
              >
                <div className="name-client">
                  <div className="button-client-header">
                    <Button
                      style={{
                        pointerEvents: "none",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                      shape="circle"
                      default
                      icon={<ShopOutlined />}
                    />{" "}
                  </div>
                  <div className="client-name-header-table">{clientName}</div>
                </div>
              </Col>
              {props.isDisconnected ? null : (
                <Col
                  xs={{ span: 6, offset: 2 }}
                  sm={{ span: 6, offset: 2 }}
                  md={{ span: 5, offset: 6 }}
                  lg={{ span: 3, offset: 8 }}
                  xl={{ span: 3, offset: 4 }}
                >
                  <a href="# " className="disconnect" onClick={() => props.disconnected(true)}>
                    Quitter
                  </a>
                </Col>
              )}
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default NavClient;
