import { Column } from "@ant-design/plots";

const MoneyGraphComponentGroup = (props) => {
  var config = {
    height: 200,
    data: props.data,
    xField: "month",
    yField: "total",
    seriesField: "type",
    isGroup: true,
    color: ["#4569F8", "#D0DBE6"],
    legend: {
      selected: {
        Courant: true,
        Précédent: false,
      },
    },
  };
  return (
    <div className="money-chart-component">
      <p>{props.title}</p>
      <Column {...config} />
    </div>
  );
};

export default MoneyGraphComponentGroup;
