import React from "react";
import { Pie } from "@ant-design/plots";

const MoneyGraphComponentPie = (props) => {
  var config = {
    height: 200,
    appendPadding: 10,
    data: props.data,
    angleField: props.value,
    colorField: props.type,
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: 24,
        },
      },
    },
  };

  return (
    <div className="money-chart-component">
      <p>{props.title}</p>
      <Pie {...config} />
    </div>
  );
};
export default MoneyGraphComponentPie;
