import {
  ArrowRightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TooltipConnect from "utils/tooltipConnect";
import * as API from "../../../api/API";
import { selectAccountingFirm, updateAccountingFirm } from "../../../slices/accountingFirmSlice";
import openNotification from "../../../utils/notification";

const { confirm } = Modal;

function AdminConciliator() {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const [isConciliatorConnected, setIsConciliatorConnected] = useState();
  const [checkConciliatorConnexion, setCheckConciliatorConnexion] = useState();
  const [loading, setLoading] = useState(false);

  const updateAccountingFirmData = async (data) => {
    try {
      await API.putAccountingFirm(accountingFirm._id, data);
      openNotification("success", "Modifications enregistrées");
      dispatch(updateAccountingFirm(data));
    } catch (error) {
      openNotification("error", "Erreur lors de l'enregistrement");
    }
  };

  useEffect(() => {
    const testConciliatorConnection = async () => {
      const res = await API.testConciliatorConnection({
        token: accountingFirm.conciliatorToken,
      });

      if (res.statusCode === 0) {
        setCheckConciliatorConnexion(true);
      } else {
        setCheckConciliatorConnexion(false);
      }
      setLoading(false);
    };

    setLoading(true);
    if (accountingFirm.conciliatorToken) {
      setIsConciliatorConnected(true);
      testConciliatorConnection();
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isConciliatorConnected === false) {
      updateAccountingFirmData({ conciliatorToken: null });
      setCheckConciliatorConnexion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConciliatorConnected]);

  const onFinish = (values) => {
    console.log(values);
    (async () => {
      const res = await API.testConciliatorConnection({
        token: values.conciliatorToken,
      });
      console.log(res);
      if (res.statusCode === 0) {
        setCheckConciliatorConnexion(true);
        setIsConciliatorConnected(true);
        const data = {
          conciliatorToken: values.conciliatorToken,
        };
        updateAccountingFirmData(data);
      } else {
        setCheckConciliatorConnexion(false);
      }
    })();
  };

  const showConciliatorAlert = () => {
    confirm({
      title: "",
      icon: <ExclamationCircleOutlined className="prompt-icon" style={{ top: "-30px" }} />,
      className: "modal-invalid modal-invalid-mail",
      maskClosable: false,
      closable: false,
      content: (
        <div>
          <div className="prompt-text-style">
            <p>
              Voulez-vous vraiment désactiver la connexion à Conciliator ? Si vous continuez, le
              token sera supprimé.
            </p>
          </div>
          <div className="prompt-btns">
            <Button
              className="other-action-btn invalid-cancel"
              onClick={(e) => {
                e.preventDefault();
                Modal.destroyAll();
              }}
            >
              Annuler
            </Button>
            <Button
              className="call-action-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsConciliatorConnected(false);
                updateAccountingFirm({ conciliatorToken: null });
                Modal.destroyAll();
              }}
            >
              Continuer
            </Button>
          </div>
        </div>
      ),
    });
  };

  return (
    <>
      {loading ? (
        ""
      ) : (
        <Col className="idocus-co">
          <p className="settings-category-p mcf-co">Connexion à Conciliator : </p>
          <div className="how-to-connection-float">
            <TooltipConnect
              url={"https://knowledge.sesha.expert/knowledge/comment-connecter-sesha-%C3%A0-idocus"}
              text={"Comment connecter Sesha à Conciliator ?"}
            />
          </div>
          <br />
          <div className="mcf-switch">
            <Switch
              checked={isConciliatorConnected}
              onChange={() => {
                if (isConciliatorConnected) showConciliatorAlert();
                else setIsConciliatorConnected(!isConciliatorConnected);
              }}
            />{" "}
            <span>
              {isConciliatorConnected ? (
                checkConciliatorConnexion ? (
                  "Activée"
                ) : (
                  <b>Veuillez renseigner votre token Conciliator et cliquer sur "Valider"</b>
                )
              ) : (
                "Désactivée"
              )}
            </span>
          </div>

          {isConciliatorConnected ? (
            <>
              {checkConciliatorConnexion ? (
                <div className="mcf-valid-block">
                  <CheckCircleFilled className="mcf-valid-icon" />
                  <p className="mcf-valid-text">
                    <b>Félicitations, Sesha est connecté à Conciliator.</b>
                  </p>
                  <p>
                    Les pièces reçues de vos clients sont automatiquement chargées dans la zone de
                    dépôt correspondante.
                  </p>
                </div>
              ) : checkConciliatorConnexion === false ? (
                <div className="mcf-invalid-block">
                  <CloseCircleFilled className="mcf-invalid-icon" />
                  <p className="mcf-invalid-text">
                    <b>Token non reconnu.</b>
                  </p>
                  <p>Veuillez essayer à nouveau.</p>
                </div>
              ) : null}
              {isConciliatorConnected ? (
                <Form
                  onFinish={onFinish}
                  layout="vertical"
                  initialValues={{
                    conciliatorToken: accountingFirm.conciliatorToken,
                  }}
                  className="user-profile-form"
                >
                  <Form.Item name="conciliatorToken" label="Token Conciliator">
                    <Input bordered={true} className={"form-input"} />
                  </Form.Item>

                  <Form.Item className="submit-changes-btn" style={{ marginLeft: "0" }}>
                    <Button className="call-action-btn modal-submit-btn" htmlType="submit">
                      Valider <ArrowRightOutlined />
                    </Button>
                  </Form.Item>
                </Form>
              ) : null}
            </>
          ) : (
            <div>
              <p>La connexion à Conciliator est disponible pour votre cabinet.</p>
              <p>
                Une fois activée, les pièces reçues de vos clients seront automatiquement chargées
                dans la zone de dépôt correspondante.
              </p>
            </div>
          )}
        </Col>
      )}
    </>
  );
}
export default AdminConciliator;
