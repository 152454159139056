import React from "react";

import "../Client.css";

function TotalAmount(props) {
  return (
    <div className="intro-client">
      <div className="intro-client-hello">Bonjour,</div>
      Voici la liste des mouvements pour lesquels un justificatif est manquant. Nous vous invitons à
      transmettre la pièce correspondante et à indiquer la nature du mouvement le cas échéant.
    </div>
  );
}

export default TotalAmount;
