import { Checkbox } from "antd";
import * as dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import * as number from "../../../utils/number";
import Category from "./Category";

function Type(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);

  const [categories, setCategories] = useState([]);
  const [activeOperations, setActiveOperations] = useState([]);
  const [totalAmount, setTotalAmount] = useState();

  useEffect(() => {
    setCategories(
      client.operationCategories
        ?.filter(({ typeId }) => typeId === props.typeId)
        .map((cat) => ({
          ...cat,
          operations: cat.operations
            .map((operation) =>
              operation.active === undefined &&
              client.uncheckedAccounts?.includes(cat.accountNumber + "-" + cat.typeId)
                ? { ...operation, active: false }
                : operation
            )
            .filter(
              (operation) =>
                (client.showZeroAccounts || !operation.is_in_balanced_account) &&
                dayjs(operation.date) >= props.startDate &&
                dayjs(operation.date) <= props.endDate &&
                props.journalListFiltered.includes(operation.journal?.trim()) &&
                (props.editMode || operation.active !== false) &&
                (operation.ghost === 0 ||
                  (operation.ghost === 1 &&
                    (operation.fileId ||
                      operation.files?.length > 0 ||
                      operation.comment ||
                      operation.isLost ||
                      operation.isLostNoAccount)))
            ),
        }))
        .filter(({ operations }) => operations.length > 0)
        .sort((a, b) => {
          const titleA = a.accountNumber
            ? a.accountNumber.trim().toUpperCase()
            : a.title.trim().toUpperCase();
          const titleB = b.accountNumber
            ? b.accountNumber.trim().toUpperCase()
            : b.title.trim().toUpperCase();
          return titleA.localeCompare(titleB, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        }) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client.operationCategories,
    props.startDate,
    props.endDate,
    props.journalListFiltered,
    props.editMode,
  ]);

  useEffect(() => {
    let totalAmount = 0;
    let nbrMissingDoc = 0;
    categories.forEach(({ operations }) => {
      operations.forEach(({ amount, active }) => {
        if (active !== false) {
          totalAmount += Math.abs(amount);
          nbrMissingDoc += 1;
        }
      });
    });

    setActiveOperations(
      categories.map(({ operations }) => operations.filter((op) => op.active !== false)).flat(1)
    );
    setTotalAmount(totalAmount);

    props.amountMissingDoc(totalAmount);
    props.nbrMissingDoc(nbrMissingDoc);
    props.filtered(categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const onChange = (state) => {
    dispatch(
      updateClient({
        operationCategories: [
          ...client.operationCategories.filter((el) => el.typeId !== props.typeId),
          ...categories.map((cat) => ({
            ...cat,
            operations: cat.operations.map((op) => ({ ...op, active: state })),
          })),
        ],
      })
    );
  };

  return props.editMode || totalAmount > 0 ? (
    <div className="type-inline">
      <div className="type-title">
        {props.typeText} - {number.withThousandSeparator(number.parseNum(totalAmount))}
      </div>
      {props.editMode ? (
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          className="type-checkbox"
          indeterminate={
            !!activeOperations.length &&
            activeOperations.length <
              categories.reduce((acc, curr) => acc + curr.operations.length, 0)
          }
          checked={
            activeOperations.length ===
            categories.reduce((acc, curr) => acc + curr.operations.length, 0)
          }
          onChange={(e) => onChange(e.target.checked)}
        />
      ) : null}
      <div className="type-separation"></div>
      {categories.map((category) => (
        <Category {...props} category={category} key={category._id} />
      ))}
    </div>
  ) : null;
}

export default Type;
