import {
  BarChartOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Col, Empty, Popconfirm, Progress, Radio, Row, Skeleton, Spin, Table, Tooltip } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { EFeatures } from "enums/EFeatures";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Styled from "styled-components";
import { hasFeatures } from "utils/security";
import * as API from "../../../api/API";
import { ReactComponent as ImgDownArrow } from "../../../assets/images/down-arrow.svg";
import { ReactComponent as ImgUpArrow } from "../../../assets/images/up-arrow.svg";
import { ReactComponent as ImgPeople } from "../../../assets/images/vpf-people.svg";
import logAction from "../../../utils/logActions";
import ModalIndex from "./ModalIndex";
import criteriaList from "./criteriaList";
import "./table.css";

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

const howManyDaysSince = (date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(date);
  const secondDate = new Date();

  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
};

const TableStyled = Styled(Table)`
thead span {
  color: ${(props) => props.darkColor};
}

th .ant-table-column-sorters{
  border: 1px solid  ${(props) => props.darkColor} !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 16px;
  background: white !important;
}

thead th {
  border-bottom: 0px !important;
  border-radius: 0px;
  background: inherit !important;
}

.ant-table-ping-left thead th.ant-table-cell-fix-left-last {
  background: ${(props) =>
    props.lightColor === "#FFEDDC59" ? "#fff9f3" : shadeColor(props.lightColor, 10)} !important;
}

tr:nth-child(2n + 1) td,
table {
  background: ${(props) => props.lightColor};
}

.ant-table-ping-left tr:nth-child(odd) td.ant-table-cell-fix-left-last{
  background: ${(props) =>
    props.lightColor === "#FFEDDC59" ? "#fff9f3" : shadeColor(props.lightColor, 10)} !important;
} 

tr:nth-child(odd) td.ant-table-cell-fix-left-last{
  background: ${(props) =>
    props.lightColor === "#FFEDDC59" ? "#fff9f3" : shadeColor(props.lightColor, 10)} !important;
} 

tr:nth-child(even) td.ant-table-column-sort{
  background: white !important;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  width: 0px !important;
}
`;

function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

function addAlpha(color, opacity) {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

function Tableau(props) {
  const [report, setReport] = useState(props.report);
  const [colors, setColors] = useState({ light: "#a5d5e3", dark: "#03424c" });
  const [dataColumns, setDataColumns] = useState([]);
  const [localData, setLocalData] = useState([]);
  const [keyPointsList] = useState(
    criteriaList.find((elt) => elt.value === "keyPointsListInclusive").list
  );

  const [sortInfo, setSortInfo] = useState({});
  const [previewData, setPreviewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableViewGraph, setTableViewGraph] = useState();
  const [reloadReport, setReloadReport] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const elementRef = useRef(null);

  const renderKeyPoints = (text, record) => {
    let result = [];
    text?.forEach((txt, i) => {
      if (txt.length > 70) {
        result.push(
          <Tooltip placement="top" title={txt} key={i}>
            <li key={i}>{txt.substr(0, 70) + "..."}</li>
          </Tooltip>
        );
      } else {
        result.push(<li key={i}>{txt}</li>);
      }
    });
    return result;
  };

  const numberWithThousandSeparator = (x) => {
    return x.toString().replace(/[\u202F\u00A0]/g, " ");
  };

  const parseNum = (num) =>
    parseFloat(num).toLocaleString("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const setSortInfoTest = (dataIndex, sortOrder) => {
    const data = {
      sortInfo: {
        sortField: dataIndex,
        sortOrder: sortOrder,
      },
    };
    if (report?._id) {
      (async () => {
        await API.putReport(report._id, data);
      })();
    } else {
      props.setSortInfo(data.sortInfo);
    }
  };

  const updateGraphViewType = (graphView) => {
    const data = {
      graphView,
    };
    (async () => {
      await API.putReport(report._id, data);
      setReloadReport(true);
    })();
  };

  const convertOperator = (operator) => {
    switch (operator) {
      case "gte":
        return "minimum";
      case "lte":
        return "maximum";
      case "eq":
        return "égal à";
      default:
        return "";
    }
  };

  const checkKeyPointOrNot = (label, value) => {
    if (!value) return "";
    switch (label) {
      case "keyPointsListInclusive":
        return value.map((el) => keyPointsList[el]).join(", ");
      case "keyPointsListExclusive":
        return value.map((el) => keyPointsList[el]).join(", ");
      case "collaboratorsInclusive":
        return value
          .map(
            (el) =>
              props.users.find((user) => user._id === el)?.firstName +
              " " +
              props.users.find((user) => user._id === el)?.lastName
          )
          .join(", ");
      case "collaboratorsExclusive":
        return value
          .map(
            (el) =>
              props.users.find((user) => user._id === el)?.firstName +
              " " +
              props.users.find((user) => user._id === el)?.lastName
          )
          .join(", ");
      case "apeCode":
        return value
          .map((el) => props.apeCodes.find((ape) => ape.code === el)?.codeLabel)
          .join(", ");
      case "analysisTemplate":
      case "missingDocTemplate":
      case "outstandingsClientsTemplate":
      case "outstandingsProvidersTemplate":
        return value
          .map(
            (el) =>
              props.templates.concat({ _id: "sesha", name: "Sesha" }).find((tpl) => tpl._id === el)
                ?.name
          )
          .join(", ");
      case "fiscalEndMonthExclusive":
        return value
          .map(
            (el) => criteriaList.find((crit) => crit.value === "fiscalEndMonthExclusive").list[el]
          )
          .join(", ");
      default:
        return value;
    }
  };

  const downloadReport = (report) => {
    (async () => {
      setDownloadLoading(true);
      if (!report.data) {
        let resdata = await API.postToGenerateReportById(report._id, {
          date: props.selectedDate,
        });
        resdata = await resdata.json();
        report.data = resdata;
      }

      let res = await API.downloadReport(report._id, report);
      res.arrayBuffer().then(function (buffer) {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          var blob = new Blob([buffer], { type: "" });
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", report.name + ".xlsx");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
      setDownloadLoading(false);
      logAction(261, 1);
    })();
  };

  useEffect(() => {
    if (report?.sortInfo) {
      setSortInfo({
        columnKey: report.sortInfo?.sortField,
        order: report.sortInfo?.sortOrder,
      });
    } else {
      setSortInfo({
        columnKey: "name",
        order: "ascend",
      });
    }
    if (report?.graphView) {
      setTableViewGraph(report.graphView);
    } else {
      setTableViewGraph(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (report.colors)
      setColors({
        light: addAlpha(report.colors.light, 0.35),
        dark: addAlpha(report.colors.dark, 0.85),
      });
  }, [report, reloadReport]);

  const handleChange = (pagination, filters, sorter) => {
    setSortInfo(sorter);
    setSortInfoTest(sorter.columnKey, sorter.order);
  };

  const handleChangeRowNumber = (size) => {
    (async () => {
      await API.putReport(props.report._id, {
        rowNumber: size,
      });
    })();
  };

  const customSort = (sortColumns, key) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === key);
    return (
      <span style={{ paddingLeft: "10px" }}>
        {sortedColumn?.order === "ascend" ? (
          <>
            <ImgUpArrow width="10px" height="10px" fill={colors.dark} />{" "}
            <ImgDownArrow width="10px" height="10px" fill={shadeColor(colors.light, -15)} />
          </>
        ) : sortedColumn?.order === "descend" ? (
          <>
            <ImgUpArrow width="10px" height="10px" fill={shadeColor(colors.light, -15)} />{" "}
            <ImgDownArrow width="10px" height="10px" fill={colors.dark} />
          </>
        ) : (
          <>
            <ImgUpArrow width="10px" height="10px" fill={shadeColor(colors.light, -15)} />{" "}
            <ImgDownArrow width="10px" height="10px" fill={shadeColor(colors.light, -15)} />
          </>
        )}
      </span>
    );
  };

  const columns = [
    {
      title: ({ sortColumns }) => (
        <>
          Nom du client
          {customSort(sortColumns, "name")}
        </>
      ),
      fixed: "left",
      dataIndex: "name",
      sortOrder: sortInfo?.columnKey === "name" ? sortInfo?.order : false,
      width: "150px",
      sorter: (rowA, rowB) => {
        return rowA.name.localeCompare(rowB.name, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      },
      key: "name",
      render: (text, record) => {
        return (
          <Link
            className="client-link"
            to={{
              pathname: `/analysis/${record._id}/${record.remoteId}/`,
            }}
            target="_blank"
          >
            <b style={{ color: "#595959" }}>{record.name}</b>
          </Link>
        );
      },
    },
    {
      title: "RemoteId",
      dataIndex: "remoteId",
      key: "remoteId",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
    },
    {
      title: ({ sortColumns }) => (
        <>
          Dernière analyse envoyée
          {customSort(sortColumns, "lastAnalysisEmailSent")}
        </>
      ),
      dataIndex: "lastAnalysisEmailSent",
      key: "lastAnalysisEmailSent",
      width: "220px",
      sortOrder: sortInfo?.columnKey === "lastAnalysisEmailSent" ? sortInfo?.order : false,
      sorter: (a, b) => {
        let x, y;
        x = a.lastAnalysisEmailSent
          ? new Date(a.lastAnalysisEmailSent)
          : new Date("2020-01-01T00:00:00Z");
        y = b.lastAnalysisEmailSent
          ? new Date(b.lastAnalysisEmailSent)
          : new Date("2020-01-01T00:00:00Z");
        return x > y ? 1 : -1;
      },
      render: (text, row) => {
        if (text) {
          const days = howManyDaysSince(text);
          switch (days) {
            case 0:
              return "Aujourd'hui";
            case 1:
              return "Hier";
            default:
              return days + " jours";
          }
        } else return "Jamais";
      },
    },
    {
      title: ({ sortColumns }) => (
        <>
          Dernière relance pièce manquante
          {customSort(sortColumns, "lastMissingDocEmailSent")}
        </>
      ),
      dataIndex: "lastMissingDocEmailSent",
      key: "lastMissingDocEmailSent",
      width: "270px",
      sortOrder: sortInfo?.columnKey === "lastMissingDocEmailSent" ? sortInfo?.order : false,
      sorter: (a, b) => {
        let x, y;
        x = a.lastMissingDocEmailSent
          ? new Date(a.lastMissingDocEmailSent)
          : new Date("2020-01-01T00:00:00Z");
        y = b.lastMissingDocEmailSent
          ? new Date(b.lastMissingDocEmailSent)
          : new Date("2020-01-01T00:00:00Z");
        return x > y ? 1 : -1;
      },
      render: (text, row) => {
        if (text) {
          const days = howManyDaysSince(text);
          switch (days) {
            case 0:
              return "Aujourd'hui";
            case 1:
              return "Hier";
            default:
              return days + " jours";
          }
        } else return "Jamais";
      },
    },
    {
      title: ({ sortColumns }) => (
        <>
          Dernière mise à jour
          {customSort(sortColumns, "updateDate")}
        </>
      ),
      dataIndex: "updateDate",
      key: "updateDate",
      sortOrder: sortInfo?.columnKey === "updateDate" ? sortInfo?.order : false,
      width: "180px",
      sorter: (a, b) => {
        let x, y;
        x = a.updateDate ? new Date(a.updateDate) : new Date("2020-01-01T00:00:00Z");
        y = b.updateDate ? new Date(b.updateDate) : new Date("2020-01-01T00:00:00Z");
        return x > y ? 1 : -1;
      },
      render: (text) => (
        <>
          {text === null || text === "" ? (
            "Jamais"
          ) : (
            <span>{dayjs(text).format("DD/MM/YYYY")}</span>
          )}
        </>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Secteur d'activité
          {customSort(sortColumns, "apeCode")}
        </>
      ),
      dataIndex: "apeCode",
      key: "apeCode",
      width: "170px",
      sortOrder: sortInfo?.columnKey === "apeCode" ? sortInfo?.order : false,
      sorter: (a, b) => (a.apeCode || "N/R").localeCompare(b.apeCode || "N/R"),
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? (
            "N/R"
          ) : (
            <Tooltip
              placement="top"
              title={props.apeCodes?.find((elt) => elt.code === text)?.codeLabel}
            >
              {text}
            </Tooltip>
          )}
        </span>
      ),
    },
    {
      title: "Collaborateurs",
      dataIndex: "collaboratorFullname",
      key: "collaboratorsId",
      width: "160px",
      sorter: (a, b) => true,
      render: (text) => {
        if (text?.length > 0) return text.join(", ");
      },
    },
    {
      title: ({ sortColumns }) => (
        <>
          Modèle Analyse
          {customSort(sortColumns, "analysisTemplate")}
        </>
      ),
      dataIndex: "analysisTemplate",
      key: "analysisTemplate",
      width: "160px",
      sortOrder: sortInfo?.columnKey === "analysisTemplate" ? sortInfo?.order : false,
      sorter: (a, b) => a.analysisTemplate.localeCompare(b.analysisTemplate),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Modèle Pièces Manquantes
          {customSort(sortColumns, "missingDocTemplate")}
        </>
      ),
      dataIndex: "missingDocTemplate",
      key: "missingDocTemplate",
      width: "230px",
      sortOrder: sortInfo?.columnKey === "missingDocTemplate" ? sortInfo?.order : false,
      sorter: (a, b) => a.missingDocTemplate.localeCompare(b.missingDocTemplate),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Modèle Encours Clients
          {customSort(sortColumns, "outstandingsClientsTemplate")}
        </>
      ),
      dataIndex: "outstandingsClientsTemplate",
      key: "outstandingsClientsTemplate",
      width: "200px",
      sortOrder: sortInfo?.columnKey === "outstandingsClientsTemplate" ? sortInfo?.order : false,
      sorter: (a, b) => a.outstandingsClientsTemplate.localeCompare(b.outstandingsClientsTemplate),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Modèle Encours Fournisseurs
          {customSort(sortColumns, "outstandingsProvidersTemplate")}
        </>
      ),
      dataIndex: "outstandingsProvidersTemplate",
      key: "outstandingsProvidersTemplate",
      width: "240px",
      sortOrder: sortInfo?.columnKey === "outstandingsProvidersTemplate" ? sortInfo?.order : false,
      sorter: (a, b) =>
        a.outstandingsProvidersTemplate.localeCompare(b.outstandingsProvidersTemplate),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Mois de clôture
          {customSort(sortColumns, "fiscalEndMonth")}
        </>
      ),
      dataIndex: "fiscalEndMonth",
      key: "fiscalEndMonth",
      width: "150px",
      sortOrder: sortInfo?.columnKey === "fiscalEndMonth" ? sortInfo?.order : false,
      sorter: (a, b) => a.fiscalEndMonth.localeCompare(b.fiscalEndMonth),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Points d'attention (nb)
          {customSort(sortColumns, "keyPointsNumber")}
        </>
      ),
      dataIndex: "keyPointsNumber",
      key: "keyPointsNumber",
      width: "190px",
      sortOrder: sortInfo?.columnKey === "keyPointsNumber" ? sortInfo?.order : false,
      sorter: (a, b) => a.keyPointsNumber - b.keyPointsNumber,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text}
        </span>
      ),
    },
    {
      title: "Points d'attention",
      dataIndex: "keyPointsText",
      key: "keyPoints",
      width: "510px",
      sortOrder: sortInfo?.columnKey === "keyPoints" ? sortInfo?.order : false,
      sorter: (a, b) => true,
      render: (text, record) => <ul>{renderKeyPoints(text, record)}</ul>,
    },
    {
      title: ({ sortColumns }) => (
        <>
          Pièces manquantes (nb)
          {customSort(sortColumns, "missingDocNumber")}
        </>
      ),
      dataIndex: "missingDocNumber",
      key: "missingDocNumber",
      width: "210px",
      sortOrder: sortInfo?.columnKey === "missingDocNumber" ? sortInfo?.order : false,
      sorter: (a, b) => a.missingDocNumber - b.missingDocNumber,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Pièces manquantes (€)
          {customSort(sortColumns, "missingDocAmount")}
        </>
      ),
      dataIndex: "missingDocAmount",
      key: "missingDocAmount",
      width: "200px",
      sortOrder: sortInfo?.columnKey === "missingDocAmount" ? sortInfo?.order : false,
      sorter: (a, b) => a.missingDocAmount - b.missingDocAmount,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Pièces reçues / pièces demandées
          {customSort(sortColumns, "receivedVsRequested")}
        </>
      ),
      dataIndex: "receivedVsRequested",
      key: "receivedVsRequested",
      width: "270px",
      sortOrder: sortInfo?.columnKey === "receivedVsRequested" ? sortInfo?.order : false,
      sorter: (a, b) => a.receivedVsRequested - b.receivedVsRequested,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Pièces perdues / pièces demandées
          {customSort(sortColumns, "lostVsRequested")}
        </>
      ),
      dataIndex: "lostVsRequested",
      key: "lostVsRequested",
      width: "290px",
      sortOrder: sortInfo?.columnKey === "lostVsRequested" ? sortInfo?.order : false,
      sorter: (a, b) => a.lostVsRequested - b.lostVsRequested,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Encours fournisseurs (€)
          {customSort(sortColumns, "outstandingsProviderAmount")}
        </>
      ),
      dataIndex: "outstandingsProviderAmount",
      key: "outstandingsProviderAmount",
      width: "220px",
      sortOrder: sortInfo?.columnKey === "outstandingsProviderAmount" ? sortInfo?.order : false,
      sorter: (a, b) => a.outstandingsProviderAmount - b.outstandingsProviderAmount,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Encours clients (€)
          {customSort(sortColumns, "outstandingsClientAmount")}
        </>
      ),
      dataIndex: "outstandingsClientAmount",
      key: "outstandingsClientAmount",
      width: "200px",
      sortOrder: sortInfo?.columnKey === "outstandingsClientAmount" ? sortInfo?.order : false,
      sorter: (a, b) => a.outstandingsClientAmount - b.outstandingsClientAmount,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Factures fournisseur impayées
          {customSort(sortColumns, "outstandingsProvider")}
        </>
      ),
      dataIndex: "outstandingsProvider",
      key: "outstandingsProvider",
      width: "250px",
      sortOrder: sortInfo?.columnKey === "outstandingsProvider" ? sortInfo?.order : false,
      sorter: (a, b) => a.outstandingsProvider - b.outstandingsProvider,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Factures client impayées
          {customSort(sortColumns, "outstandingsClient")}
        </>
      ),
      dataIndex: "outstandingsClient",
      key: "outstandingsClient",
      width: "210px",
      sortOrder: sortInfo?.columnKey === "outstandingsClient" ? sortInfo?.order : false,
      sorter: (a, b) => a.outstandingsClient - b.outstandingsClient,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Chiffre d'affaires mensuel
          {customSort(sortColumns, "monthlyCA")}
        </>
      ),
      dataIndex: "monthlyCA",
      key: "monthlyCA",
      width: "210px",
      sortOrder: sortInfo?.columnKey === "monthlyCA" ? sortInfo?.order : false,
      sorter: (a, b) => a.monthlyCA - b.monthlyCA,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Chiffre d'affaires mensuel N-1
          {customSort(sortColumns, "monthlyCAPastYear")}
        </>
      ),
      dataIndex: "monthlyCAPastYear",
      key: "monthlyCAPastYear",
      width: "235px",
      sortOrder: sortInfo?.columnKey === "monthlyCAPastYear" ? sortInfo?.order : false,
      sorter: (a, b) => a.monthlyCAPastYear - b.monthlyCAPastYear,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Chiffre d'affaires cumulé
          {customSort(sortColumns, "cumulCA")}
        </>
      ),
      dataIndex: "cumulCA",
      key: "cumulCA",
      width: "210px",
      sortOrder: sortInfo?.columnKey === "cumulCA" ? sortInfo?.order : false,
      sorter: (a, b) => a.cumulCA - b.cumulCA,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Chiffre d'affaires cumulé N-1
          {customSort(sortColumns, "cumulCAPastYear")}
        </>
      ),
      dataIndex: "cumulCAPastYear",
      key: "cumulCAPastYear",
      width: "230px",
      sortOrder: sortInfo?.columnKey === "cumulCAPastYear" ? sortInfo?.order : false,
      sorter: (a, b) => a.cumulCAPastYear - b.cumulCAPastYear,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },

    {
      title: ({ sortColumns }) => (
        <>
          Chiffre d'affaires total N-1
          {customSort(sortColumns, "totalCAPastYear")}
        </>
      ),
      dataIndex: "totalCAPastYear",
      key: "totalCAPastYear",
      width: "220px",
      sortOrder: sortInfo?.columnKey === "totalCAPastYear" ? sortInfo?.order : false,
      sorter: (a, b) => a.totalCAPastYear - b.totalCAPastYear,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>CA mensuel (var. en %){customSort(sortColumns, "varCAPercent")}</>
      ),
      dataIndex: "varCAPercent",
      key: "varCAPercent",
      width: "210px",
      sortOrder: sortInfo?.columnKey === "varCAPercent" ? sortInfo?.order : false,
      sorter: (a, b) => a.varCAPercent - b.varCAPercent,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          CA mensuel (var. vs m-1 en %)
          {customSort(sortColumns, "varCAPercentPastMonth")}
        </>
      ),
      dataIndex: "varCAPercentPastMonth",
      key: "varCAPercentPastMonth",
      width: "250px",
      sortOrder: sortInfo?.columnKey === "varCAPercentPastMonth" ? sortInfo?.order : false,
      sorter: (a, b) => a.varCAPercentPastMonth - b.varCAPercentPastMonth,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>CA cumulé (var. en %){customSort(sortColumns, "varCAPercentCumul")}</>
      ),
      dataIndex: "varCAPercentCumul",
      key: "varCAPercentCumul",
      width: "200px",
      sortOrder: sortInfo?.columnKey === "varCAPercentCumul" ? sortInfo?.order : false,
      sorter: (a, b) => a.varCAPercentCumul - b.varCAPercentCumul,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Masse salariale mensuelle
          {customSort(sortColumns, "monthlyMasseSal")}
        </>
      ),
      dataIndex: "monthlyMasseSal",
      key: "monthlyMasseSal",
      width: "220px",
      sortOrder: sortInfo?.columnKey === "monthlyMasseSal" ? sortInfo?.order : false,
      sorter: (a, b) => a.monthlyMasseSal - b.monthlyMasseSal,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Masse salariale mensuelle N-1
          {customSort(sortColumns, "monthlyMasseSalPastYear")}
        </>
      ),
      dataIndex: "monthlyMasseSalPastYear",
      key: "monthlyMasseSalPastYear",
      width: "240px",
      sortOrder: sortInfo?.columnKey === "monthlyMasseSalPastYear" ? sortInfo?.order : false,
      sorter: (a, b) => a.monthlyMasseSalPastYear - b.monthlyMasseSalPastYear,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Masse salariale cumulée
          {customSort(sortColumns, "cumulMasseSal")}
        </>
      ),
      dataIndex: "cumulMasseSal",
      key: "cumulMasseSal",
      width: "210px",
      sortOrder: sortInfo?.columnKey === "cumulMasseSal" ? sortInfo?.order : false,
      sorter: (a, b) => a.cumulMasseSal - b.cumulMasseSal,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Masse salariale cumulée N-1
          {customSort(sortColumns, "cumulMasseSalPastYear")}
        </>
      ),
      dataIndex: "cumulMasseSalPastYear",
      key: "cumulMasseSalPastYear",
      width: "230px",
      sortOrder: sortInfo?.columnKey === "cumulMasseSalPastYear" ? sortInfo?.order : false,
      sorter: (a, b) => a.cumulMasseSalPastYear - b.cumulMasseSalPastYear,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Masse salariale en % du CA mensuel (var. en %)
          {customSort(sortColumns, "varMasseSalCAPercent")}
        </>
      ),
      dataIndex: "varMasseSalCAPercent",
      key: "varMasseSalCAPercent",
      width: "350px",
      sortOrder: sortInfo?.columnKey === "varMasseSalCAPercent" ? sortInfo?.order : false,
      sorter: (a, b) => a.varMasseSalCAPercent - b.varMasseSalCAPercent,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Masse salariale en % du CA mensuel (var. vs m-1 en %)
          {customSort(sortColumns, "varMasseSalCAPercentPastMonth")}
        </>
      ),
      dataIndex: "varMasseSalCAPercentPastMonth",
      key: "varMasseSalCAPercentPastMonth",
      width: "390px",
      sortOrder: sortInfo?.columnKey === "varMasseSalCAPercentPastMonth" ? sortInfo?.order : false,
      sorter: (a, b) => a.varMasseSalCAPercentPastMonth - b.varMasseSalCAPercentPastMonth,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Masse salariale en % du CA cumulé (var.)
          {customSort(sortColumns, "varMasseSalCAPercentCumul")}
        </>
      ),
      dataIndex: "varMasseSalCAPercentCumul",
      key: "varMasseSalCAPercentCumul",
      width: "330px",
      sortOrder: sortInfo?.columnKey === "varMasseSalCAPercentCumul" ? sortInfo?.order : false,
      sorter: (a, b) => a.varMasseSalCAPercentCumul - b.varMasseSalCAPercentCumul,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Trésorerie cumulée
          {customSort(sortColumns, "cumulTreso")}
        </>
      ),
      dataIndex: "cumulTreso",
      key: "cumulTreso",
      width: "180px",
      sortOrder: sortInfo?.columnKey === "cumulTreso" ? sortInfo?.order : false,
      sorter: (a, b) => a.cumulTreso - b.cumulTreso,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Trésorerie cumulée n-1
          {customSort(sortColumns, "cumulTresoPastYear")}
        </>
      ),
      dataIndex: "cumulTresoPastYear",
      key: "cumulTresoPastYear",
      width: "195px",
      sortOrder: sortInfo?.columnKey === "cumulTresoPastYear" ? sortInfo?.order : false,
      sorter: (a, b) => a.cumulTresoPastYear - b.cumulTresoPastYear,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Trésorerie cumulée (var. vs m-1)
          {customSort(sortColumns, "varTresoAmountPastMonth")}
        </>
      ),
      dataIndex: "varTresoAmountPastMonth",
      key: "varTresoAmountPastMonth",
      width: "260px",
      sortOrder: sortInfo?.columnKey === "varTresoAmountPastMonth" ? sortInfo?.order : false,
      sorter: (a, b) => a.varTresoAmountPastMonth - b.varTresoAmountPastMonth,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Trésorerie cumulée (var. vs m-1 en %)
          {customSort(sortColumns, "varTresoPercentPastMonth")}
        </>
      ),
      dataIndex: "varTresoPercentPastMonth",
      key: "varTresoPercentPastMonth",
      width: "290px",
      sortOrder: sortInfo?.columnKey === "varTresoPercentPastMonth" ? sortInfo?.order : false,
      sorter: (a, b) => a.varTresoPercentPastMonth - b.varTresoPercentPastMonth,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Nombre d'opérations non-lettrées en compte d'attente (47)
          {customSort(sortColumns, "nbAwaitingAccount")}
        </>
      ),
      dataIndex: "nbAwaitingAccount",
      width: "420px",
      sortOrder: sortInfo?.columnKey === "nbAwaitingAccount" ? sortInfo?.order : false,
      key: "nbAwaitingAccount",
      sorter: (a, b) => a.nbAwaitingAccount - b.nbAwaitingAccount,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Montant total des opérations non-lettrées en compte d'attente (47)
          {customSort(sortColumns, "amountAwaitingAccount")}
        </>
      ),
      dataIndex: "amountAwaitingAccount",
      width: "450px",
      sortOrder: sortInfo?.columnKey === "amountAwaitingAccount" ? sortInfo?.order : false,
      key: "amountAwaitingAccount",
      sorter: (a, b) => a.amountAwaitingAccount - b.amountAwaitingAccount,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Marge mensuelle
          {customSort(sortColumns, "monthlyMarge")}
        </>
      ),
      dataIndex: "monthlyMarge",
      key: "monthlyMarge",
      width: "220px",
      sortOrder: sortInfo?.columnKey === "monthlyMarge" ? sortInfo?.order : false,
      sorter: (a, b) => a.monthlyMarge - b.monthlyMarge,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Marge mensuelle N-1
          {customSort(sortColumns, "monthlyMargePastYear")}
        </>
      ),
      dataIndex: "monthlyMargePastYear",
      key: "monthlyMargePastYear",
      width: "240px",
      sortOrder: sortInfo?.columnKey === "monthlyMargePastYear" ? sortInfo?.order : false,
      sorter: (a, b) => a.monthlyMargePastYear - b.monthlyMargePastYear,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Marge cumulée
          {customSort(sortColumns, "cumulMarge")}
        </>
      ),
      dataIndex: "cumulMarge",
      key: "cumulMarge",
      width: "240px",
      sortOrder: sortInfo?.columnKey === "cumulMarge" ? sortInfo?.order : false,
      sorter: (a, b) => a.cumulMarge - b.cumulMarge,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>
          Marge cumulée N-1
          {customSort(sortColumns, "cumulMargePastYear")}
        </>
      ),
      dataIndex: "cumulMargePastYear",
      key: "cumulMargePastYear",
      width: "240px",
      sortOrder: sortInfo?.columnKey === "cumulMargePastYear" ? sortInfo?.order : false,
      sorter: (a, b) => a.cumulMargePastYear - b.cumulMargePastYear,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === ""
            ? "N/A"
            : numberWithThousandSeparator(parseNum(text)) + " €"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>Taux de marge cumulée N{customSort(sortColumns, "varMargePercentCumul")}</>
      ),
      dataIndex: "varMargePercentCumul",
      key: "varMargePercentCumul",
      width: "240px",
      sortOrder: sortInfo?.columnKey === "varMargePercentCumul" ? sortInfo?.order : false,
      sorter: (a, b) => a.varMargePercentCumul - b.varMargePercentCumul,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>Taux de marge cumulée N-1{customSort(sortColumns, "varMargePercentCumulPastYear")}</>
      ),
      dataIndex: "varMargePercentCumulPastYear",
      key: "varMargePercentCumulPastYear",
      width: "240px",
      sortOrder: sortInfo?.columnKey === "varMargePercentCumulPastYear" ? sortInfo?.order : false,
      sorter: (a, b) => a.varMargePercentCumulPastYear - b.varMargePercentCumulPastYear,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: ({ sortColumns }) => (
        <>Variation du taux de marge{customSort(sortColumns, "varTauxMarge")}</>
      ),
      dataIndex: "varTauxMarge",
      key: "varTauxMarge",
      width: "240px",
      sortOrder: sortInfo?.columnKey === "varTauxMarge" ? sortInfo?.order : false,
      sorter: (a, b) => a.varTauxMarge - b.varTauxMarge,
      render: (text) => (
        <span
          style={{
            float: "right",
          }}
        >
          {text === null || text === "" ? "N/A" : text.toString().replace(".", ",") + " %"}
        </span>
      ),
    },
    {
      title: "Id",
      dataIndex: "_id",
      key: "_id",
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      let dataArray = [];

      let reportLocal = {};
      if (props.report._id && props.type === "real") {
        reportLocal = await API.getReport(props.report._id);
        reportLocal = await reportLocal.json();
        setReport(reportLocal);
      } else {
        setReport(props.report);
        reportLocal = props.report;
      }

      let resdata = await API.postToGenerateReport({
        criteria: reportLocal.criteria
          ? Object.fromEntries(Object.entries(reportLocal.criteria).filter(([_, v]) => v !== null))
          : {},
        column: reportLocal.column.filter((col) => col.show),
        date: props.selectedDate,
      });
      resdata = await resdata.json();
      dataArray = resdata;
      setPreviewData(resdata);

      if (props.type === "real")
        props.setOpportTotal({ _id: props.report._id, length: resdata.length });

      // concat usernames and get key points texts
      setLocalData(
        dataArray.map((data) => {
          return {
            ...data,
            key: data._id,
            collaboratorFullname: data?.collaboratorsId?.map(
              (collab) =>
                props.users?.find((user) => user._id === collab)?.firstName +
                " " +
                props.users?.find((user) => user._id === collab)?.lastName
            ),
            keyPointsText:
              data?.keyPoints !== null
                ? data?.keyPoints?.map((kp) => keyPointsList[parseInt(kp)])
                : [],
          };
        })
      );

      setLoading(false);
      setInitialLoading(false);
      props.setTriggerRef();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.report, props.selectedDate, reloadReport]);

  useEffect(() => {
    const tableData = [...previewData];

    // construct table columns
    const tableCol = [];
    if (tableData.length > 0) {
      for (const [key] of Object.entries(tableData[0])) {
        tableCol.push(columns.find((elt) => elt.key === key));
        if (key !== "remoteId" && key !== "active" && key !== "_id")
          tableCol.push({
            title: "",
            dataIndex: "",
            key: "",
            width: "10px",
            className: "vpfv2-espacement",
          });
      }
      tableCol.push({
        title: "",
        dataIndex: "",
        key: "",
        className: "vpfv2-espacement",
      });
      setDataColumns(tableCol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortInfo, previewData]);

  return (
    <>
      <div className="vpf-tablediv" ref={elementRef} id={report._id}>
        <ModalIndex
          {...props}
          component="EditReport"
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          report={report}
          reloadReport={reloadReport}
          setReloadReport={setReloadReport}
          type={"edit"}
        />
        <Row>
          <Col span={19}>
            {props.type === "preview" ? (
              <div style={{ fontSize: "18px", fontWeight: "700", marginBottom: "10px" }}>
                Voici un aperçu du résultat :
              </div>
            ) : (
              <div>
                <h1 className="vpfv2-title">{truncateString(report.name, 60)}</h1>
                <span
                  className="vpfv2-opport"
                  style={{ border: "1px solid " + colors.dark, color: colors.dark }}
                >
                  {previewData.length}&nbsp;résultat{previewData.length > 1 ? "s" : ""}
                </span>
              </div>
            )}
          </Col>
          {props.type === "preview" ? null : (
            <Col offset={2} span={3}>
              <div className="vpfv2-menubtn">
                <Tooltip placement="top" title="Télécharger">
                  <span
                    style={{ marginRight: "5%", cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      downloadReport(report);
                    }}
                  >
                    {" "}
                    {downloadLoading ? (
                      <Spin size="small" style={{ marginTop: "3px" }} />
                    ) : (
                      <span className="vpfv2-menubtn-icon">
                        <DownloadOutlined />
                      </span>
                    )}
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="Modifier">
                  <span
                    style={{ marginRight: "5%", cursor: "pointer" }}
                    onClick={() => setIsModalOpen(true)}
                  >
                    <span className="vpfv2-menubtn-icon">
                      <EditOutlined />
                    </span>
                  </span>
                </Tooltip>
                <Tooltip placement="top" title="Supprimer">
                  <Popconfirm
                    cancelButtonProps={{ className: "delete-report-popup" }}
                    okButtonProps={{ className: "delete-report-popup" }}
                    placement="top"
                    title="Voulez-vous supprimer ce tableau ?"
                    onConfirm={(e) => {
                      e.preventDefault();
                      props.setDeleteReport(report._id);
                      props.deleteOpportTotal(report._id);
                      props.setTriggerRef();
                      logAction(31, -1);
                    }}
                    okText="Oui"
                    cancelText="Non"
                  >
                    <span style={{ marginRight: "5%", cursor: "pointer" }}>
                      <span className="vpfv2-menubtn-icon">
                        <CloseOutlined />
                      </span>
                    </span>
                  </Popconfirm>
                </Tooltip>
              </div>
            </Col>
          )}
        </Row>
        <Row
          style={{
            padding: "5px",
            marginLeft: props.type === "preview" ? "0px" : "47px",
            marginBottom: "10px",
          }}
        >
          <Col span={18} className="criteria-recap-div">
            {report.criteria ? (
              Object.entries(
                Object.fromEntries(
                  Object.entries(report.criteria)
                    .filter(([_, v]) => v !== null)
                    .filter(([_, v]) => v !== undefined)
                ) || {}
              ).map((crit, i) => {
                return (
                  <div className="criteria-recap-item" key={crit[0]}>
                    <span style={{ color: "grey", marginRight: "10px" }}>
                      {criteriaList.find((el) => el.value === crit[0]).label}{" "}
                      <b>
                        {convertOperator(crit[1].operator)}{" "}
                        {checkKeyPointOrNot(crit[0], crit[1].value)}
                      </b>
                    </span>
                    {i === Object.entries(report.criteria || {}).length - 1 ? null : (
                      <span style={{ marginRight: "10px" }}> · </span>
                    )}
                  </div>
                );
              })
            ) : (
              <span style={{ color: "grey", marginRight: "10px" }}>
                Aucun critère n'a été appliqué
              </span>
            )}
          </Col>
        </Row>
        <div className={"vpf-tableau vpf-tableau-row"}>
          {initialLoading ? (
            <div className="report-list-table-div">
              <Skeleton
                active
                style={{
                  marginLeft: "35px",
                  marginTop: "-10px",
                  marginBottom: "30px",
                  width: "95%",
                }}
              />
            </div>
          ) : previewData.length > 0 ? (
            <>
              <>
                {tableViewGraph && props.type !== "preview" ? (
                  <div
                    style={{
                      backgroundColor: colors.light,
                      color: colors.dark,
                      borderRadius: "20px",
                      height: "250px",
                    }}
                  >
                    <div className="vpfv2-graphview-title">Chiffres clés</div>
                    <div className="vpfv2-graphview">
                      <Progress
                        style={{ marginRight: "40px" }}
                        className="circle-progress"
                        strokeLinecap="butt"
                        type="dashboard"
                        strokeColor={colors.dark}
                        trailColor={shadeColor(colors.light, -8)}
                        strokeWidth={11}
                        format={() => (
                          <div
                            style={{
                              paddingTop: "54px",
                            }}
                          >
                            <span style={{ fontSize: "35px", fontWeight: "700" }}>
                              {previewData?.length || 0}
                            </span>
                            <br />
                            <div
                              style={{
                                paddingTop: "5px",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: colors.dark,
                                marginBottom: "10px",
                              }}
                            >
                              client{previewData?.length > 1 ? "s" : ""}
                            </div>
                            <div>
                              <ImgPeople
                                fill={colors.dark}
                                style={{ width: "38px", height: "38px" }}
                              />
                            </div>
                          </div>
                        )}
                      />
                      <Progress
                        style={{ marginLeft: "40px" }}
                        className="circle-progress"
                        strokeLinecap="butt"
                        type="circle"
                        strokeColor={colors.dark}
                        trailColor={shadeColor(colors.light, -8)}
                        strokeWidth={11}
                        percent={Math.round((previewData?.length / props.clientTotal) * 100)}
                        format={(percent) => {
                          return props.clientTotal ? (
                            <>
                              <span style={{ fontSize: "35px", fontWeight: "700" }}>{percent}</span>
                              <span style={{ fontSize: "22px", fontWeight: "700" }}> %</span>
                              <br />
                              <div
                                style={{
                                  paddingTop: "5px",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  color: colors.dark,
                                }}
                              >
                                des clients
                              </div>
                            </>
                          ) : (
                            <div style={{ fontSize: "30px" }}>
                              <span className="loader__dot">.</span>
                              <span className="loader__dot">.</span>
                              <span className="loader__dot">.</span>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <TableStyled
                    lightColor={colors.light}
                    darkColor={colors.dark}
                    columns={dataColumns.filter(
                      (col) =>
                        col.dataIndex !== "remoteId" &&
                        col.dataIndex !== "active" &&
                        col.dataIndex !== "_id"
                    )}
                    dataSource={
                      !hasFeatures([EFeatures.MANAGE_USERS_AND_RIGHTS])
                        ? localData.filter((client) => client.active)
                        : localData
                    }
                    showSorterTooltip={false}
                    sortDirections={["descend", "ascend", "descend"]}
                    onChange={handleChange}
                    pagination={
                      previewData.length > 5
                        ? {
                            defaultPageSize: report?.rowNumber || 5,
                            pageSizeOptions: [5, 10, 20, 50],
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                              handleChangeRowNumber(size);
                              const element = elementRef.current;
                              const y =
                                element.getBoundingClientRect().top + window.pageYOffset - 100;
                              window.scrollTo({ top: y, behavior: "smooth" });
                            },
                          }
                        : false
                    }
                    loading={loading}
                    size="middle"
                    scroll={{
                      x: 800,
                    }}
                  />
                )}
              </>
              {props.type === "preview" ? null : (
                <div
                  style={
                    previewData.length > (report?.rowNumber || 5) && !tableViewGraph
                      ? { width: "50%", position: "relative", bottom: "40px", color: "#595959" }
                      : {
                          width: "50%",
                          position: "relative",
                          bottom: "-15px",
                          marginBottom: "50px",
                          color: "#595959",
                        }
                  }
                >
                  <Radio.Group
                    defaultValue={tableViewGraph ? "b" : "a"}
                    buttonStyle="solid"
                    style={{ position: "relative", right: "-8px" }}
                  >
                    <Radio.Button
                      value="a"
                      onClick={() => {
                        updateGraphViewType(false);
                        setTableViewGraph(false);

                        const element = elementRef.current;
                        const y = element.getBoundingClientRect().top + window.pageYOffset - 100;
                        window.scrollTo({ top: y, behavior: "smooth" });
                      }}
                    >
                      <div style={{ marginLeft: "-3px", marginTop: "-3px" }}>
                        <UnorderedListOutlined />
                      </div>
                    </Radio.Button>
                    <Radio.Button
                      value="b"
                      onClick={() => {
                        updateGraphViewType(true);
                        setTableViewGraph(true);
                        const element = elementRef.current;
                        const y = element.getBoundingClientRect().top + window.pageYOffset - 100;
                        window.scrollTo({ top: y, behavior: "smooth" });
                      }}
                    >
                      <div style={{ marginLeft: "-5px", marginTop: "-3px" }}>
                        <BarChartOutlined />
                      </div>
                    </Radio.Button>
                  </Radio.Group>
                </div>
              )}
            </>
          ) : loading ? (
            <div
              style={{
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "10px", height: "10px" }}>
                <Spin />
              </div>
            </div>
          ) : (
            <Empty
              style={{ marginTop: "30px", marginBottom: "30px", color: "grey" }}
              description={"Aucun dossier client ne correspond aux filtres sélectionnés"}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Tableau;
