import { DndContext, MouseSensor, useDroppable, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Button } from "antd";
import { useState } from "react";
import * as API from "../../../api/API";
import openNotification from "../../../utils/notification";
import OrderItem from "./OrderItem";

const indicName = {
  ca: "Chiffre d'affaires",
  marge: "Marge",
  masse_sal: "Masse salariale",
  treso: "Trésorerie",
};

function Order(props) {
  const [activeId, setActiveId] = useState(null);
  const [indicators, setIndicators] = useState(props.currentTemplate?.templateAnalysis);
  const { setNodeRef } = useDroppable({
    id: "droppable",
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = ({ active }) => {
    setActiveId(active.id);
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      setActiveId(null);
      return;
    }
    if (active.id !== over.id) {
      const activeIndex = active.data.current.sortable.index;
      const overIndex =
        over.id in indicators ? indicators.length + 1 : over.data.current.sortable.index;

      let orderedIndic = arrayMove(indicators, activeIndex, overIndex);
      let orderedIndicTreso = arrayMove(
        orderedIndic,
        orderedIndic.findIndex((elt) => elt.id === "treso_dispo"),
        orderedIndic.findIndex((elt) => elt.id === "treso") + 1
      );

      setIndicators(orderedIndicTreso.map((indic, i) => ({ ...indic, order: i })));
      setActiveId(null);
    }
  };

  const onSubmit = async () => {
    props.setCurrentTemplate({ ...props.currentTemplate, templateAnalysis: indicators });
    props.setIsOrderModalOpen(false);

    const res = await API.putAnalysisConfigTemplate(props.currentTemplate._id, {
      ...props.currentTemplate,
      templateAnalysis: indicators,
    });
    if (res.status === 200) {
      openNotification("success", "Votre modèle a été sauvegardé");
    } else {
      openNotification("error", "Erreur lors de l'enregistrement du modèle");
    }
  };

  return (
    <div className="order-modal-content">
      <p className="order-instruction">Glissez-déposez les indicateurs pour les ré-organiser</p>

      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <SortableContext id={activeId} items={indicators} strategy={verticalListSortingStrategy}>
          <div className="droppable" ref={setNodeRef}>
            {indicators
              .filter((elt) => elt.id !== "treso_dispo")
              .map((indic) => {
                return (
                  <OrderItem
                    {...props}
                    key={indic.id}
                    id={indic.id}
                    indic={indic}
                    dragOverlay={indic.id === activeId ? true : false}
                    name={indic.name || indicName[indic.id]}
                  />
                );
              })}
          </div>
        </SortableContext>
      </DndContext>

      <div className="vpf-modal-footer">
        <Button
          className="other-action-btn vpf-btn"
          onClick={(e) => {
            e.preventDefault();
            props.setIsOrderModalOpen(false);
          }}
        >
          Annuler
        </Button>
        <Button
          className="call-action-btn vpf-btn"
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          Valider
        </Button>
      </div>
    </div>
  );
}

export default Order;
