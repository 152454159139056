import {
  DatabaseOutlined,
  EyeOutlined,
  HistoryOutlined,
  SettingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Form,
  Layout,
  Modal,
  Popover,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import * as dayjs from "dayjs";
import { EFeatures } from "enums/EFeatures";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import hasFeature from "utils/dataProviderFeatures";
import logAction from "utils/logActions";
import { hasFeatures } from "utils/security";
import * as API from "../../api/API";
import badgePro from "../../assets/images/payment/badge_pro.png";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectClient, updateClient } from "../../slices/clientSlice";
import PaymentRequired from "../unauthorized/PaymentRequired";
import "./Nav.css";
import IDocusIcon from "./icons/IDocus";
import McfIcon from "./icons/Mcf";

require("dayjs/locale/fr");

const { Paragraph, Text } = Typography;
const { Option } = Select;

function SubNav(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const client = useSelector(selectClient);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [refreshActive, setRefreshActive] = useState(false);
  const [refreshActiveAGIRIS, setRefreshActiveAGIRIS] = useState(false);

  const [agirisUpdateMessage, setAgirisUpdateMessage] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [dataModelModal, setDataModelModal] = useState(false);
  const [templates, setTemplates] = useState([]);

  var timezone = require("dayjs/plugin/timezone");
  dayjs.extend(timezone);
  dayjs.locale("fr");

  const howManyIcons = () => {
    let count = 0;

    // maj
    if (hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "NAVBAR_UPDATE")) count++;

    // mcf
    if (accountingFirm.mcf) count++;

    // iDocus
    if (accountingFirm.iDocus) count++;

    // paramètres
    count++;

    //historique
    count++;

    //modèles
    count++;

    //client token
    if (client.clientToken) count++;
    return count;
  };

  useEffect(() => {
    (async () => {
      let res = await API.getAnalysisConfigTemplates();
      res = await res.json();
      setTemplates(res);
    })();
  }, []);

  useEffect(() => {
    if (client.status === "ready") {
      (async () => {
        if (
          !client.templates ||
          (!templates.find((elt) => elt._id === client.templates.analysis) &&
            client.templates.analysis !== "sesha")
        ) {
          dispatch(
            updateClient({
              templates: { ...client.templates, analysis: "default" },
            })
          );
        }
      })();
    }
  }, [client.status]);

  useEffect(() => {
    form.resetFields();
  }, [client]);

  useEffect(() => {
    (async () => {
      if (
        accountingFirm.status === "ready" &&
        accountingFirm.dataProvider === "AGIRIS" &&
        !accountingFirm.fec &&
        client
      ) {
        let res = await API.postIsClientAwaitingUpdate({
          firmId: accountingFirm._id,
          companyId: client.remoteId,
        });
        if (res.status === 200) {
          res = await res.json();
          if (res.result) {
            setAgirisUpdateMessage(
              "Données en attente de mise à jour (cette opération peut prendre plusieurs minutes)"
            );
            setRefreshActiveAGIRIS(true);
            setTimeout(() => setTriggerUpdate(triggerUpdate + 1), 30000);
          } else if (!res.result && agirisUpdateMessage) {
            setAgirisUpdateMessage("Données à jour, vous pouvez rafraîchir la page");
            setRefreshActiveAGIRIS(false);
            setRefreshActive(false);
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm, client, triggerUpdate]);

  useEffect(() => {
    setRefreshActive(props.refreshDP);
  }, [props.refreshDP]);

  const clientAddress = client.address ? client.address + "," : "";

  const date = new Date(client.updateDate);
  const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  const update =
    "le " + day + "/" + month + "/" + date.getFullYear() + " à " + hours + "h" + minutes;
  const iconsCount = howManyIcons();

  const fiscalMonth = (date_debut_exercice) => {
    return dayjs.tz(date_debut_exercice, "Europe/Paris").subtract(1, "month").format("MMMM");
  };

  const onSubmit = (values) => {
    const analysisChanged = values.analysis !== client.templates?.analysis ? true : false;
    (async () => {
      const templatesSelection = {
        analysis: values.analysis !== "default" ? values.analysis : undefined,
        missingDoc: values.missing_doc !== "default" ? values.missing_doc : undefined,
        outstandingClients:
          values.outstandings_clients !== "default" ? values.outstandings_clients : undefined,
        outstandingProviders:
          values.outstandings_providers !== "default" ? values.outstandings_providers : undefined,
      };

      if (analysisChanged) {
        if (
          values.analysis !== client.templates?.analysis &&
          client.templates?.analysis !== undefined
        )
          logAction(250, 1, client._id);
        if (
          values.missing_doc !== client.templates?.missingDoc &&
          client.templates?.missingDoc !== undefined
        )
          logAction(251, 1, client._id);
        if (
          values.outstandings_clients !== client.templates?.outstandingClients &&
          client.templates?.outstandingClients !== undefined
        )
          logAction(252, 1, client._id);
        if (
          values.outstandings_providers !== client.templates?.outstandingProviders &&
          client.templates?.outstandingProviders !== undefined
        )
          logAction(253, 1, client._id);

        const res = await API.putClient(client._id, {
          templates: templatesSelection,
          analysisIndics: [],
          tableOrder: ["ca", "marge", "masse_sal", "treso"],
        });
        if (res.status === 201) {
          setDataModelModal(false);
          dispatch(
            updateClient({
              templates: templatesSelection,
              analysisIndics: [],
              tableOrder: ["ca", "marge", "masse_sal", "treso"],
            })
          );
        }
      } else {
        const res = await API.putClient(client._id, {
          templates: templatesSelection,
        });
        if (res.status === 201) {
          setDataModelModal(false);
          dispatch(
            updateClient({
              templates: templatesSelection,
            })
          );
        }
      }
      props.reload(true);
    })();
  };

  const cancelAction = () => {
    setDataModelModal(false);
    // remove state templates
  };

  return (
    <>
      <Layout>
        <Row className="client-info" align="middle">
          <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
          <Col xs={18} sm={18} md={13} lg={9} xl={9}>
            <Paragraph className="client-name">
              {client.name}
              {client?.client?.date_debut_exercice ? (
                <Tag className="tag-cloture">
                  Clôture en {fiscalMonth(client?.client?.date_debut_exercice)}
                </Tag>
              ) : null}
            </Paragraph>
            <Paragraph className="client-address">
              {clientAddress} {client.postalCode} {client.city}
            </Paragraph>
          </Col>
          <Col
            className="last-update-col"
            xs={{
              span: 0,
              offset: 0,
            }}
            sm={{
              span: 0,
              offset: 0,
            }}
            md={{
              span: 4,
              offset: 0,
            }}
            lg={{
              span: 4,
              offset: 9 - iconsCount,
            }}
            xl={{
              span: 3,
              offset: 9 - iconsCount,
            }}
          >
            {client.updateDate && accountingFirm.fec ? (
              <div className="last-update">
                <span> Données mises à jour </span> <br />
                <span> {update} </span>
              </div>
            ) : null}
            {agirisUpdateMessage ? (
              <div className="last-update">
                <span> {agirisUpdateMessage} </span>
              </div>
            ) : null}
          </Col>
          <Col
            className="params"
            xs={iconsCount}
            sm={iconsCount}
            md={iconsCount}
            lg={iconsCount}
            xl={iconsCount}
          >
            <McfIcon {...props} />
            <IDocusIcon {...props} />
            {client.clientToken ? (
              <Popover
                placement="top"
                content="Afficher le formulaire client"
                className="info-popover"
                overlayClassName="navbar-popover-overlay"
              >
                <Button
                  className="client-icon"
                  shape="circle"
                  style={{
                    color: "black",
                  }}
                  onClick={() => window.open("/client/" + client.clientToken, "_blank")}
                  icon={<EyeOutlined />}
                />
              </Popover>
            ) : null}
            <Popover
              placement="top"
              content="Afficher l'historique des échanges"
              className="info-popover"
              overlayClassName="navbar-popover-overlay"
            >
              <Button
                className="client-icon"
                shape="circle"
                style={{
                  color: "black",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  props.showHistory(true);
                  logAction(130, 1, client._id);
                }}
                icon={<HistoryOutlined />}
              />
            </Popover>
            {hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "NAVBAR_UPDATE") ? (
              <Popover
                placement="top"
                content={"Mettre à jour les données comptables"}
                className="info-popover"
                overlayClassName="navbar-popover-overlay"
              >
                <Button
                  className={refreshActive || refreshActiveAGIRIS ? "refresh-icon" : "client-icon"}
                  disabled={refreshActive || refreshActiveAGIRIS}
                  shape="circle"
                  style={{
                    color: "black",
                  }}
                  onClick={async () => {
                    if (accountingFirm.dataProvider === "AGIRIS") {
                      props.refreshAGIRIS();
                      setRefreshActiveAGIRIS(true);
                      setAgirisUpdateMessage(
                        "Données en attente de mise à jour (cette opération peut prendre plusieurs minutes)"
                      );
                      setTimeout(() => setTriggerUpdate(triggerUpdate + 1), 30000);
                    } else props.refreshDPSetter(true);
                    setRefreshActive(true);
                  }}
                  icon={<SyncOutlined />}
                />
              </Popover>
            ) : null}
            <Badge
              count={
                accountingFirm.proAbonoFeatures?.includes("analysis_config") ? null : (
                  <img src={badgePro} alt="Logo" style={{ height: "15px" }} />
                )
              }
              color="#ff9700"
              size={"small"}
              style={{ top: "4px", right: "8px", fontSize: "9px" }}
            >
              <Popover
                placement="top"
                content="Modèles de données"
                className="info-popover"
                overlayClassName="navbar-popover-overlay"
              >
                <Button
                  className="client-icon"
                  shape="circle"
                  style={{
                    color: "black",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setDataModelModal(true);
                  }}
                  icon={<DatabaseOutlined />}
                />
              </Popover>
            </Badge>

            <Popover
              placement="top"
              content="Paramètres client"
              className="info-popover"
              overlayClassName="navbar-popover-overlay"
            >
              <Button
                className="client-icon"
                shape="circle"
                style={{
                  color: "black",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  props.importActive(true);
                }}
                icon={<SettingOutlined />}
              />
            </Popover>
          </Col>
        </Row>
      </Layout>
      <Modal
        title="Modèles de données"
        className="edit-user-modal data-model-modal"
        open={dataModelModal}
        onCancel={(e) => {
          e.preventDefault();
          setDataModelModal(false);
        }}
        footer={null}
      >
        {!accountingFirm.proAbonoFeatures?.includes("analysis_config") ? (
          <PaymentRequired {...props} page={"analysis_config_modal"} />
        ) : (
          <Form
            form={form}
            layout="horizontal"
            className="user-profile-form"
            onFinish={onSubmit}
            initialValues={{
              analysis: client.templates?.analysis || "default",
              missing_doc: client.templates?.missingDoc || "default",
              outstandings_clients: client.templates?.outstandingClients || "default",
              outstandings_providers: client.templates?.outstandingProviders || "default",
            }}
          >
            <Row>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <Text className="section-title">Analyse</Text>
                <div className="blue-bar-subnav"></div>
                <Form.Item name={"analysis"}>
                  <Select className="select-template-button" popupClassName="select-modal-template">
                    <Option key="default" className="select-template-default">
                      Modèle par défaut
                    </Option>
                    <Option key="sesha" className="select-template-default">
                      Modèle Sesha
                    </Option>
                    {templates
                      .filter((elt) => elt.type === "ANALYSIS")
                      .map((template) => (
                        <Option key={template._id}>{template.name}</Option>
                      ))}
                  </Select>
                </Form.Item>

                <Text className="section-title">Pièces manquantes</Text>
                <div className="blue-bar-subnav"></div>
                <Form.Item name={"missing_doc"}>
                  <Select className="select-template-button" popupClassName="select-modal-template">
                    <Option key="default" className="select-template-default">
                      Modèle par défaut
                    </Option>
                    <Option key="sesha" className="select-template-default">
                      Modèle Sesha
                    </Option>
                    {templates
                      .filter((elt) => elt.type === "MISSING_DOC")
                      .map((template) => (
                        <Option key={template._id}>{template.name}</Option>
                      ))}
                  </Select>
                </Form.Item>

                <Text className="section-title">Encours clients</Text>
                <div className="blue-bar-subnav"></div>
                <Form.Item name={"outstandings_clients"}>
                  <Select className="select-template-button" popupClassName="select-modal-template">
                    <Option key="default" className="select-template-default">
                      Modèle par défaut
                    </Option>
                    <Option key="sesha" className="select-template-default">
                      Modèle Sesha
                    </Option>
                    {templates
                      .filter((elt) => elt.type === "OUTSTANDINGS_CLIENTS")
                      .map((template) => (
                        <Option key={template._id}>{template.name}</Option>
                      ))}
                  </Select>
                </Form.Item>

                <Text className="section-title">Encours fournisseurs</Text>
                <div className="blue-bar-subnav"></div>
                <Form.Item name={"outstandings_providers"}>
                  <Select className="select-template-button" popupClassName="select-modal-template">
                    <Option key="default" className="select-template-default">
                      Modèle par défaut
                    </Option>
                    <Option key="sesha" className="select-template-default">
                      Modèle Sesha
                    </Option>
                    {templates
                      .filter((elt) => elt.type === "OUTSTANDINGS_PROVIDERS")
                      .map((template) => (
                        <Option key={template._id}>{template.name}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 10, offset: 2 }}
                sm={{ span: 10, offset: 3 }}
                md={{ span: 10, offset: 3 }}
                lg={{ span: 10, offset: 3 }}
                xl={{ span: 10, offset: 3 }}
              >
                <p className="data-model-modal-txt">
                  Choisissez, pour chaque fonctionnalité, le modèle de données adapté à ce client.
                  Les modèles sont personnalisables par les administrateurs dans{" "}
                  {!hasFeatures([EFeatures.ADMINISTRATION]) ? (
                    <span>l’interface d'administration</span>
                  ) : (
                    <Link
                      className="data-model-link"
                      to={{
                        pathname: `/admin`,
                      }}
                    >
                      <span>l’interface d'administration</span>
                    </Link>
                  )}
                  .
                </p>

                <Form.Item className="modal-submit-item">
                  <Button htmlType="submit" className="call-action-btn modal-submit-btn">
                    Valider
                  </Button>
                </Form.Item>
                <Button className="other-action-btn modal-cancel-btn" onClick={cancelAction}>
                  Annuler
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
}

export default SubNav;
