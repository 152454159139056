import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      {
        color: [],
      },
    ],
  ],
};
const formats = ["bold", "italic", "underline", "color", "list", "bullet", "indent"];
const SignatureTextEditor = (props) => (
  <ReactQuill
    theme={"bubble"}
    defaultValue={props.text || ""}
    modules={modules}
    formats={formats}
    onChange={(value) => props.setText(value)}
  />
);
export default SignatureTextEditor;
