import { CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Spin, Upload, message } from "antd";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import * as API from "../../../api/API";
import buildFileName from "../../../utils/buildFileName";
import logAction from "../../../utils/logActions";
import openNotification from "../../../utils/notification";

const options = [
  { label: ".png", value: "image/png" },
  { label: ".jpeg", value: "image/jpeg" },
  { label: ".jpg", value: "image/jpg" },
  { label: ".pdf", value: "application/pdf" },
  { label: ".txt", value: "text/plain" },
  { label: ".csv", value: "text/csv" },
  {
    label: ".docx",
    value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  { label: ".doc", value: "application/msword" },
  { label: ".xls", value: "application/vnd.ms-excel" },
  { label: ".xlsx", value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
  { label: ".ofx", value: "application/x-ofx" },
  { label: ".json", value: "application/json" },
];

function FileUpload(props) {
  const operation = props.operation;
  const tokenClient = props.match.params.token;
  const idClient = jwt_decode(tokenClient).clientId;
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  let uploading = false;
  const decodedToken = jwt_decode(props.match.params.token);
  const idAccountingFirm = jwt_decode(tokenClient).accountingFirmId;
  const [fileSize, setFileSize] = useState();
  const [fileTypes, setFileTypes] = useState([]);

  useEffect(() => {
    (async () => {
      let fileSettings = await API.getAccountingFirmFileSettings(idAccountingFirm);
      if (fileSettings.status === 200) {
        fileSettings = await fileSettings.json();
        setFileSize(fileSettings.fileSize);
        setFileTypes(
          !fileSettings.fileTypes?.length
            ? options.map(({ value }) => value)
            : fileSettings.fileTypes
        );
      } else {
        console.log("Error");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadType = (file) => {
    if (
      fileTypes.includes(file.type) ||
      (fileTypes.includes("application/x-ofx") && file.name.endsWith(".ofx"))
    ) {
      return true;
    }
    if (fileTypes.length > 1) {
      message.error(
        `Format non pris en charge. (formats acceptés: ${props.displayFileTypes.join(", ")})`
      );
    } else {
      message.error(
        `Format non pris en charge. (format accepté: ${props.displayFileTypes.join(", ")})`
      );
    }
    return false;
  };

  const uploadSize = (file) => {
    if (fileSize === null) {
      return true;
    } else {
      if (fileSize * 1048576 < file.size) {
        message.error(`"${file.name}" est trop volumineux. Taille maximum: ${fileSize}Mo.`);
        return false;
      } else {
        return true;
      }
    }
  };

  const checkUpload = (file) => {
    if (uploadType(file) && uploadSize(file)) {
      return true;
    } else {
      setFileUploading(false);
      return false;
    }
  };

  useEffect(() => {
    props.getFiles(defaultFileList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFileList]);

  const handleChange = (values) => {
    (async () => {
      let res = await API.getOperation(idClient, operation._id.replaceAll("/", "%2F"));
      if (res.status === 200) {
        res = await res.json();
        const fileName =
          values.file.name === "image.jpg"
            ? buildFileName(values.file.name, operation.text, true)
            : buildFileName(values.file.name, operation.text, false);

        if (res.files.some((e) => e.fileName === fileName)) {
          setFileUploading(false);
          message.error(
            "Il n'est pas possible de charger 2 fichiers du même nom. Veuillez revoir votre sélection ou supprimer le fichier déjà téléchargé."
          );
        } else {
          if (values.file.status !== "uploading" && values.file.status !== undefined) {
            setFileUploading(true);
          }
          if (values.file.status === "done" && uploading === false) {
            uploading = true;
            let formData = new FormData();
            formData.append("files", values.file.originFileObj, fileName);
            formData.set(
              "input",
              JSON.stringify({
                title:
                  props.type.title !== undefined ? props.type.title.replace("/", "%2F") : undefined,
                typeId: props.type.typeId || "3",
                typeText: props.type.typeText,
                downloadedOnce: false,
                operation,
              })
            );
            formData.set("idClient", idClient);

            axios({
              url: process.env.REACT_APP_INTERFACE_BACKEND + "/operation/file",
              method: "post",
              headers: {
                Authorization: `Bearer ${tokenClient}`,
              },
              processData: false,
              data: formData,
            })
              .then((response) => {
                if (response.status === 200) {
                  openNotification("success", "Votre fichier a bien été envoyé");
                  logAction(110, 1, idClient);
                  API.postClientTracker(idClient, {
                    action: "UPLOAD",
                    operationId: operation._id,
                    dateOp: operation.date,
                    type: props.type.typeText,
                    category:
                      props.type.title !== undefined
                        ? props.type.title.replace("/", "%2F")
                        : "Autres",
                    label: operation.text,
                    reference: operation.pieceRef,
                    amount: operation.amount,
                    nature: operation.nature,
                    fileName: fileName,
                  });

                  API.notify({ clientId: idClient, action: "missingDoc" });

                  uploading = false;
                  setFileUploading(false);
                  setDefaultFileList([
                    ...defaultFileList,
                    {
                      _id: response.data.files.find((f) => f.fileName === fileName)._id,
                      filename: values.file.name,
                    },
                  ]);

                  try {
                    formData.set(
                      "idFile",
                      response.data.files.find((f) => f.fileName === fileName)._id
                    );

                    if (decodedToken.mcf) {
                      axios({
                        url: process.env.REACT_APP_INTERFACE_BACKEND + "/sendToMCF",
                        method: "post",
                        headers: {
                          Authorization: `Bearer ${tokenClient}`,
                        },
                        processData: false,
                        data: formData,
                      })
                        .then((resmcf) => {
                          console.log(resmcf);
                        })
                        .catch((error) => {
                          // console.log(error.toJSON());
                        });
                    }

                    axios({
                      url: process.env.REACT_APP_INTERFACE_BACKEND + "/sendToIDepot",
                      method: "post",
                      headers: {
                        Authorization: `Bearer ${tokenClient}`,
                      },
                      processData: false,
                      data: formData,
                    })
                      .then((resIDepot) => {
                        console.log(resIDepot);
                      })
                      .catch((error) => {
                        // console.error(error);
                      });

                    if (decodedToken.iDocus) {
                      axios({
                        url: process.env.REACT_APP_INTERFACE_BACKEND + "/sendToIDocus",
                        method: "post",
                        headers: {
                          Authorization: `Bearer ${tokenClient}`,
                        },
                        processData: false,
                        data: formData,
                      })
                        .then((resIDocus) => {
                          console.log(resIDocus);
                        })
                        .catch((error) => {
                          // console.log(error.toJSON());
                        });
                    }

                    if (decodedToken.conciliator) {
                      axios({
                        url: process.env.REACT_APP_INTERFACE_BACKEND + "/sendToConciliator",
                        method: "post",
                        headers: {
                          Authorization: `Bearer ${tokenClient}`,
                        },
                        processData: false,
                        data: formData,
                      })
                        .then((resConciliator) => {
                          console.log(resConciliator);
                        })
                        .catch((error) => {
                          // console.log(error.toJSON());
                        });
                    }
                  } catch (error) {
                    console.error("FileUpload: " + error);
                  }
                } else {
                  openNotification(
                    "error",
                    "Erreur lors de l'import, le fichier est peut-être corrompu"
                  );
                  uploading = false;
                  setFileUploading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                openNotification(
                  "error",
                  "Erreur lors de l'import, le fichier est peut-être corrompu"
                );
                uploading = false;
                setFileUploading(false);
              });
          }
        }
      }
    })();
  };

  return (
    <>
      <Upload
        {...props}
        method="get"
        className="upload-field"
        beforeUpload={(file) => checkUpload(file)}
        showUploadList={false}
        onChange={(info) => {
          handleChange(info);
        }}
      >
        {fileUploading ? (
          <div className="spin-upload">
            <Spin size="large" />
          </div>
        ) : props.btnMode ? (
          <>
            <Button type="text" className="add-comment hide-btn justificatif-btn">
              <span className="justificatif-btn-text">Ajouter un justificatif</span>
            </Button>{" "}
            <Button
              type="text"
              className="add-comment-responsive call-action-btn client-upload add-justificatif-button-pos"
            >
              <PlusOutlined />
            </Button>{" "}
          </>
        ) : (
          <Button className="call-action-btn client-upload upload-client-button-pos">
            <CloudUploadOutlined />
          </Button>
        )}{" "}
      </Upload>{" "}
    </>
  );
}

export default FileUpload;
