import { Badge, Button, Tabs, Upload, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../api/API";
import { selectAccountingFirm, updateAccountingFirm } from "../../slices/accountingFirmSlice";
import logAction from "../../utils/logActions";
import openNotification from "../../utils/notification";
import FileSettings from "./FileSettings";
import TemplateMail from "./TemplateMail";
import TemplateMailForms from "./TemplateMailForms";

const { TabPane } = Tabs;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const allMailTemplatesInit = [
  {
    type: "analysis",
    subject: "[<nom_dossier_client>] Votre analyse comptable - <mois_analyse>",
    body: [
      {
        para: "begining",
        text: "Bonjour, <br/><br/>Veuillez trouver ci-dessous l’analyse comptable de la société &lt;nom_dossier_client&gt; pour le mois &lt;mois_analyse&gt;.<br/><br/>&lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "missingdoc",
    subject: "[<nom_dossier_client>] Vos justificatifs manquants",
    linkText: "Accéder à la liste des justificatifs manquants",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Il manque actuellement &lt;nb_justif&gt; justificatifs à votre dossier pour un montant total de &lt;montant_justif&gt; € TTC.<br/><br/>Je vous invite à me les transmettre dès que possible. Je reste à votre disposition pour toute question.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "outstandingsclients",
    subject: "[<nom_dossier_client>] Vos encours clients",
    linkText: "Accéder à la liste des factures non réglées",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>&lt;nb_factures&gt; factures clients n'ont pas été réglées pour un montant total de &lt;montant_factures&gt; € TTC.<br/><br/>Je vous invite à réviser ces éléments et à indiquer, le cas échéant, le mode de paiement utilisé et la date de celui-ci.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "outstandingsproviders",
    subject: "[<nom_dossier_client>] Vos encours fournisseurs",
    linkText: "Accéder à la liste des factures non réglées",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>&lt;nb_factures&gt; factures fournisseurs n'ont pas été réglées pour un montant total de &lt;montant_factures&gt; € TTC.<br/><br/>Je vous invite à réviser ces éléments et à indiquer, le cas échéant, le mode de paiement utilisé et la date de celui-ci.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "global",
    subject: "[<nom_dossier_client>] Vos points en suspens",
    linkText: "Accéder au détail",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Voici les points actuellement en suspens sur votre dossier :",
      },
      {
        para: "missingdoc",
        text: "<ul><li>&lt;nb_justif&gt; justificatifs manquants pour un montant total de &lt;montant_justif&gt; € TTC</li></ul>",
      },
      {
        para: "outstandingsclients",
        text: "<ul><li>&lt;nb_factures&gt; factures clients non réglées pour un montant total de &lt;montant_factures&gt; € TTC</li></ul>",
      },
      {
        para: "outstandingsproviders",
        text: "<ul><li>&lt;nb_factures&gt; factures fournisseurs non réglées pour un montant total de &lt;montant_factures&gt; € TTC</li></ul>",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "form",
    subject: "[<nom_cabinet>] - <nom_form>",
    linkText: "Accéder au formulaire",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Je vous invite à remplir le formulaire &lt;nom_form&gt; avant le &lt;date_fin&gt;.<br/>Je reste à votre disposition pour tout complément d'information.",
      },
      {
        para: "ending",
        text: "&lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "formreminder",
    subject: "[<nom_cabinet>] - <nom_form>",
    linkText: "Accéder au formulaire",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Je me permets de vous relancer au sujet du formulaire &lt;nom_form&gt;.<br/>A ce jour, vous avez rempli &lt;taux_completion&gt; de votre formulaire.<br/>Afin d'assurer le traitement de votre dossier, je vous remercie de compléter les informations manquantes avant le &lt;date_fin&gt;.<br/>Je reste à votre disposition pour tout complément d'information.",
      },
      {
        para: "ending",
        text: "&lt;signature_collab&gt;",
      },
    ],
  },
];

function Custom(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const tokenClient = localStorage.getItem("token");

  const [color, setColor] = useState(accountingFirm.color || "#eaf4fe");
  const [logo, setLogo] = useState();
  const [logoFileId, setLogoFileId] = useState("");
  const [, setFileList] = useState();
  const [allMailTemplates, setAllMailTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  //const [reset, setReset] = useState(false);

  useEffect(() => {
    (async () => {
      var templates = [];
      setLogoFileId(accountingFirm.logoFile?._id);

      allMailTemplatesInit.forEach((elt) => {
        const currentTemplate = Object.keys(accountingFirm).find(
          (key) => key.toLowerCase() === "templatemail" + elt.type.toLowerCase()
        );
        templates.push({
          type: elt.type,
          subject: accountingFirm[currentTemplate]?.subject || elt.subject,
          linkText: accountingFirm[currentTemplate]?.linkText || elt.linkText,
          body: accountingFirm[currentTemplate]?.body || elt.body,
        });
      });
      setAllMailTemplates(templates);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let res = await API.getAccountingFirmLogo(accountingFirm._id);
      if (res.status === 200) {
        const data = await res.blob();
        setLogo(URL.createObjectURL(data));
        setLoading(false);
      } else {
        console.log("error");
        setLoading(false);
      }
    })();
  }, []);

  const handleChangeColor = (color) => {
    setColor(color.hex);
    logAction(121, 1);
    (async () => {
      const res = await API.putAccountingFirm(accountingFirm._id, { color: color.hex });
      if (res.status === 201) {
        dispatch(updateAccountingFirm({ color: color.hex }));
      } else openNotification("error", "Erreur lors de la modification de la couleur");
    })();
  };

  const handleChange = (info) => {
    if (info.file.status === "done") {
      (async () => {
        if (logoFileId !== "") {
          await API.removeAccountingFirmLogo(accountingFirm._id, {
            fileId: logoFileId,
          });
        }
        let formData = new FormData();
        formData.append("files", info.file.originFileObj, info.file.name);
        formData.set("accountingFirmId", accountingFirm._id);
        const url = process.env.REACT_APP_INTERFACE_BACKEND + "/accountingFirm/logo";
        axios({
          url,
          method: "post",
          headers: {
            Authorization: `Bearer ${tokenClient}`,
          },
          processData: false,
          data: formData,
        })
          .then((response) => {
            dispatch(updateAccountingFirm({ logoFile: response.data.logoFile }));
            getBase64(info.file.originFileObj, (img) => setLogo(img));
            setFileList(info.file);
            setLogoFileId(response.data.logoFile._id);
          })
          .catch(function (error) {
            console.log(error);
          });
      })();
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Uniquement les fichiers JPG/PNG sont acceptés.");
    }
    /*const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;*/
    return isJpgOrPng;
  };

  const resetLogo = () => {
    setLogo();
    if (logoFileId !== "") {
      (async () => {
        await API.removeAccountingFirmLogo(accountingFirm._id, {
          fileId: logoFileId,
        });
        setLogoFileId("");
      })();
    }
  };

  return (
    <>
      {loading ? (
        ""
      ) : (
        <div className="custom-block">
          <Tabs defaultActiveKey="1" tabPosition="left" className="custom-tabs">
            <TabPane tab="Logo" key="1">
              <p>
                Le logo apparaîtra dans le formulaire de chargement des justificatifs côté client
                ainsi que dans les signatures des emails sortants.
              </p>
              <Upload
                {...props}
                method="get"
                className="upload-logo-img"
                showUploadList={false}
                maxCount={1}
                listType="picture"
                onChange={handleChange}
                beforeUpload={beforeUpload}
              >
                {logo ? (
                  <img
                    src={logo}
                    alt="logo"
                    className="logo-display"
                    style={{ maxHeight: "80px" }}
                  />
                ) : null}
                <Button
                  type="text"
                  className="add-logo-img call-action-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    logAction(120, 1);
                  }}
                >
                  Charger un fichier
                </Button>
              </Upload>
              {logo ? (
                <Button
                  className="other-action-btn reset-logo-img"
                  onClick={(e) => {
                    e.preventDefault();
                    resetLogo();
                  }}
                >
                  Supprimer
                </Button>
              ) : null}
            </TabPane>
            <TabPane tab="Couleur de fond" key="2">
              <p>
                Définissez ici la couleur de fond du formulaire de chargement des justificatifs côté
                client et des emails sortants.
              </p>
              <ChromePicker
                className="color-picker"
                disableAlpha={true}
                color={color}
                onChangeComplete={handleChangeColor}
              />
              <Button
                className="call-action-btn reset-color-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setColor("#eaf4fe");
                  handleChangeColor({ hex: "#eaf4fe" });
                }}
              >
                Réinitialiser
              </Button>
            </TabPane>
            <TabPane key="3" tab={<span>Taille et formats des fichiers</span>}>
              <p style={{ fontStyle: "italic", marginBottom: "25px" }}>
                Personnalisez ici la taille maximale et les formats de fichiers acceptés pour la
                fonctionnalité Pièces manquantes.
              </p>
              <FileSettings />
            </TabPane>
            <TabPane tab="Mail analyse" key="4">
              <TemplateMail
                {...props}
                type="analysis"
                mailTemplate={allMailTemplates.find((elt) => elt.type === "analysis")}
                mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "analysis")}
              />
            </TabPane>
            <TabPane tab="Mail pièces manquantes" key="5">
              <TemplateMail
                {...props}
                type="missingdoc"
                mailTemplate={allMailTemplates.find((elt) => elt.type === "missingdoc")}
                mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "missingdoc")}
              />
            </TabPane>
            <TabPane
              key="6"
              tab={
                <span>
                  <Badge
                    count={"Pro"}
                    color="gold"
                    style={{
                      top: "-8px",
                      visibility: !accountingFirm.proAbonoFeatures
                        ? "hidden"
                        : !accountingFirm.proAbonoFeatures?.includes("outstandings")
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Mail encours clients
                  </Badge>
                </span>
              }
            >
              <TemplateMail
                {...props}
                type="outstandingsclients"
                mailTemplate={allMailTemplates.find((elt) => elt.type === "outstandingsclients")}
                mailTemplateInit={allMailTemplatesInit.find(
                  (elt) => elt.type === "outstandingsclients"
                )}
              />
            </TabPane>
            <TabPane
              key="7"
              tab={
                <span>
                  <Badge
                    count={"Pro"}
                    color="gold"
                    style={{
                      top: "-8px",
                      visibility: !accountingFirm.proAbonoFeatures
                        ? "hidden"
                        : !accountingFirm.proAbonoFeatures?.includes("outstandings")
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Mail encours fournisseurs
                  </Badge>
                </span>
              }
            >
              <TemplateMail
                {...props}
                type="outstandingsproviders"
                mailTemplate={allMailTemplates.find((elt) => elt.type === "outstandingsproviders")}
                mailTemplateInit={allMailTemplatesInit.find(
                  (elt) => elt.type === "outstandingsproviders"
                )}
              />
            </TabPane>
            <TabPane
              key="8"
              tab={
                <span>
                  <Badge
                    count={"Pro"}
                    color="gold"
                    style={{
                      top: "-8px",
                      visibility: !accountingFirm.proAbonoFeatures
                        ? "hidden"
                        : !accountingFirm.proAbonoFeatures?.includes("outstandings")
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Mail relance globale
                  </Badge>
                </span>
              }
            >
              <TemplateMail
                {...props}
                type="global"
                mailTemplate={allMailTemplates.find((elt) => elt.type === "global")}
                mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "global")}
              />
            </TabPane>
            <TabPane key="9" tab={<span>Mail d'envoi Forms</span>}>
              <TemplateMailForms
                {...props}
                type="form"
                mailTemplate={allMailTemplates.find((elt) => elt.type === "form")}
                mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "form")}
              />{" "}
            </TabPane>
            <TabPane key="10" tab={<span>Mail relance Forms</span>}>
              <TemplateMailForms
                {...props}
                type="formreminder"
                mailTemplate={allMailTemplates.find((elt) => elt.type === "formreminder")}
                mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "formreminder")}
              />
            </TabPane>
          </Tabs>
        </div>
      )}
    </>
  );
}
export default Custom;
