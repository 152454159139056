import { CommentOutlined } from "@ant-design/icons";
import { Popover, Progress, Tag } from "antd";
import { useEffect, useState } from "react";
import * as number from "../../../utils/number";
import Detailed from "./Detailed";

const accountColumns = [
  {
    title: "N° de compte aux.",
    dataIndex: "accountNumber",
    key: "accountNumber",
    width: "170px",
    sorter: (a, b) => a.accountNumber.localeCompare(b.accountNumber),
    render: (text, record) => {
      const nbrCommentCollab = record.operations.reduce(
        (count, op) => (op.commentCollab ? count + 1 : count),
        0
      );

      return (
        <>
          <span>{text}</span>
          {record.operations.some((op) => op.commentCollab) && (
            <Popover
              content={
                nbrCommentCollab > 1
                  ? `${nbrCommentCollab} commentaires du cabinet`
                  : `${nbrCommentCollab} commentaire du cabinet`
              }
            >
              <Tag color="var(--blue)" style={{ marginLeft: "10px" }} borderless>
                <span style={{ color: "white" }}>
                  {record.operations.reduce(
                    (count, op) => (op.commentCollab ? count + 1 : count),
                    0
                  )}
                  <CommentOutlined style={{ marginLeft: "5px" }} />
                </span>
              </Tag>
            </Popover>
          )}
        </>
      );
    },
  },
  {
    title: "Lib. compte auxiliaire",
    dataIndex: "accountLabel",
    key: "accountLabel",
    width: "170px",
    sorter: (a, b) => a.accountLabel.localeCompare(b.accountLabel),
  },
  {
    title: "Nb de factures",
    dataIndex: "invoicesNb",
    key: "invoicesNb",
    align: "center",
    width: "100px",
    sorter: (a, b) => a.invoicesNb - b.invoicesNb,
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Encours",
    dataIndex: "outstandingsTotal",
    key: "outstandingsTotal",
    align: "center",
    width: "110px",
    defaultSortOrder: "descend",
    sorter: (a, b) => Math.abs(a.outstandingsTotal) - Math.abs(b.outstandingsTotal),
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {number.parseToAmount(Math.abs(text))}
      </span>
    ),
  },
];

const operationColumns = [
  {
    title: "N° pièce",
    dataIndex: "pieceRef",
    key: "pieceRef",
    width: "150px",
    sorter: (a, b) => a.pieceRef.localeCompare(b.pieceRef),
  },
  {
    title: "Lib. opération",
    dataIndex: "label",
    key: "label",
    width: "150px",
    sorter: (a, b) => a.label.localeCompare(b.label),
  },
  {
    title: "Date de facturation",
    dataIndex: "date",
    key: "date",
    align: "center",
    width: "150px",
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {new Date(text)?.toLocaleDateString()}
      </span>
    ),
  },
  {
    title: "Echéance",
    dataIndex: "dueDays",
    key: "dueDays",
    align: "center",
    width: "120px",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.dueDays - b.dueDays,
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {text} jours
      </span>
    ),
  },
  {
    title: "Encours",
    dataIndex: "amount",
    key: "amount",
    align: "center",
    width: "110px",
    sorter: (a, b) => a.amount - b.amount,

    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {number.parseToAmount(Math.abs(text))}
      </span>
    ),
  },
];

const Outstandings = (props) => {
  const [totalAmountFiltered, setTotalAmountFiltered] = useState();
  const [totalNumberFiltered, setTotalNumberFiltered] = useState();

  useEffect(() => {
    console.log(props);
  }, []);

  return (
    <div style={{ minHeight: "1000px" }}>
      <div className="circle outstandings-circle">
        <Progress
          type="circle"
          percent={100}
          size={80}
          strokeColor="#4569f8"
          format={() => <span className="circular-text">{totalNumberFiltered}</span>}
        />
        <div className="circle-block">
          <div className="outstandings-text">
            Factures non payées au <b>{new Date(props.endDate).toLocaleDateString()}</b>
          </div>

          <br />
          <span className="outstandings-text">Pour un montant de </span>
          <b>{number.parseToAmount(Math.abs(totalAmountFiltered))}</b>
        </div>
      </div>

      <div>
        <b>
          Si le règlement de l’une de ces factures a déjà été réalisé, merci de nous indiquer de
          quelle façon en commentaire.
        </b>
      </div>
      <div className="outstandings-dueDay">
        {props.type === "clients" ? (
          <p>
            Encours supérieurs à {props.outstandingClientDueDay || 0} jours au{" "}
            {new Date(props.endDate).toLocaleDateString()}
          </p>
        ) : (
          <p>
            Encours supérieurs à {props.outstandingProviderDueDay || 0} jours au{" "}
            {new Date(props.endDate).toLocaleDateString()}
          </p>
        )}
      </div>
      <br />
      <Detailed
        {...props}
        data={props.data}
        endDate={props.endDate}
        accountColumns={accountColumns}
        operationColumns={operationColumns.filter((col) => col.key !== "clientComment")}
        setTotalAmountFiltered={setTotalAmountFiltered}
        setTotalNumberFiltered={(count) => {
          setTotalNumberFiltered(count);
          props.setBadgeCount(count);
        }}
        changeTab={(key) => props.changeTab(key)}
      />
    </div>
  );
};

export default Outstandings;
