import { Badge, Col, Row, Tabs } from "antd";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import logAction from "utils/logActions";
import * as API from "../../api/API";
import * as sentry from "../../utils/sentry";
import Alert from "../alert/Alert.js";
import DisconnectedFooter from "../footer/DisconnectedFooter.js";
import "./Client.css";
import NavClient from "./NavClient.js";
import Missing from "./missing/Missing";
import Outstandings from "./outstandings/Outstandings";

const { TabPane } = Tabs;

function Client(props) {
  const tokenClient = props.match.params.token;

  const [connectionError, setConnectionError] = useState(false);
  const [expiredToken, setExpiredToken] = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const [nbrMissing, setNbrMissing] = useState(0);
  const [nbrClients, setNbrClients] = useState(0);
  const [nbrProviders, setNbrProviders] = useState(0);
  const [color, setColor] = useState("");
  const [outstandings, setOutstandings] = useState({});
  const [activeKey, setActiveKey] = useState();

  const [displayMissingDoc, setDisplayMissingDoc] = useState(false);
  const [displayOutstandingsClient, setDisplayOutstandingsClient] = useState(false);
  const [displayOutstandingsProv, setDisplayOutstandingsProv] = useState(false);

  const [outstandingClientDueDay, setOutstandingClientDueDay] = useState();
  const [outstandingProviderDueDay, setOutstandingProviderDueDay] = useState();
  const [outstandingClientEndDate, setOutstandingClientEndDate] = useState();
  const [outstandingProviderEndDate, setOutstandingProviderEndDate] = useState();

  const idAccountingFirm = jwt_decode(tokenClient).accountingFirmId;
  const idClient = jwt_decode(tokenClient).clientId;
  const remoteId = jwt_decode(tokenClient).remoteId;

  useEffect(() => {
    sentry.addTag("client", remoteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      let resac = await API.getAccountingFirmColor(idAccountingFirm);
      if (resac.status === 200) {
        resac = await resac.json();
        setColor(resac.color);
      } else if (resac.status === 401) setExpiredToken(true);
      else {
        setConnectionError(true);
      }
    })();
  }, [idAccountingFirm]);

  useEffect(() => {
    (async () => {
      if (
        jwt_decode(tokenClient).outstandingClientDueDay ||
        jwt_decode(tokenClient).outstandingProviderDueDay ||
        jwt_decode(tokenClient).outstandingClientEndDate ||
        jwt_decode(tokenClient).outstandingProviderEndDate
      ) {
        // old way
        setOutstandingClientDueDay(jwt_decode(tokenClient).outstandingClientDueDay);
        setOutstandingProviderDueDay(jwt_decode(tokenClient).outstandingProviderDueDay);
        setOutstandingClientEndDate(jwt_decode(tokenClient).outstandingClientEndDate);
        setOutstandingProviderEndDate(jwt_decode(tokenClient).outstandingProviderEndDate);
        setDisplayMissingDoc(true);
        setDisplayOutstandingsClient(true);
        setDisplayOutstandingsProv(true);
        setActiveKey("missingdoc");
      } else {
        let client = await API.getClient(idClient);
        if (client.status === 200) {
          client = await client.json();
          setDisplayMissingDoc(client.clientPage?.missingdoc?.display || false);
          setDisplayOutstandingsClient(client.clientPage?.outstandingsclients?.display || false);
          setDisplayOutstandingsProv(client.clientPage?.outstandingsproviders?.display || false);
          setOutstandingClientDueDay(client.clientPage?.outstandingsclients?.dueDays);
          setOutstandingProviderDueDay(client.clientPage?.outstandingsproviders?.dueDays);
          setOutstandingClientEndDate(client.clientPage?.outstandingsclients?.endDate);
          setOutstandingProviderEndDate(client.clientPage?.outstandingsproviders?.endDate);

          if (client.clientPage?.[jwt_decode(tokenClient).defaultView]?.display)
            setActiveKey(jwt_decode(tokenClient).defaultView);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idClient]);

  useEffect(() => {
    (async () => {
      let outstandings = await API.getOutstandingsClient(idClient);
      if (outstandings.status === 200) {
        outstandings = await outstandings.json();

        outstandings.clients = outstandings.clients
          ?.map((cat) => ({
            ...cat,
            operations: cat.operations
              .filter((op) => op.showToClient)
              .map((op) => ({ ...op, categoryId: cat._id })),
          }))
          ?.filter((cat) => cat.operations.length > 0);
        outstandings.providers = outstandings.providers
          ?.map((cat) => ({
            ...cat,
            operations: cat.operations
              .filter((op) => op.showToClient)
              .map((op) => ({ ...op, categoryId: cat._id })),
          }))
          ?.filter((cat) => cat.operations.length > 0);
        setOutstandings(outstandings);
      } else if (outstandings.status === 401) setExpiredToken(true);
      else {
        setConnectionError(true);
      }
    })();
  }, [idClient]);

  const onChange = (activeKey) => {
    setActiveKey((previous) => {
      if (previous === "outstandingsclients" && activeKey === "missingdoc") {
        logAction(269, 1, idClient, idAccountingFirm);
      } else if (previous === "outstandingsproviders" && activeKey === "missingdoc") {
        logAction(270, 1, idClient, idAccountingFirm);
      }
      return activeKey;
    });
  };

  return (
    <>
      {connectionError ? (
        <Alert
          {...props}
          title="Maintenance"
          text="Le service est momentanément indisponible. Nous vous invitons à revenir dans quelques instants."
        />
      ) : expiredToken ? (
        <Alert
          {...props}
          title="Lien expiré"
          text="Pour des raisons de sécurité, les liens Sesha expirent au bout de 60 jours. Nous vous invitons à contacter votre cabinet comptable afin d'obtenir un nouveau lien."
        />
      ) : (
        <div className="client-missingdoc-bg" style={{ width: "100%", backgroundColor: color }}>
          <div className="client-missingdoc">
            <NavClient
              {...props}
              isDisconnected={disconnected}
              disconnected={(data) => setDisconnected(data)}
              color={color}
            />
            {disconnected ? null : (
              <Row justify="center" className="analysis-row">
                <Col xs={22} sm={22} md={22} lg={22} xl={22} className="analysis-box-client">
                  <Tabs onChange={onChange} activeKey={activeKey}>
                    {displayMissingDoc ? (
                      <TabPane
                        className="tab-container"
                        tab={
                          <Badge count={nbrMissing} className="badge-count">
                            <span>Pièces manquantes</span>
                          </Badge>
                        }
                        key="missingdoc"
                        forceRender
                      >
                        <Missing {...props} setBadgeCount={setNbrMissing} />
                      </TabPane>
                    ) : null}
                    {displayOutstandingsClient && outstandings.clients?.length > 0 ? (
                      <>
                        <TabPane
                          className="tab-container"
                          tab={
                            <Badge count={nbrClients} className="badge-count">
                              <span>Encours clients</span>
                            </Badge>
                          }
                          key="outstandingsclients"
                          forceRender
                        >
                          <Outstandings
                            {...props}
                            data={outstandings.clients}
                            type="clients"
                            setBadgeCount={setNbrClients}
                            changeTab={setActiveKey}
                            outstandingClientDueDay={outstandingClientDueDay}
                            endDate={outstandingClientEndDate}
                          />
                        </TabPane>
                      </>
                    ) : null}
                    {displayOutstandingsProv && outstandings.providers?.length > 0 ? (
                      <TabPane
                        className="tab-container"
                        tab={
                          <Badge count={nbrProviders} className="badge-count">
                            <span>Encours fournisseurs</span>
                          </Badge>
                        }
                        key="outstandingsproviders"
                        forceRender
                      >
                        <Outstandings
                          {...props}
                          data={outstandings.providers}
                          type="providers"
                          setBadgeCount={setNbrProviders}
                          changeTab={setActiveKey}
                          outstandingProviderDueDay={outstandingProviderDueDay}
                          endDate={outstandingProviderEndDate}
                        />
                      </TabPane>
                    ) : null}
                  </Tabs>
                </Col>
              </Row>
            )}
            {disconnected ? <DisconnectedFooter /> : null}
          </div>
        </div>
      )}
    </>
  );
}

export default Client;
