import { EditOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input } from "antd";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import * as API from "../../../api/API";
import FileDisplay from "./FileDisplay.js";
import LostDocument from "./LostDocument.js";
import OperationNature from "./OperationNature.js";

const { TextArea } = Input;

const { Panel } = Collapse;

function OperationActions(props) {
  const operation = props.operation;
  const tokenClient = props.match.params.token;
  const idClient = jwt_decode(tokenClient).clientId;

  const [form] = Form.useForm();

  const onSubmit = (value) => {
    props.getComment(value.comment);
    (async () => {
      const res = await API.UpdateOperation({
        clientId: idClient,
        title: props.type.title !== undefined ? props.type.title.replace("/", "%2F") : undefined,
        typeId: props.type.typeId,
        categoryId: props.type._id,
        operation,
        comment: value.comment,
        other: props.title === "Autres" ? true : false,
      });
      props.getEditComment(false);
      if (res.status === 200) {
        API.postClientTracker(idClient, {
          action: "COMMENT",
          operationId: operation._id,
          dateOp: operation.date,
          type: props.type.typeText,
          category:
            props.type.title !== undefined ? props.type.title.replace("/", "%2F") : "Autres",
          label: operation.text,
          reference: operation.pieceRef,
          amount: operation.amount,
          nature: operation.nature,
          comment: value.comment,
        });
        if (value.comment) API.notify({ clientId: idClient, action: "missingDoc" });
      } else {
        console.log("error OperationActions");
      }
    })();
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.comment]);

  return (
    <>
      <Collapse
        ghost
        collapsible="header"
        activeKey={props.comment || props.editComment ? ["open"] : []}
      >
        <Panel
          showArrow={false}
          header={
            <>
              {props.comment ? (
                <Button
                  type="text"
                  className="add-comment"
                  onClick={() => {
                    onSubmit({ comment: null });
                  }}
                >
                  Supprimer le commentaire
                </Button>
              ) : (
                <Button
                  type="text"
                  className="add-comment"
                  onClick={() => props.getEditComment(!props.editComment)}
                >
                  Ajouter un commentaire
                </Button>
              )}
              <div style={{ display: "contents", width: "100%" }}>
                {operation.subTypeId !== null ? <OperationNature {...props} /> : null}

                {props.files === null || props.files.length === 0 ? (
                  <LostDocument {...props} />
                ) : props.files === null ? (
                  <FileDisplay {...props} file={props.file} />
                ) : (
                  <div className="multiple">
                    {props.files.map((f, i) => {
                      return <FileDisplay {...props} file={f} key={i} />;
                    })}
                  </div>
                )}

                <div className="status-txt">
                  {props.files !== null && props.files.length > 0 ? (
                    <span>En cours de traitement</span>
                  ) : null}
                  {props.comment &&
                  !props.isLost &&
                  !props.isLostNoAccount &&
                  (props.files === null || props.files.length === 0) ? (
                    <span>Commentaire en cours de traitement</span>
                  ) : null}
                  {props.isLost ? <span className="lost-txt">[Récupérable]</span> : null}
                  {props.isLostNoAccount ? (
                    <span className="lost-txt">[Non récupérable]</span>
                  ) : null}
                  {props.invalidComment && (props.files === null || props.files.length === 0) ? (
                    <span className="justif-invalide">
                      {" "}
                      <strong>{props.invalidComment.split("***")[0]} - Invalidé : </strong>
                      {props.invalidComment.split("***")[1]}
                    </span>
                  ) : null}
                </div>
              </div>
            </>
          }
          key={"open"}
        >
          <div>
            <div>
              <Form
                form={form}
                layout="inline"
                name="comment-form"
                className="submit-btn-flex"
                onFinish={(data) => onSubmit(data)}
                initialValues={{
                  comment: props.comment,
                }}
              >
                <Form.Item
                  name="comment"
                  label={<span>Votre commentaire :</span>}
                  className="comment-item"
                >
                  <TextArea
                    disabled={!props.editComment && props.comment}
                    bordered={props.editComment || !props.comment}
                    className={
                      props.editComment || !props.comment ? "edit-txt comment-area" : "comment-show"
                    }
                    onChange={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Form.Item>
                <Form.Item className="send-comment">
                  {props.editComment || !props.comment ? (
                    <>
                      <Button
                        type="primary"
                        className="add-comment hide-btn submit-btn-style"
                        htmlType="submit"
                      >
                        Soumettre{" "}
                      </Button>{" "}
                      <Button
                        type="primary"
                        className="send-btn add-comment-responsive call-action-btn send-btn-pos"
                        htmlType="submit"
                      >
                        <SendOutlined />
                      </Button>{" "}
                    </>
                  ) : (
                    <Button
                      className="other-action-btn"
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={(e) => {
                        e.preventDefault();
                        props.getEditComment(true);
                      }}
                    />
                  )}
                </Form.Item>
              </Form>
            </div>
          </div>
        </Panel>
      </Collapse>
    </>
  );
}

export default OperationActions;
