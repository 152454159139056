import {
  CheckCircleTwoTone,
  CopyOutlined,
  ExclamationCircleTwoTone,
  LinkOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Popover, Table } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../../../api/API";
import IDepot from "./IDepot";
import Mcf from "./Mcf";

import dayjs from "dayjs";

const d = new Date();
const n = d.getDate();
const month =
  dayjs()
    .subtract(n < 15 ? 2 : 1, "months")
    .get("month") + 1;
const year = dayjs()
  .subtract(n < 15 ? 2 : 1, "months")
  .get("year");

function ClientTable(props) {
  const [analysis, setAnalysis] = useState();
  const [keyPoints, setKeyPoints] = useState();
  const [missingDoc, setMissingDoc] = useState();
  const [outstandingsClients, setOutstandingsClients] = useState();
  const [outstandingsProv, setOutstandingsProv] = useState();

  const [analysisLoading, setAnalysisLoading] = useState(true);
  const [keyPointsLoading, setKeyPointsLoading] = useState(true);
  const [missingDocLoading, setMissingDocLoading] = useState(true);
  const [outstandingsClientsLoading, setOutstandingsClientsLoading] = useState(true);
  const [outstandingsProvLoading, setOutstandingsProvLoading] = useState(true);

  const [mcfState, setMcfState] = useState();
  const [idepotState, setIdepotState] = useState();

  useEffect(() => {
    (async () => {
      setAnalysisLoading(true);
      let res = await API.getAnalysis(
        props.currentClientId,
        props.currentClient,
        year,
        month,
        props.currentAccFirm
      );
      if (res.status === 200) {
        res = await res.json();
        setAnalysis(res);
      } else setAnalysis(false);
      setAnalysisLoading(false);
    })();
  }, [props.currentClient]);
  useEffect(() => {
    (async () => {
      setKeyPointsLoading(true);
      let res = await API.getKeyPoints(
        props.currentClientId,
        props.currentClient,
        year,
        month,
        props.currentAccFirm
      );
      if (res.status === 200) {
        res = await res.json();
        setKeyPoints(res);
      } else setKeyPoints(false);
      setKeyPointsLoading(false);
    })();
  }, [props.currentClient]);
  useEffect(() => {
    (async () => {
      setMissingDocLoading(true);
      let res = await API.getMissingDocuments(props.currentClientId, props.currentAccFirm);
      if (res.status === 200) {
        res = await res.json();
        setMissingDoc(res);
      } else setMissingDoc(false);
      setMissingDocLoading(false);
    })();
  }, [props.currentClient]);
  useEffect(() => {
    (async () => {
      setOutstandingsClientsLoading(true);
      let res = await API.getOutstandings(
        props.currentClientId,
        year,
        month,
        "OUTSTANDINGS_CLIENTS",
        props.currentAccFirm
      );
      if (res.status === 200) {
        res = await res.json();
        setOutstandingsClients(res);
      } else setOutstandingsClients(false);
      setOutstandingsClientsLoading(false);
    })();
  }, [props.currentClient]);
  useEffect(() => {
    (async () => {
      setOutstandingsProvLoading(true);
      let res = await API.getOutstandings(
        props.currentClientId,
        year,
        month,
        "OUTSTANDINGS_PROVIDERS",
        props.currentAccFirm
      );
      if (res.status === 200) {
        res = await res.json();
        setOutstandingsProv(res);
      } else setOutstandingsProv(false);
      setOutstandingsProvLoading(false);
    })();
  }, [props.currentClient]);

  useEffect(() => {
    (async () => {
      if (props.currentClient) {
        let res = await API.testMCFConnection({
          clientId: props.clients?.find((client) => client.remoteId === props.currentClient)._id,
          accountingFirmId: props.currentAccFirm,
        });
        setMcfState(res.statusCode);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentClient, props.clients]);

  useEffect(() => {
    (async () => {
      if (props.currentAccFirm) {
        let res = await API.testIDepotConnectionDashboard({
          accountingFirmId: props.currentAccFirm,
        });
        setIdepotState(res);
      }
    })();
  }, [props.currentAccFirm, props.clients]);

  const columns = [
    {
      title: "Id",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: "20px",
      render: (_id) => (
        <CopyOutlined
          onClick={() => {
            navigator.clipboard.writeText('{_id: ObjectId("' + _id + '")}');
          }}
        />
      ),
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "120px",
    },
    {
      title: "Health check",
      children: [
        {
          title: "A",
          dataIndex: "A",
          key: "A",
          align: "center",
          width: "16px",
          render: () => (
            <>
              {analysisLoading ? (
                <LoadingOutlined />
              ) : analysis && typeof analysis?.text === "string" ? (
                <Popover content={<>{analysis?.text}</>}>
                  <ExclamationCircleTwoTone twoToneColor="orange" />
                </Popover>
              ) : analysis ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                <ExclamationCircleTwoTone twoToneColor="red" />
              )}
            </>
          ),
        },
        {
          title: "PA",
          dataIndex: "PA",
          key: "PA",
          align: "center",
          width: "16px",
          render: () => (
            <>
              {keyPointsLoading ? (
                <LoadingOutlined />
              ) : keyPoints ? (
                <Popover
                  content={<>Points d'attentions: {keyPoints.attention_points_nlg?.length}</>}
                >
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Popover>
              ) : (
                <ExclamationCircleTwoTone twoToneColor="red" />
              )}
            </>
          ),
        },
        {
          title: "PM",
          dataIndex: "PM",
          key: "PM",
          align: "center",
          width: "16px",
          render: () => (
            <>
              {missingDocLoading ? (
                <LoadingOutlined />
              ) : missingDoc ? (
                <Popover
                  content={
                    <>
                      Journaux: {missingDoc.journals?.missing_documents?.join(", ")}
                      <br />
                      Pièces manquantes:{" "}
                      {missingDoc.operationCategories.reduce(
                        (cumul, current) => cumul + (current.operations?.length || 0),
                        0
                      )}
                      <br />
                      Autres: {missingDoc.others?.length || 0}
                    </>
                  }
                >
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Popover>
              ) : (
                <ExclamationCircleTwoTone twoToneColor="red" />
              )}
            </>
          ),
        },
        {
          title: "EC",
          dataIndex: "EC",
          key: "EC",
          align: "center",
          width: "16px",
          render: () => (
            <>
              {outstandingsClientsLoading ? (
                <LoadingOutlined />
              ) : outstandingsClients ? (
                <Popover
                  content={
                    <>
                      Journaux: {outstandingsClients.journals.clients?.join(", ")} <br />
                      Encours:{" "}
                      {outstandingsClients.outstandings?.clients?.reduce(
                        (cumul, current) => cumul + current.operations?.length,
                        0
                      ) || 0}
                    </>
                  }
                >
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Popover>
              ) : (
                <ExclamationCircleTwoTone twoToneColor="red" />
              )}
            </>
          ),
        },
        {
          title: "EF",
          dataIndex: "EF",
          key: "EF",
          align: "center",
          width: "16px",
          render: () => (
            <>
              {outstandingsProvLoading ? (
                <LoadingOutlined />
              ) : outstandingsProv ? (
                <Popover
                  content={
                    <>
                      Journaux: {outstandingsProv.journals.providers?.join(", ")} <br />
                      Encours:{" "}
                      {outstandingsProv.outstandings?.providers?.reduce(
                        (cumul, current) => cumul + current.operations?.length,
                        0
                      ) || 0}
                    </>
                  }
                >
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Popover>
              ) : (
                <ExclamationCircleTwoTone twoToneColor="red" />
              )}
            </>
          ),
        },
      ],
    },
    {
      title: "Dernière analyse",
      dataIndex: "lastAnalysisEmailSent",
      key: "lastAnalysisEmailSent",
      align: "center",
      width: "100px",
      render: (text, row) =>
        row.email?.analysis?.lastEmailSent
          ? dayjs(row.email?.analysis?.lastEmailSent).format("DD/MM/YYYY HH:mm:ss")
          : "Jamais",
    },
    {
      title: "Pièces manquantes",
      children: [
        {
          title: "Dernière relance",
          dataIndex: "lastMissingDocEmailSent",
          key: "lastMissingDocEmailSent",
          align: "center",
          width: "100px",
          render: (text, row) =>
            row.email?.missingdoc?.lastEmailSent
              ? dayjs(row.email?.missingdoc?.lastEmailSent).format("DD/MM/YYYY HH:mm:ss")
              : "Jamais",
        },
        {
          title: "Accès client",
          dataIndex: "clientToken",
          key: "clientToken",
          align: "center",
          width: "50px",
          render: (clientToken) =>
            clientToken ? (
              <Button
                type="text"
                href={window.location.origin + "/client/" + clientToken}
                target="_blank"
              >
                <LinkOutlined style={{ color: "blue" }} />
              </Button>
            ) : (
              ""
            ),
        },
      ],
    },
    {
      title: "Mise à jour du dossier",
      dataIndex: "lastUpdate",
      key: "lastUpdate",
      align: "center",
      width: "100px",
      render: (lastUpdate) =>
        lastUpdate ? dayjs(lastUpdate).format("DD/MM/YYYY HH:mm:ss") : "Jamais",
    },
    {
      title: "GED",
      dataIndex: "ged",
      key: "ged",
      align: "center",
      width: "50px",
      render: () => (
        <>
          <Mcf mcfState={mcfState} />
          <IDepot idepotState={idepotState} />
        </>
      ),
    },
  ];

  return (
    <div style={{ marginTop: "30px" }}>
      <Table
        columns={columns}
        dataSource={[props.clients?.find((client) => client.remoteId === props.currentClient)]}
        size="small"
        bordered
        pagination={false}
      />
    </div>
  );
}

export default ClientTable;
