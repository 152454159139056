import React, { useState, useEffect } from "react";
import { Button, Avatar, Typography, Popover } from "antd";
import {
  VerticalAlignBottomOutlined,
  UserOutlined,
  BankOutlined,
  LineChartOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import DataTable from "react-data-table-component";
import LoaderRaw from "../../../utils/LoaderRaw";
import * as API from "../../../api/API";
import OverviewGraphs from "./OverviewGraphs.js";

const { Text } = Typography;

function OverviewDashboard(props) {
  const [dashboardInfo, setDashboardInfo] = useState([]);

  useEffect(() => {
    (async () => {
      let res = await API.getDashboardOverview();
      res = await res.json();
      setDashboardInfo(res);
    })();
  }, []);

  function nameWithPopover(name, popoverContent) {
    return (
      <>
        <>{name} </>
        <Popover placement="right" content={popoverContent} overlayClassName="popover-dashboard">
          <span>
            <InfoCircleOutlined />
          </span>
        </Popover>
      </>
    );
  }

  const columns1 = [
    {
      name: "Cabinets ouverts",
      selector: "firmsOpen",
      center: true,
    },
    {
      name: nameWithPopover(
        "Cabinets actifs",
        "Au moins une connexion dans les 30 derniers jours par un des utilisateurs du cabinet"
      ),
      selector: "isFirmActiveCount",
      center: true,
    },
    {
      name: "Cabinets non actifs",
      selector: "isFirmNotActiveCount",
      center: true,
    },
    {
      name: "Cabinets \nfin M-2",
      selector: "firmTotalM2",
      center: true,
    },
    {
      name: "Cabinets \nfin M-1",
      selector: "firmTotalM1",
      center: true,
    },
  ];

  const columns2 = [
    {
      name: "Utilisateurs habilités",
      selector: "usersTotal",
      center: true,
    },
    {
      name: nameWithPopover(
        "Utilisateurs actifs",
        "Au moins une connexion dans les 30 derniers jours"
      ),
      selector: "isUserActiveCount",
      center: true,
    },
    {
      name: nameWithPopover(
        "Utilisateurs réguliers",
        "3 connexions + 10 analyses générées dans les 30 derniers jours"
      ),
      selector: "regularUsers",
      center: true,
    },
    {
      name: nameWithPopover(
        "Taux d'activité",
        "Total d'utilisateurs actifs / Total d'utilisateurs habilités"
      ),
      selector: "activityRate",
      center: true,
    },
    {
      name: nameWithPopover(
        "Médiane utilisateurs",
        "Nombre médian d'utilisateurs actifs \npar cabinet actif"
      ),
      selector: "median",
      center: true,
    },
  ];

  const handleDownload = () => {
    (async () => {
      let res = await API.getDownloadDashboard();
      res.arrayBuffer().then(function (buffer) {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          var blob = new Blob([buffer], { type: "" });
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "Dashboard.xlsx");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    })();
  };

  return (
    <div className="overview">
      <div className="create-user">
        <Button
          style={{ marginTop: "2.5px" }}
          className="call-action-btn"
          onClick={(e) => {
            e.preventDefault();
            handleDownload();
          }}
          icon={<VerticalAlignBottomOutlined />}
        >
          Télécharger
        </Button>
      </div>

      <Avatar
        className="dashboard-icon"
        size="large"
        icon={<BankOutlined style={{ color: "#4569f8" }} />}
      />
      <Text className="title-table" strong>
        Données sur les cabinets comptables
      </Text>
      <DataTable
        className="overview-table"
        columns={columns1}
        data={dashboardInfo}
        noDataComponent={<LoaderRaw />}
      />
      <Avatar
        className="dashboard-icon"
        size="large"
        icon={<UserOutlined style={{ color: "#4569f8" }} />}
      />
      <Text className="title-table" strong>
        Données sur les utilisateurs
      </Text>
      <DataTable
        className="overview-table"
        columns={columns2}
        data={dashboardInfo}
        noDataComponent={<LoaderRaw />}
      />
      {dashboardInfo.length > 0 ? (
        <>
          <Avatar
            className="dashboard-icon"
            size="large"
            icon={<LineChartOutlined style={{ color: "#4569f8" }} />}
          />
          <Text className="title-table" strong>
            Graphiques d'utilisation
          </Text>
          <OverviewGraphs dataAnalysis={dashboardInfo} />
        </>
      ) : null}
    </div>
  );
}

export default OverviewDashboard;
