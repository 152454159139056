import { Col, Row, Table } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";

import MoneyGraphComponentAreas from "./MoneyGraphComponentAreas.js";
import MoneyGraphComponentBarHorizontal from "./MoneyGraphComponentBarHorizontal.js";
import MoneyGraphComponentColumn from "./MoneyGraphComponentColumn.js";
import MoneyGraphComponentColumnBidirectional from "./MoneyGraphComponentColumnBidirectional.js";
import MoneyGraphComponentGroup from "./MoneyGraphComponentGroup.js";
import MoneyGraphComponentPie from "./MoneyGraphComponentPie";

function MoneyGraphs(props) {
  return (
    <div>
      <Row className="money-chart-row">
        <Col
          xs={24}
          sm={24}
          md={{ span: 5, offset: 0 }}
          lg={{ span: 5, offset: 0 }}
          className="col-money-charts"
        >
          <MoneyGraphComponentGroup
            data={props.dataAnalysis[0].monthlyRevenueChart}
            title={"CA 24 derniers mois"}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ span: 5, offset: 1 }}
          lg={{ span: 5, offset: 1 }}
          className="col-money-charts"
        >
          <MoneyGraphComponentColumn
            data={props.dataAnalysis[0].yearProjection}
            title={"Répartition de l'ARR"}
            valuex={"month"}
            valuey={"total"}
            color={"#a67676"}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ span: 5, offset: 1 }}
          lg={{ span: 5, offset: 1 }}
          className="col-money-charts"
        >
          <MoneyGraphComponentColumnBidirectional
            data={props.dataAnalysis[0].numberSubscriptionsChart}
            title={"Abo + upsells vs désabonnements"}
            valuex={"month"}
            valuey={["sub", "unsub"]}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ span: 5, offset: 1 }}
          lg={{ span: 5, offset: 1 }}
          className="col-money-charts"
        >
          <MoneyGraphComponentPie
            data={props.dataAnalysis[0].subcriptionsTypeCurrentChart}
            title={"Répartition des abonnements"}
            value={"value"}
            type={"type"}
          />
        </Col>
      </Row>
      <Row className="money-chart-row">
        {/* <Col xs={24} sm={24} md={11} lg={11} className="col-money-charts">
          <MoneyGraphComponentAreas
            data={props.dataAnalysis[0].subcriptionsTypeChart}
            title={"Répartition des types d'abonnement en fonction du temps"}
            valuex={"date"}
            valuey={"total"}
            type={"type"}
          />
        </Col> */}
        <Col xs={24} sm={24} md={11} lg={11} className="col-money-charts">
          <div className="money-chart-component">
            <p>Abonnements résiliés à échéance</p>
            <Table
              dataSource={props.dataAnalysis[0].terminatedAtRenewal}
              columns={[
                {
                  title: "Cabinet comptable",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Offre",
                  dataIndex: "TitleLocalized",
                  key: "TitleLocalized",
                },
                {
                  title: "Date de résiliation",
                  dataIndex: "DateTerm",
                  key: "DateTerm",
                  defaultSortOrder: "ascend",
                  sorter: (a, b) => new Date(a.DateTerm) - new Date(b.DateTerm),
                  render: (value, record, index) => dayjs(value).format("DD/MM/YYYY"),
                },
              ]}
            />
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ span: 11 }}
          lg={{ span: 11, offset: 1 }}
          className="col-money-charts"
        >
          <MoneyGraphComponentAreas
            data={props.dataAnalysis[0].totalBankAccountChart}
            title={"Cumul des prélèvements"}
            valuex={"date"}
            valuey={"value"}
            type={"category"}
          />
        </Col>
      </Row>

      <Row className="money-chart-row">
        <Col xs={24} sm={24} md={11} lg={11} className="col-money-charts">
          <MoneyGraphComponentBarHorizontal
            data={props.dataAnalysis[0].topContributorsChart}
            title={"Top 10 des contributeurs"}
            valuex={"total"}
            valuey={"id"}
          />
        </Col>
      </Row>
    </div>
  );
}

export default MoneyGraphs;
