const buildFileName = (originalFileName, operation, iphone) => {
  let nameWithoutExt = originalFileName.replace(/\.[^/.]+$/, "");
  let random = "";
  nameWithoutExt = nameWithoutExt.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
  nameWithoutExt = nameWithoutExt.length > 50 ? nameWithoutExt.substring(0, 50) : nameWithoutExt;

  operation = operation.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
  operation = operation.length > 25 ? operation.substring(0, 25) : operation;

  if (iphone) random = Math.floor(Math.random() * 9000 + 1000);

  return nameWithoutExt + random + "_" + operation + "." + originalFileName.split(".").pop();
};

export { buildFileName as default };
