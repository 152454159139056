import { CheckOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Popover, Result, Row, Spin } from "antd";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import * as API from "../../../api/API";
import isOperationEmpty from "../../../utils/isOperationEmpty";
import "../Client.css";
import Intro from "./Intro.js";
import TotalAmount from "./TotalAmount.js";
import TypeOperationsList from "./TypeOperationsList.js";

const howManyMissingDocuments = (data) => {
  let total = 0;
  data.forEach((element) => element.operations.forEach(() => total++));
  return total;
};

function Missing(props) {
  const [missingDocument, setMissingDocument] = useState([]);
  const [missingDocumentDec, setMissingDocumentDec] = useState([]);
  const [missingDocumentEnc, setMissingDocumentEnc] = useState([]);
  const [missingDocumentOther, setMissingDocumentOther] = useState([]);
  const [updated, isUpdated] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [lastMailSent, setLastMailSent] = useState(null);
  const [hideDone, setHideDone] = useState();
  const tokenClient = props.match.params.token;
  const idClient = jwt_decode(tokenClient).clientId;
  const idAccountingFirm = jwt_decode(tokenClient).accountingFirmId;
  const [fileSize, setFileSize] = useState();
  const [displayTypes, setDisplayTypes] = useState([]);

  const options = [
    { label: ".png", value: "image/png" },
    { label: ".jpeg", value: "image/jpeg" },
    { label: ".jpg", value: "image/jpg" },
    { label: ".tiff", value: "image/tiff" },
    { label: ".pdf", value: "application/pdf" },
    { label: ".txt", value: "text/plain" },
    { label: ".csv", value: "text/csv" },
    {
      label: ".docx",
      value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    { label: ".doc", value: "application/msword" },
    { label: ".xls", value: "application/vnd.ms-excel" },
    { label: ".xlsx", value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    { label: ".ofx", value: "application/x-ofx" },
    { label: ".json", value: "application/json" },
  ];

  const content = (
    <div style={{ width: "auto", minWidth: "150px", textAlign: "end" }}>
      <p style={{ margin: "0px" }}>
        Taille maximum : <span style={{ fontWeight: "400" }}>{fileSize}Mo</span>
      </p>
      <p style={{ marginBottom: "0" }}>
        Formats acceptés :{" "}
        <span style={{ fontWeight: "400", paddingBottom: "0" }}>{displayTypes?.join(", ")}</span>
      </p>
    </div>
  );

  const noFileSize = (
    <div style={{ width: "auto", minWidth: "150px", textAlign: "end" }}>
      <p style={{ margin: "0px" }}>
        Taille maximum : <span style={{ fontWeight: "400" }}>Aucune limite</span>
      </p>
      <p style={{ marginBottom: "0" }}>
        Formats acceptés :{" "}
        <span style={{ fontWeight: "400", paddingBottom: "0" }}>{displayTypes?.join(", ")}</span>
      </p>
    </div>
  );

  useEffect(() => {
    if (updated) {
      (async () => {
        let res = await API.postToGetClientMissingDoc({ idClient });
        if (res.status === 200) {
          res = await res.json();
          isUpdated(false);
          setMissingDocument(res.filtered);
          setMissingDocumentDec(res.filteredDecaissement);
          setMissingDocumentEnc(res.filteredEncaissement);
          if (res.filteredOther[0].operations.length > 0)
            setMissingDocumentOther(res.filteredOther);

          let resClient = await API.getClient(idClient);
          resClient = await resClient.json();

          if (resClient?.email?.missingdoc?.lastEmailSent)
            setLastMailSent(resClient.email.missingdoc.lastEmailSent);
        } else if (res.status === 204) {
          setEmpty(true);
        } else {
          isUpdated(false);
        }
      })();
    }
  }, [updated, idClient]);

  useEffect(() => {
    let missingDocumentLocal = [...missingDocument];
    let missingDocumentEncLocal = [...missingDocumentEnc];
    let missingDocumentDecLocal = [...missingDocumentDec];
    let missingDocumentOtherLocal = [...missingDocumentOther];

    if (hideDone) {
      missingDocumentLocal.forEach((elem, i) => {
        missingDocumentLocal[i].operations = elem.operations.filter((op) => isOperationEmpty(op));
        missingDocumentLocal[i].operations.sort(function (a, b) {
          return a.date < b.date ? 1 : a.date > b.date ? -1 : 0;
        });
      });
      setMissingDocument(missingDocumentLocal);

      /* *******ENC******** */
      missingDocumentEncLocal.forEach((elem, i) => {
        missingDocumentEncLocal[i].operations = elem.operations.filter((op) =>
          isOperationEmpty(op)
        );
        missingDocumentEncLocal[i].operations.sort(function (a, b) {
          return a.date < b.date ? 1 : a.date > b.date ? -1 : 0;
        });
      });
      setMissingDocumentEnc(missingDocumentEncLocal);

      /* *******DEC******** */
      missingDocumentDecLocal.forEach((elem, i) => {
        missingDocumentDecLocal[i].operations = elem.operations.filter((op) =>
          isOperationEmpty(op)
        );
        missingDocumentDecLocal[i].operations.sort(function (a, b) {
          return a.date < b.date ? 1 : a.date > b.date ? -1 : 0;
        });
      });
      setMissingDocumentDec(missingDocumentDecLocal);

      /* *******OTHER******** */
      missingDocumentOtherLocal.forEach((elem, i) => {
        missingDocumentOtherLocal[i].operations = elem.operations.filter((op) =>
          isOperationEmpty(op)
        );
      });
      setMissingDocumentOther(missingDocumentOtherLocal);
    } else {
      isUpdated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideDone]);

  const onChange = (e) => {
    setHideDone(e.target.checked);
  };

  useEffect(() => {
    (async () => {
      let fileSettings = await API.getAccountingFirmFileSettings(idAccountingFirm);
      if (fileSettings.status === 200) {
        fileSettings = await fileSettings.json();
        setDisplayTypes(
          !fileSettings.fileTypes?.length
            ? options.map(({ label }) => label)
            : fileSettings.fileTypes.map((el) => options.find((op) => op.value === el)?.label)
        );
        setFileSize(fileSettings.fileSize);
      } else {
        console.log("Error in getAccountingFirmFileSettings");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="client-missingdoc">
        {empty ? (
          <Result
            icon={<CheckOutlined style={{ color: "#4569f8" }} />}
            title="Toutes les pièces manquantes ont été traitées"
          />
        ) : missingDocument.length > 0 || missingDocumentOther.length > 0 ? (
          <>
            <Intro />
            <Popover placement="left" content={fileSize ? content : noFileSize} trigger="hover">
              <Button
                style={{
                  position: "absolute",
                  right: "6%",
                  border: "none",
                  padding: "0",
                  boxShadow: "none",
                }}
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "#6b87f9",
                      fontSize: "25px",
                    }}
                  />
                }
              ></Button>
            </Popover>
            <TotalAmount
              {...props}
              displayFileTypes={displayTypes}
              missingDocument={missingDocument}
              other={missingDocumentOther}
              lastMailSent={lastMailSent}
              setBadgeCount={props.setBadgeCount}
            />
            <div className="filter-op-done">
              <Checkbox onChange={onChange}>Masquer les opérations traitées</Checkbox>
            </div>
            <div>
              {missingDocumentOther[0] && missingDocumentOther[0].operations.length > 0 ? (
                <TypeOperationsList
                  {...props}
                  displayFileTypes={displayTypes}
                  nbrReceivedDocuments={
                    howManyMissingDocuments(missingDocument) +
                    howManyMissingDocuments(missingDocumentOther)
                  }
                  title={"Autres"}
                  typelist={missingDocumentOther}
                  reload={(data) => isUpdated(data)}
                  updated={updated}
                  hide={hideDone}
                />
              ) : null}
              {missingDocumentDec.length > 0 ? (
                <TypeOperationsList
                  {...props}
                  displayFileTypes={displayTypes}
                  nbrReceivedDocuments={
                    howManyMissingDocuments(missingDocument) +
                    howManyMissingDocuments(missingDocumentOther)
                  }
                  title={"Décaissements à justifier"}
                  typelist={missingDocumentDec}
                  reload={(data) => isUpdated(data)}
                  updated={updated}
                  hide={hideDone}
                />
              ) : null}
              {missingDocumentEnc.length > 0 ? (
                <TypeOperationsList
                  {...props}
                  displayFileTypes={displayTypes}
                  nbrReceivedDocuments={
                    howManyMissingDocuments(missingDocument) +
                    howManyMissingDocuments(missingDocumentOther)
                  }
                  title={"Encaissements à justifier"}
                  typelist={missingDocumentEnc}
                  reload={(data) => isUpdated(data)}
                  updated={updated}
                  hide={hideDone}
                />
              ) : null}
            </div>
          </>
        ) : (
          <Row style={{ height: "1000px" }}>
            <Col span={24} offset={12}>
              <Spin size="large" style={{ paddingTop: "100px" }} />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default Missing;
